// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

// Components
import About from "./components/About";
import Accessibility from "./components/Accessibility";
import Activity from "./components/Activity";
import Baseline from "./components/Baseline";
import Compiled from "./components/Compiled";
import Contact from "./components/Contact";
import DataSetAPI from "./components/DataSetAPI";
import Economic from "./components/Economic";
import EmissionsHighlights from "./components/EmissionsHighlights";
import EmissionsInventories from "./components/EmissionsInventories";
import EmissionsProjections from "./components/EmissionsProjections";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Glossary from "./components/Glossary";
import Header from "./components/Header";
import HelpSupport from "./components/HelpSupport";
import Hightech from "./components/Hightech";
import Home from "./components/Home.js";
import Kyoto from "./components/Kyoto";
import NavBreadcrumbs from "./components/NavBreadcrumbs";
import NoConnection from "./components/NoConnection";
import Paris from "./components/Paris";
import ParisMemo from "./components/ParisMemo";
import Quarterly from "./components/Quarterly";
import Scope2 from "./components/Scope2";
import SearchResults from "./components/SearchResults";
import States from "./components/States";
import Topbar from "./components/Topbar";
import UNFCCC from "./components/UNFCCC";

// Classes
import ArrayUtility from "./classes/ArrayUtility";
import MStyles from "./classes/MStyles";
import Page from "./classes/Page";
import TreeUtility from "./classes/TreeUtility";

// Fonts
import "@fontsource/public-sans";

/**
 * Top level application used by index.html
 */
export default class App extends React.Component {
  state = {
    selectionPage: "Home",
    searchTerm: "",
    glossaryTerm: "",
    loading: false,
    error: false,

    searchanzsicID: -1,
    searchfuelID: -1,
    searchgasrawID: -1,
    searchgasrawpID: -1,
    searchgasAR4ID: -1,
    searchgasAR5ID: -1,
    searchgasAR5PID: -1,
    searchBaselineID: -1,
    searchKPID: -1,
    searchMemoID: -1,
    searchParisID: -1,

    defaultdatatypeid: null,
    datatypetree: null,
    datatypetreeselected: null,
    topdatatypesectorsKP: null,
    topdatatypesectorsUNFCCCParis: null,

    defaultfuelid: null,
    fueltree: null,
    fueltreeselected: null,
    topfuelsectors: null,
    topfuelsectorsMemo: null,

    gastreear4: null,
    gastreear5: null,
    gastreear5projections: null,
    gastreeraw: null,
    gastreerawkp: null,
    gastreerawprojections: null,

    gastreear4selected: null,
    gastreear5selected: null,
    gastreear5projectionsselected: null,
    gastreerawselected: null,
    gastreerawkpselected: null,
    gastreerawprojectionsselected: null,

    locationtree: null,
    locationtreeselected: null,

    anzsictree: null,
    anzsictreeselected: null,
    anzsictreeScope2: null,
    anzsictreeScope2selected: null,
    defaultsectorforfuelsearch: null,
    sectortreeBaseline: null,
    sectortreeBaselineselected: null,
    sectortreeKP: null,
    sectortreeKPselected: null,
    sectortreeMemo: null,
    sectortreeMemoselected: null,
    sectortreeParis: null,
    sectortreeParisselected: null,
    sectortreeStates: null,
    sectortreeStatesselected: null,
    sectortreeUNFCCC: null,
    sectortreeUNFCCCselected: null,

    confidentialSectorACT: null,
    confidentialSectorAU: null,
    confidentialSectorET: null,
    confidentialSectorNSW: null,
    confidentialSectorNT: null,
    confidentialSectorQLD: null,
    confidentialSectorSA: null,
    confidentialSectorTAS: null,
    confidentialSectorVIC: null,
    confidentialSectorWA: null,

    firstyearnir: 1990,
    lastyearnir: 2020,
    firstyearprojections: 2020,
    lastyearprojections: 2030,
  };

  /**
   * Fetch data in ComponentDidMount
   * https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#fetching-external-data
   */
  componentDidMount() {
    this.setState({ loading: true }, () => {
      // // Slow network testing
      // setTimeout(() => {
      let base = "/api/Defaults";

      // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
      fetch(base, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json()) // Obtain promise
        .then((data) => {
          // Obtain data from promise
          if (data) {
            // Convert array of ids to array of nodes
            const tfparis = [];
            const tfmemo = [];
            const ttparis = [];
            const ttkp = [];

            for (const t of data.topfuelsectors) {
              tfparis.push(
                TreeUtility.findinTree(
                  data.sectortreeParis.Nodes,
                  null,
                  t.toString()
                )
              );
            }
            for (const t of data.topfuelsectorsMemo) {
              tfmemo.push(
                TreeUtility.findinTree(
                  data.sectortreeMemo.Nodes,
                  null,
                  t.toString()
                )
              );
            }

            for (const t of data.topdatatypesectorsUNFCCCParis) {
              ttparis.push(
                TreeUtility.findinTree(
                  data.sectortreeParis.Nodes,
                  null,
                  t.toString()
                )
              );
            }
            for (const t of data.topdatatypesectorsKP) {
              ttkp.push(
                TreeUtility.findinTree(
                  data.sectortreeKP.Nodes,
                  null,
                  t.toString()
                )
              );
            }
            const statesstringarray = ArrayUtility.convertArrayIDsToArrayString(
              data.states
            );
            this.setState({
              defaultdatatypeid: data.defaultdatatypeid,
              datatypenotavailableid: data.datatypenotavailableid.toString(),
              datatypetree: data.datatypetree.Nodes,
              datatypetreeselected: data.datatypetree.Default,
              topdatatypesectorsKP: ttkp,
              topdatatypesectorsUNFCCCParis: ttparis,

              defaultsectorforfuelsearch: data.defaultsectorforfuelsearch,
              fuelnotavailableid: data.fuelnotavailableid.toString(),
              fueltree: data.fueltree.Nodes,
              fueltreeselected: data.fueltree.Default,
              topfuelsectors: tfparis,
              topfuelsectorsMemo: tfmemo,

              gastreear4: data.gastreear4.Nodes,
              gastreear4selected: data.gastreear4.Default,
              gastreear5: data.gastreear5.Nodes,
              gastreear5selected: data.gastreear5.Default,
              gastreear5projections: data.gastreear5projections.Nodes,
              gastreear5projectionsselected: data.gastreear5projections.Default,
              gastreeraw: data.gastreeraw.Nodes,
              gastreerawselected: data.gastreeraw.Default,
              gastreerawkp: data.gastreerawkp.Nodes,
              gastreerawkpselected: data.gastreerawkp.Default,
              gastreerawprojections: data.gastreerawprojections.Nodes,
              gastreerawprojectionsselected: data.gastreerawprojections.Default,

              locationtree: data.locationtree.Nodes,
              locationtreeselected: data.locationtree.Default,
              states: statesstringarray,

              anzsictree: data.anzsictree.Nodes,
              anzsictreeselected: data.anzsictree.Default,
              anzsictreeScope2: data.anzsictreeScope2.Nodes,
              anzsictreeScope2selected: data.anzsictreeScope2.Default,
              sectortreeBaseline: data.sectortreeBaseline.Nodes,
              sectortreeBaselineselected: data.sectortreeBaseline.Default,
              sectortreeKP: data.sectortreeKP.Nodes,
              sectortreeKPselected: data.sectortreeKP.Default,
              sectortreeMemo: data.sectortreeMemo.Nodes,
              sectortreeMemoselected: data.sectortreeMemo.Default,
              sectortreeParis: data.sectortreeParis.Nodes,
              sectortreeParisselected: data.sectortreeParis.Default,
              sectortreeStates: data.sectortreeStates.Nodes,
              sectortreeStatesselected: data.sectortreeStates.Default,

              confidentialSectorACT: data.confidentialSectorACT,
              confidentialSectorAU: data.confidentialSectorAU,
              confidentialSectorET: data.confidentialSectorET,
              confidentialSectorNSW: data.confidentialSectorNSW,
              confidentialSectorNT: data.confidentialSectorNT,
              confidentialSectorQLD: data.confidentialSectorQLD,
              confidentialSectorSA: data.confidentialSectorSA,
              confidentialSectorTAS: data.confidentialSectorTAS,
              confidentialSectorVIC: data.confidentialSectorVIC,
              confidentialSectorWA: data.confidentialSectorWA,

              firstyearnir: data.firstyearnir,
              lastyearnir: data.lastyearnir,
              firstyearprojections: data.firstyearprojections,
              lastyearprojections: data.lastyearprojections,
            });
          } else {
            this.setState({ error: true })
          }
        })
        .catch((data) => {
          console.log("Catch")
          this.setState({ error: true });
        })
        .finally(this.setState({ loading: false }));

    }
      // Slow netowrk testing
      //,10000)}
    );

    // https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics
    // https://www.bounteous.com/insights/2021/04/06/tracking-single-page-applications-google-analytics-4
    document.title = Page.Home.title + " | ANGA";
    window.dataLayer.push({
      event: 'pageview',
      page_location: "/" + Page.Home.page,
      page_title: document.title,
      page: {
        path: "/" + Page.Home.page,
        title: document.title
      }
    });
  }

  /**
   * Handler for navigation click
   * @param {*} newSelection
   */
  handleNavigation = (newSelection) => {
    this.setState({
      selectionPage: newSelection,
    });
    for (const p of Page.list) {
      if (p.page === newSelection) {

        // https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics
        // https://www.bounteous.com/insights/2021/04/06/tracking-single-page-applications-google-analytics-4
        document.title = p.title + " | ANGA";
        window.dataLayer.push({
          event: 'pageview',
          page_location: "/" + p.page,
          page_title: document.title,
          page: {
            path: "/" + p.page,
            title: document.title
          }
        });
      }
    }
  };

  /**
   * Handler for glossary click
   * @param {*} glossaryterm - Known terms are ANZSIC, KP, UNFCCC
   */
  handleGlossary = (glossaryterm) => {
    this.setState({
      selectionPage: Page.Glossary.page,
      glossaryTerm: glossaryterm,
    });
  };

  /**
   * Handler to call search page with term
   * @param {*} term
   */
  handleSearch = (term) => {
    this.setState({
      searchTerm: term,
      selectionPage: Page.SearchResults.page,
    });
  };

  /**
   * Reset term selected in header search box, once the search on the page is used
   */
  handleChangeSearchTerm = () => {
    this.setState({ searchTerm: "" });
  };

  /**
   * Handler for search results
   * This can take us to
   *  1. Paris for selection on Fuel, Raw, AR5, Paris
   *  2. UNFCCC for selection on AR4
   *  3. Memo for slection on Memo
   *  4. KP for selection on KP
   *  5. Baseline for selection on RawP, AR5P and Baseline
   * @param {*} searchresult has type and id
   */
  handleSearchResult = (searchresult) => {
    let p = "";
    let _anzsicID = -1;
    let _fuelID = -1;
    let _gasrawID = -1;
    let _gasrawpID = -1;
    let _gasAR4ID = -1;
    let _gasAR5ID = -1;
    let _gasAR5PID = -1;
    let _BaselineID = -1;
    let _KPID = -1;
    let _MemoID = -1;
    let _ParisID = -1;

    if (searchresult.type === "ANZSIC") {
      p = Page.Economic.page;
      _anzsicID = searchresult.id;
    }
    if (searchresult.type === "Fuel") {
      p = Page.Paris.page;
      _fuelID = searchresult.id;
    }
    if (searchresult.type === "Raw") {
      p = Page.Paris.page;
      _gasrawID = searchresult.id;
    }
    if (searchresult.type === "RawP") {
      p = Page.Baseline.page;
      _gasrawpID = searchresult.id;
    }
    if (searchresult.type === "AR4") {
      p = Page.UNFCCC.page;
      _gasAR4ID = searchresult.id;
    }
    if (searchresult.type === "AR5") {
      p = Page.Paris.page;
      _gasAR5ID = searchresult.id;
    }
    if (searchresult.type === "AR5P") {
      p = Page.Baseline.page;
      _gasAR5PID = searchresult.id;
    }
    if (searchresult.type === "Baseline") {
      p = Page.Baseline.page;
      _BaselineID = searchresult.id;
    }
    if (searchresult.type === "KP") {
      p = Page.Kyoto.page;
      _KPID = searchresult.id;
    }
    if (searchresult.type === "Memo") {
      p = Page.ParisMemo.page;
      _MemoID = searchresult.id;
    }
    if (searchresult.type === "Paris") {
      p = Page.Paris.page;
      _ParisID = searchresult.id;
    }

    // Reset all values to their default.
    this.setState(
      {
        searchanzsicID: _anzsicID,
        searchfuelID: _fuelID,
        searchgasrawID: _gasrawID,
        searchgasrawpID: _gasrawpID,
        searchgasAR4ID: _gasAR4ID,
        searchgasAR5ID: _gasAR5ID,
        searchgasAR5PID: _gasAR5PID,
        searchBaselineID: _BaselineID,
        searchKPID: _KPID,
        searchMemoID: _MemoID,
        searchParisID: _ParisID,
      },
      () => {
        this.setState({ selectionPage: p });
      }
    );
  };

  render() {
    return (
      <div className="App" >
        {
          !this.state.error && (
            <>
              <header>
                <Header
                  searchTerm={this.state.searchTerm}
                  onHandleSearch={this.handleSearch}
                  key={this.state.searchTerm}
                />
                <Topbar onHandleNavigation={this.handleNavigation} />
              </header>
              <Container component="main" id="main" aria-describedby="dataload" aria-busy={this.state.loading} sx={
                (this.state.loading)
                  ? { display: "none" }
                  : { display: "block" }
              }>
                <NavBreadcrumbs
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <About page={this.state.selectionPage} />
                <Accessibility
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <Activity page={this.state.selectionPage} />
                <Baseline
                  page={this.state.selectionPage}
                  firstyear={this.state.firstyearprojections}
                  gasdefaultar={this.state.gastreear5selected}
                  gastreear={this.state.gastreear5projections}
                  gasselected={this.state.gastreear5selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gastreeraw={this.state.gastreerawprojections}
                  lastyear={this.state.lastyearprojections}
                  sectortree={this.state.sectortreeBaseline}
                  sectorselected={this.state.sectortreeBaselineselected}
                  searchgasrawid={this.state.searchgasrawpID}
                  searchgasarid={this.state.searchgasAR5PID}
                  searchbaselineid={this.state.searchBaselineID}
                />
                <Compiled page={this.state.selectionPage} />
                <Contact page={this.state.selectionPage} />
                <DataSetAPI
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <Economic
                  firstyear={this.state.firstyearnir}
                  gasdefaultar={this.state.gastreear5selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gasselected={this.state.gastreear5selected}
                  gastreear={this.state.gastreear5}
                  gastreeraw={this.state.gastreeraw}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.locationtreeselected}
                  locationtree={this.state.locationtree}
                  sectorselected={this.state.anzsictreeselected}
                  sectortree={this.state.anzsictree}
                  page={this.state.selectionPage}
                  searchanzsicid={this.state.searchanzsicID}
                />
                <EmissionsHighlights page={this.state.selectionPage} />
                <EmissionsInventories
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <EmissionsProjections
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <FAQ page={this.state.selectionPage} />
                <Glossary
                  glossaryterm={this.state.glossaryTerm}
                  page={this.state.selectionPage}
                />
                <HelpSupport
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <Hightech
                  page={this.state.selectionPage}
                  firstyear={this.state.firstyearprojections}
                  gasdefaultar={this.state.gastreear5selected}
                  gastreear={this.state.gastreear5projections}
                  gasselected={this.state.gastreear5selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gastreeraw={this.state.gastreerawprojections}
                  lastyear={this.state.lastyearprojections}
                  sectortree={this.state.sectortreeBaseline}
                  sectorselected={this.state.sectortreeBaselineselected}
                />
                <Home
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <Kyoto
                  datatypenotavailableid={this.state.datatypenotavailableid}
                  datatypetree={this.state.datatypetree}
                  datatypeselected={this.state.datatypetreeselected}
                  firstyear={this.state.firstyearnir}
                  fuelnotavailableid={this.state.fuelnotavailableid}
                  fuelselected={this.state.fueltreeselected}
                  fueltree={this.state.fueltree}
                  gasdefaultar={this.state.gastreear4selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gasselected={this.state.gastreear4selected}
                  gastreear={this.state.gastreear4}
                  gastreeraw={this.state.gastreerawkp}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.locationtreeselected}
                  sectorselected={this.state.sectortreeKPselected}
                  sectortree={this.state.sectortreeKP}
                  page={this.state.selectionPage}
                  topdatatypesectors={this.state.topdatatypesectorsKP}
                  topfuelsectors={this.state.topfuelsectors}
                  searchkyotoid={this.state.searchKPID}
                  onHandleGlossary={this.handleGlossary}
                />
                <Paris
                  defaultsectorforfuelsearch={this.state.defaultsectorforfuelsearch}
                  datatypenotavailableid={this.state.datatypenotavailableid}
                  datatypetree={this.state.datatypetree}
                  datatypeselected={this.state.datatypetreeselected}
                  firstyear={this.state.firstyearnir}
                  fuelnotavailableid={this.state.fuelnotavailableid}
                  fuelselected={this.state.fueltreeselected}
                  fueltree={this.state.fueltree}
                  gasdefaultar={this.state.gastreear5selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gasselected={this.state.gastreear5selected}
                  gastreear={this.state.gastreear5}
                  gastreeraw={this.state.gastreeraw}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.locationtreeselected}
                  locationtree={this.state.locationtree}
                  sectorselected={this.state.sectortreeParisselected}
                  sectortree={this.state.sectortreeParis}
                  page={this.state.selectionPage}
                  topdatatypesectors={this.state.topdatatypesectorsUNFCCCParis}
                  topfuelsectors={this.state.topfuelsectors}
                  searchfuelid={this.state.searchfuelID}
                  searchgasrawid={this.state.searchgasrawID}
                  searchgasar5id={this.state.searchgasAR5ID}
                  searchsectorid={this.state.searchParisID}
                />
                <ParisMemo
                  datatypenotavailableid={this.state.datatypenotavailableid}
                  firstyear={this.state.firstyearnir}
                  fuelnotavailableid={this.state.fuelnotavailableid}
                  fuelselected={this.state.fueltreeselected}
                  fueltree={this.state.fueltree}
                  gasdefaultar={this.state.gastreear5selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gasselected={this.state.gastreear5selected}
                  gastreear={this.state.gastreear5}
                  gastreeraw={this.state.gastreeraw}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.locationtreeselected}
                  locationtree={this.state.locationtree}
                  sectorselected={this.state.sectortreeMemoselected}
                  sectortree={this.state.sectortreeMemo}
                  page={this.state.selectionPage}
                  topfuelsectors={this.state.topfuelsectorsMemo}
                  searchmemoid={this.state.searchMemoID}
                />
                <Quarterly page={this.state.selectionPage} />
                <Scope2
                  firstyear={this.state.firstyearnir}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.locationtreeselected}
                  locationtree={this.state.locationtree}
                  sectorselected={this.state.anzsictreeScope2selected}
                  sectortree={this.state.anzsictreeScope2}
                  page={this.state.selectionPage}
                  onHandleNavigation={this.handleNavigation}
                />
                <SearchResults
                  page={this.state.selectionPage}
                  searchTerm={this.state.searchTerm}
                  anzsic={this.state.anzsictree}
                  fuel={this.state.fueltree}
                  gasraw={this.state.gastreeraw}
                  gasrawprojections={this.state.gastreerawprojections}
                  gasar4={this.state.gastreear4}
                  gasar5={this.state.gastreear5}
                  gasar5projections={this.state.gastreear5projections}
                  sectorBaseline={this.state.sectortreeBaseline}
                  sectorKP={this.state.sectortreeKP}
                  sectorMemo={this.state.sectortreeMemo}
                  sectorParis={this.state.sectortreeParis}
                  onHandleSearch={(r) => {
                    this.handleSearchResult(r);
                  }}
                  onChangeTerm={this.handleChangeSearchTerm}
                />
                <States
                  firstyear={this.state.firstyearnir}
                  fuelnotavailableid={this.state.fuelnotavailableid}
                  gasdefaultar={this.state.gastreear5selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gasselected={this.state.gastreear5selected}
                  gastreear={this.state.gastreear5}
                  gastreeraw={this.state.gastreeraw}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.states}
                  locationtree={this.state.locationtree}
                  sectorselected={this.state.sectortreeParisselected}
                  sectortree={this.state.sectortreeParis}
                  page={this.state.selectionPage}
                  confidentialSectorACT={this.state.confidentialSectorACT}
                  confidentialSectorAU={this.state.confidentialSectorAU}
                  confidentialSectorET={this.state.confidentialSectorET}
                  confidentialSectorNSW={this.state.confidentialSectorNSW}
                  confidentialSectorNT={this.state.confidentialSectorNT}
                  confidentialSectorQLD={this.state.confidentialSectorQLD}
                  confidentialSectorSA={this.state.confidentialSectorSA}
                  confidentialSectorTAS={this.state.confidentialSectorTAS}
                  confidentialSectorVIC={this.state.confidentialSectorVIC}
                  confidentialSectorWA={this.state.confidentialSectorWA}
                />
                <UNFCCC
                  datatypenotavailableid={this.state.datatypenotavailableid}
                  datatypetree={this.state.datatypetree}
                  datatypeselected={this.state.datatypetreeselected}
                  firstyear={this.state.firstyearnir}
                  fuelnotavailableid={this.state.fuelnotavailableid}
                  fuelselected={this.state.fueltreeselected}
                  fueltree={this.state.fueltree}
                  gasdefaultar={this.state.gastreear4selected}
                  gasdefaultraw={this.state.gastreerawselected}
                  gasselected={this.state.gastreear4selected}
                  gastreear={this.state.gastreear4}
                  gastreeraw={this.state.gastreeraw}
                  lastyear={this.state.lastyearnir}
                  locationselected={this.state.locationtreeselected}
                  locationtree={this.state.locationtree}
                  sectorselected={this.state.sectortreeParisselected}
                  sectortree={this.state.sectortreeParis}
                  page={this.state.selectionPage}
                  topdatatypesectors={this.state.topdatatypesectorsUNFCCCParis}
                  topfuelsectors={this.state.topfuelsectors}
                  searchunfcccgasid={this.state.searchgasAR4ID}
                  onHandleGlossary={this.handleGlossary}
                />
              </Container>

              <Box
                sx={
                  this.state.loading
                    ? { display: "box" }
                    : { display: "none" }
                }
              >
                <Box sx={MStyles.progressboxstyle} display={"flex"}>
                  <CircularProgress id="dataload" title="Loading default data"></CircularProgress>
                </Box>
              </Box>
              <Footer onHandleNavigation={this.handleNavigation} />
            </>)
        }
        {this.state.error && <NoConnection />}
      </div>
    );
  }
}
