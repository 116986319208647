// React
import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

// Components
import NormalCard from "./NormalCard";

/**
 * Navigation page for projections
 */
export default class EmissionsProjections extends React.Component {
  page = Page.EmissionsProjections;

  cards = [
    {
      page: Page.Baseline.page,
      header: "Baseline scenario",
      text: "The baseline scenario is an estimate of Australia's projected future emissions under policies and measures in place at the time of publishing.",
    },
    {
      page: Page.Hightech.page,
      header: "'With additional measures' scenario",
      text: "The 'with additional measures' scenario also includes some policies and measures under development at the time of publication.",
    },
  ];

  handleNavigation = (page) => {
    this.props.onHandleNavigation(page);
  };

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
        The emissions projections are estimates of Australia's future greenhouse gas emissions. They provide emissions projections to 2035 and provide an indicative assessment of how Australia is tracking against its emissions reduction target to reduce emissions by 43 per cent below 2005 levels by 2030. They also provide an understanding of the expected drivers of future emissions.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
        This site provides data from the current emissions projections. The projections include a baseline scenario based on policies and measures in place at the time of publication, and a 'with additional measures' scenario that includes some policies and measures under development at the time of publication. Visit 
          {" "}<Link href={ExternalURL.PROJECTION.url} sx={MStyles.linkstyle} alt={ExternalURL.PROJECTION.alt}>{ExternalURL.PROJECTION.text}</Link>{" "}
          for more information.
        </Typography>
        <Grid
          container={true}
          direction="row"
          justify="flex-start"
          alignItems="flex-center"
          sx={{
            marginTop: 0,
            marginBottom: 1,
            marginRight: 1,
            marginLeft: 1,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          {this.cards.map((row) => (
            <NormalCard
              key={row.page}
              page={row.page}
              header={row.header}
              text={row.text}
              onHandleNavigation={() => {
                this.handleNavigation(row.page);
              }}
            />
          ))}
        </Grid>
      </div>
    );
  }
}
