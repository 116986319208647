// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Footer for the web page
 */
export default class Footer extends React.Component {
  handleNavigation = (page) => {
    this.props.onHandleNavigation(page);
  };

  render() {
    return (
      <Box disableGutters component="footer" sx={MStyles.footerbox}>
        <Container>
          <Stack sx={MStyles.footer} direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Link tabIndex={0}
              onClick={() => {
                this.handleNavigation(Page.Contact.page);
              }}
              sx={MStyles.footerlinks}
            >
              {Page.Contact.title}
            </Link>
            <Link tabIndex={0}
              onClick={() => {
                this.handleNavigation(Page.Accessibility.page);
              }}
              sx={MStyles.footerlinks}
            >
              Accessibility
            </Link>
            <Link href={ExternalURL.DISCLAIMER.url} sx={MStyles.footerlinks}>
              {ExternalURL.DISCLAIMER.text}
            </Link>
            <Link href={ExternalURL.PRIVACY.url} sx={MStyles.footerlinks}>
              {ExternalURL.PRIVACY.text}
            </Link>
            <Link
              href={ExternalURL.FREEDOMOFINFORMATION.url}
              sx={MStyles.footerlinks}
            >
              {ExternalURL.FREEDOMOFINFORMATION.text}
            </Link>
          </Stack>
          <Stack
            sx={MStyles.footerbottom}
            direction="column"
            spacing={2}
          >
            <Link href={ExternalURL.COPYRIGHT.url} sx={MStyles.footerlinkscopyrights}>
              &copy;{" "}{ExternalURL.COPYRIGHT.title}
            </Link>
            <Typography paragraph sx={MStyles.footertext}>
              We acknowledge the Traditional Owners of country throughout Australia and recognise their continuing 
              connection to land, waters and culture. We pay our respects to their Elders past, present and emerging.
            </Typography>
          </Stack>
        </Container>
      </Box>
    );
  }
}
