export default class ExternalURL {

    //#region NIR source. Update annually in April
    static ACTIVITY_AGG_CROP = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-agriculture-crop.xlsx",
        text: "Activity Table 1990-2022 - Agriculture - Crop (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Agriculture - Crop (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_AGG_FERT = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-agriculture-fertiliser.xlsx",
        text: "Activity Table 1990-2022 - Agriculture - Fertiliser (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Agriculture - Fertiliser (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_AGG_LIVS = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-agriculture-livestock-national.xlsx",
        text: "Activity Table 1990-2022 - Agriculture - Livestock National (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Agriculture - Livestock National (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_AGG_OTHER = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-agriculture-other-livestock.xlsx",
        text: "Activity Table 1990-2022 - Agriculture - Other Livestock (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Agriculture - Other Livestock (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_FUGI = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-energy-fugitives.xlsx",
        text: "Activity Table 1990-2022 - Energy - Fugitives (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Energy - Fugitives (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_SE = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-energy-stationary-energy.xlsx",
        text: "Activity Table 1990-2022 - Energy - Stationary Energy (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Energy - Stationary Energy (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_TRANS = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-energy-transport.xlsx",
        text: "Activity Table 1990-2022 - Energy - Transport (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Energy - Transport (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_IP = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-industrial-processes.xlsx",
        text: "Activity Table 1990-2022 - Industrial Processes (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Industrial Processes (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_WAST = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-waste.xlsx",
        text: "Activity Table 1990-2022 - Waste (Excel)",
        alt: "Click to download Activity Table 1990-2022 - Waste (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_LULUCF = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-activity-table-1990-2022-lulucf.xlsx",
        text: "Activity Table 1990-2022 - LULUCF (Excel)",
        alt: "Click to download Activity Table 1990-2022 - LULUCF (Excel)",
        date: "April 2024"
    };

    static ACTIVITY_ANZSIC = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-national-inventory-by-economic-sector-2022-data-tables.xlsx",
        text: "National Inventory by Economic Sector 2022 - Data Tables (Excel)",
        alt: "Click to download National Inventory by Economic Sector 2022 - Data Tables (Excel)",
        date: "May 2024"
    };

    static ACTIVITY_BC = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-state-territory-inventories-2022-black-carbon-data-tables.xlsx",
        text: "State & Territory Inventories 2022 - Black Carbon Data Tables (Excel)",
        alt: "Click to download State & Territory Inventories 2022 - Black Carbon Data Tables (Excel)",
        date: "May 2024"
    };

    static ACTIVITY_STATES = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-state-territory-inventories-2022-emission-data-tables.xlsx",
        text: "State & Territory Inventories 2022 - Emission Data Tables (Excel)",
        alt: "Click to download State & Territory Inventories 2022 - Emission Data Tables (Excel)",
        date: "May 2024"
    };

    static ANZSIC = {
        url: "https://www.dcceew.gov.au/climate-change/publications/national-inventory-by-economic-sector",
        text: "National Inventory by Economic Sector",
        alt: "Link to National Inventory by Economic Sector"
    }

    static NIR = {
        url: "https://www.dcceew.gov.au/climate-change/publications/national-inventory-reports",
        text: "National Inventory Report",
        alt: "Link to National Inventory Report"
    };

    static STATE_EMISSION_INTENSITY = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-state-territory-inventories-2022-emission-intensity-data-tables.xlsx",
        text: "State & Territory Inventories 2022 - Emission Intensity Data Tables (Excel)",
        alt: "Link to State & Territory Inventories 2022 - Emission Intensity Data Tables (Excel)",
        date: "May 2024"
    };

    static STATE_REALCULATION = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-state-territory-inventories-2022-recalculation-data-tables.xlsx",
        text: "State & Territory Inventories 2022 - Recalculation Data Tables (Excel)",
        alt: "Link to State & Territory Inventories 2022 - Recalculation Data Tables (Excel)",
        date: "May 2024"
    };

    static STATE_SECTORAL_BREAKDOWN = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/anga-state-territory-inventories-2022-sectoral-breakdown-data-tables.xlsx",
        text: "State & Territory Inventories 2022 - Sectoral Breakdown Data Tables (Excel)",
        alt: "Link to State & Territory Inventories 2022 Sectoral Breakdown Data Tables (Excel)",
        date: "May 2024"
    };

    //#endregion

    //#region Projection Usually update in October
    static ACTIVITY_PROJECTION = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/australias-emissions-projections-2023-chart-data.xlsx",
        text: "Projections chart data (Excel)",
        alt: "Click to download Projections chart data (Excel)",
        date: "November 2023",  
    };

    static AUSTRALIAS_EMISSIONS_PROJECTION = {
        url: "https://www.dcceew.gov.au/climate-change/emissions-reporting/projecting-emissions",
        text: "Australia's emissions projections",
        alt: "Link to Australia's emissions projections"
    };
    
    static QUARTERLY = {
        url: "https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-gas-inventory-quarterly-updates",
        text: "National Greenhouse Gas Inventory Quarterly Updates",
        alt: "Link to external web site containing National Greenhouse Gas Inventory Quarterly Update: December 2021"
    };

    static PROJECTION = 
    {
        url: "https://www.dcceew.gov.au/climate-change/emissions-reporting/projecting-emissions",
        text: "Australia's emissions projections",
        alt: "Link to publication list containing Australia's emissions projections"
    }
    static PROJECTIONCHARTDATA = {
        url: "https://www.dcceew.gov.au/sites/default/files/documents/australias-emissions-projections-2023-chart-data.xlsx",
        text: "Chart data",
        title: "Australia's emissions projections data.",
        alt: "Link to publication list containing Australia's projections chart data"
    };

    static PROJECTIONSROADMAP = "https://www.dcceew.gov.au/climate-change/publications/technology-investment-roadmap";
    static PROJECTIONSLOWTECH = {
        url: "https://www.dcceew.gov.au/climate-change/publications/technology-investment-roadmap-low-emissions-technology-statement-2021",
        text: "Low EmissionsTechnology Statement (LETS 2021)",
        alt: "Link to external page for Low EmissionsTechnology Statement (LETS 2021)"
    };

    //#endregion

    //#region Department Update after MoG

    static ACCESSIBILITY = {
        url: "https://www.dcceew.gov.au/about/commitment/accessibility",
        text: "Accessibility",
        alt: "Link to the department accessibility page"
    };
    static COPYRIGHT = {
        url: "https://www.dcceew.gov.au/",
        title: "Department of Climate Change, Energy, the Environment and Water",
        alt: "Copyright name"
    };
    static DEPARTMENT = {
        url: "https://www.dcceew.gov.au/",
        title: "Department of Climate Change, Energy, the Environment and Water",
        alt: "Link to the department home page"
    };
    static DISCLAIMER = {
        url: "https://www.dcceew.gov.au/about/disclaimer",
        text: "Disclaimer",
        alt: "Link to the department disclaimer page"
    };
    static EMISSION_REPORTING = {
        url: "https://www.dcceew.gov.au/climate-change/emissions-reporting",
        title: "DCCEEW website",
        alt: "Link to the department emission reporting page"
    };
    static FREEDOMOFINFORMATION = {
        url: "https://www.dcceew.gov.au/about/reporting/freedom-of-information",
        text: "FOI",
        alt: "Link to the department freedom of information page"
    }
    static MAILTO = {
        url: "mailto:nationalgreenhouseaccounts@dcceew.gov.au",
        text: "nationalgreenhouseaccounts@dcceew.gov.au",
    };

    static MAILTOPROJECTIONS = {
        url: "mailto:emissions.projections@dcceew.gov.au",
        text: "emissions.projections@dcceew.gov.au",
    };

    static PRIVACY = {
        url: "https://www.dcceew.gov.au/about/commitment/privacy",
        text: "Privacy",
        alt: "Link to the department privacy page"
    };

    static REPORTERROR = {
        url: "https://www.dcceew.gov.au/about/contact",
        text: "Please notify the system administrators",
        alt: "Report error"
    };

    //#endregion

    //#region Australian Government
 
    static DTO = {
        url: "https://www.dta.gov.au",
        text: "The Digital Transformation Agency",
        alt: "Link to the external page",
    }
    static DTO_STANDARD = {
        url: "https://www.dta.gov.au/help-and-advice/about-digital-service-standard",
        text: "Digital Service Standard",
        alt: "Link to the external page"
    }

    //#endregion

    //#region External Sources

    static IPCC2006 = "https://www.ipcc.ch/report/2006-ipcc-guidelines-for-national-greenhouse-gas-inventories/";
    static IPCC2013 = "https://www.ipcc.ch/publication/2013-revised-supplementary-methods-and-good-practice-guidance-arising-from-the-kyoto-protocol/";
    static IPCC2019 = "https://www.ipcc.ch/report/2019-refinement-to-the-2006-ipcc-guidelines-for-national-greenhouse-gas-inventories/";
    static MONTREAL = {
        url: "https://www.dcceew.gov.au/environment/protection/ozone/montreal-protocol",
        text: "Montreal Protocol",
        alt: "Link to Montreal Protocol external web page"
    };

    static ODATA = {
        url: "https://www.odata.org/",
        text: "Open Data Protocol v4",
        title: "Open Data Protocol v4",
        alt: "Go to Open Data Protocol v4"
    };
    static ODATACONSUMERS = {
        url: "https://www.odata.org/ecosystem/",
        text: "Consumers",
        title: "ODATA Ecosystem",
        alt: "Go to ODATA Ecosystem"
    };
    static POWERQUERY = {
        url: "https://www.microsoft.com/en-us/download/details.aspx?id=39379",
        text: "PowerQuery for Excel",
        title: "Download PowerQuery for Excel page",
        alt: "Go to download PowerQuery for Excel page"
    };

    static UNFCCCREPORTING = "https://unfccc.int/process-and-meetings/transparency-and-reporting/reporting-and-review-under-the-convention/greenhouse-gas-inventories-annex-i-parties/reporting-requirements";
    static UNFCCCREPORTINGKP = "https://unfccc.int/process/transparency-and-reporting/reporting-and-review-under-the-kyoto-protocol/overview/background-and-resources/the-guidelines-to-implement-the-kyoto-protocol-the-marrakesh-accords-and-the-578-implications";

    //#endregion 
}