// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Components
import MultipleSelectTree from "./MultipleSelectTree";
import SingleSelectGasTree from "./SingleSelectGasTree";
import SingleSelectTree from "./SingleSelectTree";
import Visualisation from "./Visualisation";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import ExternalURL from "../classes/ExternalURL";
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TransformationUtility from "../classes/TransformationUtility";
import TreeUtility from "../classes/TreeUtility";

/**
 * Display data about economic sectors
 * @param {*} props
 * @returns
 */
export default function Economic(props) {

    // Reducer function for a filter
    function reducer(state, filter) {
      return {
        gasselected: filter.gasselected,
        locationselected: filter.locationselected,
        sectorselected: filter.sectorselected,
        sectortree: filter.sectortree
      };
    }
  
    // Use reducer to minimize invocations of API
    // This should contain all variables that are invoked by API
    // It should be initialized with default values
    const [filter, dispatch] = React.useReducer(
      reducer,
      {
        gasselected: props.gasdefaultar,
        locationselected: props.locationselected,
        sectorselected: props.sectorselected,
        sectortree: props.sectortree
      }
    )

  const [charttype, setChartType] = React.useState("StackedBar");
  const [displayline, setDisplayLine] = React.useState(true);
  const [displaybar, setDisplayBar] = React.useState(false);
  const [displaystackedbar, setDisplayStackedBar] = React.useState(true);

  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);

  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);

  // Dialogs
  const [gasdialog, setGasDialog] = React.useState(false);
  const [gaskey, setGasKey] = React.useState("g" + Date.now());

  const [locationdialog, setLocationDialog] = React.useState(false);
  const [locationkey, setLocationKey] = React.useState("l" + Date.now());

  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  // Labels
  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [location, setLocation] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [gas, setGas] = React.useState("");
  const [units, setUnits] = React.useState("");

  const page = Page.Economic;
  const digits = 4;

  //#region Effects
  /**
   * Reset to default if pages changes.
   */
  React.useEffect(() => {
    if (props.page === Page.SearchResults.page) {
      if (-1 !== props.searchanzsicid) {
        dispatch({
          gasselected: props.gasdefaultar,
          locationselected: props.locationselected,
          sectorselected: props.searchanzsicid,
          sectortree: props.sectortree  
        })
      }
    } else if (props.page !== page.page) {
      dispatch({
        gasselected: props.gasdefaultar,
        locationselected: props.locationselected,
        sectorselected: props.sectorselected,
        sectortree: props.sectortree  
      })
  }}, [
    page.page,
    props.page,
    props.gasdefaultar,
    props.locationselected,
    props.searchanzsicid,
    props.sectorselected,
    props.sectortree
  ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    /**
     * Transform data into components used by visualization.
     * This follows successful data load so it is included in this effect.
     * @param {*} apid
     */
    function transformdata(apid) {
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];

      // Populate data for XAxis key and name are reserved names
      // Populae data for year table column
      TransformationUtility.prepareTimeSeries(
        props.firstyear,
        props.lastyear,
        _headers,
        _chartdata,
        _tabledata
      );

      const _generationspread = TransformationUtility.calculateGenerationSpread(
        filter.sectortree,
        filter.sectorselected
      );
      const maxvalue = TransformationUtility.getMaxValue(
        apid,
        filter.sectorselected,
        _tabledata
      );
      const scale = TransformationUtility.getScale(maxvalue);

      const hd = TransformationUtility.populateTimeSeries(
        apid,
        filter.sectorselected,
        filter.sectortree,
        _chartdata,
        _lines,
        _tabledata,
        _headers,
        digits,
        scale.factor
      );

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const gas = TransformationUtility.calculateGasDisplayName(
        props.gastreeraw,
        props.gastreear,
        filter.gasselected
      );

      const loc = TransformationUtility.calculateTitle(
        false,
        props.locationtree,
        filter.locationselected
      );
      const sectors1 = TransformationUtility.calculateSelectionList(
        filter.sectortree,
        filter.sectorselected
      );

      // Annual Australian Emissions CO2-e in (kt) accounted under Total UNFCCC
      const g = _generationspread ? "Line" : "StackedBar";

      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setChartType(g);
      setDisplayLine(true);
      setDisplayBar(false);
      setDisplayStackedBar(!_generationspread);
      setLocation(loc);
      setSector(sectors1);
      setGas(gas);
      setHasData(hd.hasdata);
      setPartialData(hd.partialdata);
      setUnits(scale.units);

    }

    if (props.page !== page.page) return;
    if (!filter) return
    if (!filter.sectorselected) return;
    if (!filter.gasselected) return;
    if (!filter.locationselected) return;

    setLoading(true);
    const base = "/api/ANZSICTimeSeries?";
    const sectorstring = ArrayUtility.convertArrayToStringIDs(filter.sectorselected);
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        urlencodedanzsiclist: sectorstring,
        locationid: filter.locationselected,
        gasid: filter.gasselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            setLines(null);
            setChartData(null);
            setHeaders(null);
            setTableData(null);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformdata(d);
          }
        } else {
          setLines(null);
          setChartData(null);
          setHeaders(null);
          setTableData(null);
        }
      })
      .catch((data) => {
        console.log("Error");
        setLines(null);
        setChartData(null);
        setHeaders(null);
        setTableData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.firstyear,
    props.gastreear,
    props.gastreeraw,
    props.lastyear,
    props.locationtree,
    props.page,
  ]);

  //#endregion

  //#region Gas

  /**
   * Cancel selection of Gas
   */
  function cancelselectGas() {
    setGasDialog(false);
  }

  /**
   * Open Gas dialog
   */
  function openGasDialog() {
    setGasKey("g" + Date.now());
    setGasDialog(true);
  }

  /**
   * Save selection of Gas
   */
  function saveselectGas(value) {
    setGasDialog(false);
    const f = filter;
    dispatch({
      gasselected: value,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      sectortree: f.sectortree  
    })
  }

  //#endregion

  //#region Location

  /**
   * Cancel selection of Location
   */
  function cancelselectLocation() {
    setLocationDialog(false);
  }

  /**
   * Open location selection dialog
   */
  function openLocationDialog() {
    setLocationKey("l" + Date.now());
    setLocationDialog(true);
  }

  /**
   * Save selection of location.
   * Sector hierarchies are slightly different for different locations so need to update
   * both hierarchy and data after change of location
   */
  function saveselectLocation(value) {
    setLocationDialog(false);
    setLoading(true);
    const base = "/api/ANZSICHierarchy?";
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(
      base +
      new URLSearchParams({
        LocationID: value,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          dispatch({
            gasselected: props.gasdefaultar,
            locationselected: value,
            sectorselected: data.Default,
            sectortree: data.Nodes 
          })      
        } else {
          console.error("Error: no data retrieved");
        }
      })
      .catch((data) => {
        console.error("Error",data);
      });
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    const arr = [];
    if (Array.isArray(value)) {
      for (const s of value) {
        const n = TreeUtility.findinTree(filter.sectortree, null, s);
        if (n) {
          arr.push(n.name);
        }
      }
    } else {
      const n = TreeUtility.findinTree(filter.sectortree, null, value);
      if (n) {
        arr.push(n.name);
      }
    }
    
    setSectorDialog(false);
    const f = filter;
    dispatch({
      gasselected: f.gasselected,
      locationselected: f.locationselected,
      sectorselected: value,
      sectortree: f.sectortree 
    })      
    setSector(ArrayUtility.convertArrayToString(arr));
  }
  //#endregion

  // Do not render if the page name is different
  if (props.page !== page.page) return null;
  if (loading || !props.sectortree)
    return (
      <Box sx={MStyles.progressboxstyle} display={"flex"}>
        <CircularProgress></CircularProgress>
      </Box>
    );

  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        Search emission estimates by Australia and New Zealand Standard Industry
        Classification (ANZSIC).
      </Typography>

      <Typography paragraph sx={MStyles.body}>
        Emissions for the National inventory by economic sector are displayed
        using the global warming potentials (GWP) of gases from the
        Intergovernmental Panel on Climate Change&apos;s (IPCC) Fifth Assessment
        Report (AR5).
      </Typography>

      <Typography paragraph sx={MStyles.body}>
        A chart is presented below for emissions for all of Australia, for CO2-e
        and for all sectors.
      </Typography>

      <Typography paragraph sx={MStyles.body}>
        Selecting a point on the chart will display the emissions for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openLocationDialog();
            }}
          >
            Select location
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
          >
            Select sector
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openGasDialog();
            }}
          >
            Select gas
          </Button>
        </Stack>
      </Box>

      <Visualisation
        digits={digits}
        isprojections={false}
        isdate={true}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={false}
        ariatitle={"Emissions by economic sector"}
        bar={displaybar}
        data={chartdata}
        defaulttype={charttype}
        displaylocation={true}
        displaysector={false}
        fileprefix={page.title}
        headers={headers}
        line={displayline}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title={"Emissions by economic sector"}
        units={units}
        XAxisTitle="Year"
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={""}
        emissiontype={""}
      />

      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          Estimates are based on the IPCC classification system used to report
          Australia&apos;s greenhouse gas emission inventory under UNFCCC guidelines
          and to track Australia&apos;s progress towards its 2030 Paris target.
          Please see the National Inventory Report for further information.
        </Typography>

        <Typography paragraph sx={MStyles.body}>
          Some sub-industries are not available for reasons of confidentiality.
          As a consequence, some industries will present as greater than the sum
          of their available parts.
        </Typography>

        <Typography paragraph sx={MStyles.body}>
          ANZSIC emissions at State and Territory level exclude emissions from
          military transport. For further information about the allocation of
          military transport to ANZSIC industries, please see the published{" "}
          <Link
            href={ExternalURL.ANZSIC.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            alt={ExternalURL.ANZSIC.alt}
          >
            {ExternalURL.ANZSIC.text}
          </Link>{" "}
          report.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Bar charts are not available for queries with different levels of the sectoral hierarchy selected. For example, if the user selects D Electricity, Gas, Water and Waste Services and 26 Electricity Supply sectors the data will be available as a line chart or table only, as 26 Electricity Supply is a subdivision of D Electricity, Gas, Water and Waste Services.
        </Typography>
      </Box>

      <SingleSelectGasTree
        key={gaskey}
        isAR5={true}
        isProjection={false}
        open={gasdialog}
        defaultar={props.gasdefaultar}
        treear={props.gastreear}
        defaultraw={props.gasdefaultraw}
        treeraw={props.gastreeraw}
        selected={filter.gasselected}
        title="Select gas"
        onCancel={() => {
          cancelselectGas();
        }}
        onSave={(selection) => {
          saveselectGas(selection);
        }}
      />

      <SingleSelectTree
        key={locationkey}
        open={locationdialog}
        tree={props.locationtree}
        selected={filter.locationselected}
        title="Select Location"
        onCancel={() => {
          cancelselectLocation();
        }}
        onSave={(selection) => {
          saveselectLocation(selection);
        }}
      />

      <MultipleSelectTree
        key={sectorkey}
        open={sectordialog}
        messages={Messages.SECTOR_ECONOMIC_INFO}
        tree={filter.sectortree}
        selected={filter.sectorselected}
        title="Select Sector"
        topsector="Total of all Economic (ANZSIC)"
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />
    </div>
  );
}
