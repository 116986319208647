// React
import React from "react";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Display formated accordion use by Compiled, Search and FAQ pages
 */
export default class CustomAccordion extends React.Component {
  render() {
    return (
      <Box mt={2} mb={2}>
        {this.props.data.map((d) => {
          return (
            <article key={d.q}>
              <Accordion
                disableGutters
                elevation={0}
                sx={MStyles.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  sx={MStyles.accordionsummary}
                >
                  <Typography
                    variant={this.props.variant}
                    sx={this.props.variant === "h3" ? MStyles.h3 : MStyles.h4}
                  >
                    {d.q}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "white" }}>
                  {d.a.map((p) => {
                    return (
                      <Typography paragraph sx={MStyles.body} key={p}>
                        {p}
                      </Typography>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </article>
          );
        })}
      </Box>
    );
  }
}
