// React
import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Container from "@mui/material/Container";
import ErrorIcon from "@mui/icons-material/Error";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";

/**
 * No data message
 */
export default function NoConnection() {
  return (
    <Container sx={{pt:10}}>
    <Alert
      severity="info"
      icon={<ErrorIcon fontSize="large" sx={{ color: "#DC5800" }} />}
      sx={MStyles.dimensionboxwarningmessagestyle}
      display={"flex"}
    >
      <AlertTitle>
        <Typography component="h4" sx={MStyles.warning}>
          Web site is currently experiencing problem.
        </Typography>
      </AlertTitle>
      <Typography paragraph sx={MStyles.body}>
        <Link href={ExternalURL.REPORTERROR.url} sx={MStyles.linkstyle} alt={ExternalURL.REPORTERROR.alt}>
        {ExternalURL.REPORTERROR.text}
      </Link>
      </Typography>
    </Alert>
    </Container>
  );
}
