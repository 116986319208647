export default class ChartUtility {
  /**
   *
   * @param {*} x
   * @returns
   */
  static formatYAxisNumbers(x) {
    return new Intl.NumberFormat("en-AU").format(x);
  }

  /**
   * Calculate if legen can be displayed based on screen size
   * @param {*} viewwidth 
   * @returns 
   */
   static showLegend(viewwidth) {
    if (500 < viewwidth) return true;
    return false;
  }

  /**
   * Calculate if legen can be displayed based on screen size
   * @param {*} viewwidth 
   * @returns 
   */
   static showYAxisLabel(viewwidth) {
    if (500 < viewwidth) return "Emissions";
    return "";
  }

  /**
   * Calculate location of source based on display
   * @param {*} viewwidth 
   * @returns 
   */
   static sourcey(viewwidth) {
    // Comment this out as the location is not reliable 13-Jul-2022
    // if (1300 < viewwidth) return 666-230;
    // if (666 < viewwidth ) return 230 + (viewwidth-666)*(666-230)/(1300-666);
    // if (600 < viewwidth) return 230 + (viewwidth-580)*(600-230)/(1300-600);
    return null;
  }
  /**
   * Format ticks for dates
   * @param {*} x
   * @returns
   */
  static tickdateFormatter(x) {
    const date = new Date(x);
    if (date.getMonth() === 0) return date.getFullYear().toString();
    if (date.getMonth() === 2) return "Q1 " + date.getFullYear().toString();
    if (date.getMonth() === 5) return "Q2 " + date.getFullYear().toString();
    if (date.getMonth() === 8) return "Q3 " + date.getFullYear().toString();
    if (date.getMonth() === 11) return "Q4 " + date.getFullYear().toString();
    return "Error";
  }

  /**
   * Format ticks for location
   * @param {*} x
   * @returns
   */
  static ticklocationFormatter(x) {
    if (x === "EXTERNAL TERRITORIES (ET)") return "ET";
    return x;
  }

  /**
   * Calculate x location of title based on display
   * @param {*} viewwidth 
   * @returns 
   */
  static titlex(viewwidth) {
    if (1300 < viewwidth) return (1300 - 350) /2 ;
    if (500 < viewwidth) return (viewwidth - 350) /2 ;
    return null;
  }

  /**
   * Calculate y location of title based on display
   * @param {*} viewwidth 
   * @returns 
   */
  static titley(viewwidth) {
    if (400 < viewwidth) return 20;
    return null;
  }
}
