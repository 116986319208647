// React
import * as React from "react";

// MUI

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Single select dialog for years data. Style guide section 7.2 version 1.2_18_05_2022.
 * Properties
 * open : true or false to show this dialog
 * data : data list of years available
 * selected : key of the selected node
 * onCancel : Handle to cancel selection
 * onSave : Handle to pass new selection
 */
export default function SelectYears(props) {
  const [type, setType] = React.useState(props.type);
  const [selected, setSelected] = React.useState(props.selected);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "100",
      },
    },
  };

  /**
 * Aria requirement to close dialog on escape
 * @param {*} e 
 */
   function ariaescape(e) {
    if (27 === e.keyCode) {
      cancelselect();
    }
  }
  
  function cancelselect() {
    props.onCancel();
  }

  function confirm() {
    if (type === "Series") {
      props.onSave("Series");
    } else {
      props.onSave(selected);
    }
  }

  function handleChange(event) {
    setSelected(event.target.value);
  }

  function selectRaw(item) {
    setType(item);
  }

  // To detect if this is a a mobile screen we need to check for sm
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Dialog open={props.open} fullScreen={!small} maxWidth="md" aria-labelledby="title" aria-describedby="description" aria-modal="true" onKeyDown={ariaescape}>
        <Box
          display="flex"
          sx={{ justifyContent: "flex-end", marginBottom: 0, paddingBottom: 0 }}
        >
          <IconButton
            onClick={() => {
              cancelselect();
            }}
          >
            <CloseIcon titleAccess="Close dialog"/>
          </IconButton>
        </Box>      
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1" sx={MStyles.h1} id="title">
              Select year(s)
            </Typography>
            <Typography paragraph sx={MStyles.body} id="description">
              Select one year of emissions or view them as a time series.
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2" sx={MStyles.h2}>
          Select one
        </Typography>
        <RadioGroup defaultValue="One">
          <FormControlLabel
            control={<Radio />}
            value="Series"
            label="View emissions as a time series"
            onClick={(x) => selectRaw("Series")}
            checked={type !== "One"}
          />
          <FormControlLabel
            control={<Radio />}
            value="One"
            label={"View emissions by year"}
            onClick={(x) => selectRaw("One")}
            checked={type === "One"}
          />
        </RadioGroup>
        <Typography sx={MStyles.body} display={type === "One" ? "box" : "none"}>
          Select year
        </Typography>
        <Box display={type === "One" ? "box" : "none"}>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={selected}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            sx={{ minWidth: 250 }}
            MenuProps={MenuProps}
          >
            {props.data.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.linkbutton}
          onClick={() => {
            cancelselect();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.buttondimstyle}
          onClick={() => {
            confirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
