// React
import * as React from "react";

// MUI
import Typography from "@mui/material/Typography";

// Components
import ActivityTabs from "./ActivityTabs";

// Classes
import Page from "../classes/Page";
import MStyles from "../classes/MStyles";

/**
 * Page containing activity information
 */
export default class Activity extends React.Component {
  page = Page.Activity;

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div maxwidth="xl">
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography sx={MStyles.body}>
          This page contains the activity data used to derive emission estimates. Examples of activity data include quantity of fuel consumed or animal livestock numbers.
        </Typography>
        <ActivityTabs />
      </div>
    );
  }
}
