// React
import * as React from "react";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Disply search result properties
 * @param {*} props 
 * @returns 
 */
export default function SearchResultDetail(props) {

/**
 * Raise issue to parent on view emission click
 * @param {*} props 
 * @returns 
 */
 const viewEmission = (id) => {
    props.onSelected(id);
  };

  if(0 === props.rows.length) return (null);
  
  return (
    <Accordion disableGutters elevation={0} sx={MStyles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={MStyles.accordionsummary}
        disabled={0 === props.rows.length}        
      >
        <Typography fontWeight="bold">
          {props.name} ({props.rows.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "white" }}>
        <TableContainer>
          <Table aria-label="search results table">
            <TableHead sx={MStyles.tableheadstyle}>
              <TableRow>
                <TableCell sx={MStyles.tableheadercell}>Number</TableCell>
                <TableCell sx={MStyles.tableheadercell}>{props.columnname}</TableCell>
                <TableCell sx={MStyles.tableheadercell}>View emissions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={MStyles.tablebodystyle}>
              {props.rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={MStyles.tablecell}>
                    {row.count}
                  </TableCell>
                  <TableCell sx={MStyles.tablecell}>{row.value}</TableCell>
                  {!row.c && (
                  <TableCell sx={MStyles.tablecell}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={MStyles.searchbutton}
                      onClick={() => {
                        viewEmission(row.id);
                      }}
                    >
                      View emissions
                    </Button>
                  </TableCell>
                  )}
                  {
                    row.c && (<TableCell sx={MStyles.tablecell}>&nbsp;</TableCell>)
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
