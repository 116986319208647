// React
import * as React from "react";

// MUI
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Components
import CustomAccordion from "./CustomAccordion";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Display information about OData
 */
export default class Compiled extends React.Component {
  page = Page.Compiled;

  fifthassessment = [
    {
      q: "Paris Agreement Inventory",
      a: ["Estimates in these datasets are based on the UNFCCC classification system used to report Australia's greenhouse gas emission inventory to track Australia's progress towards its 2030 Paris target. Datasets are available for national and state and territory estimates, and are disaggregated by IPCC sector, gas and financial year."],
    },
    {
      q: "Inventory by Economic Sector (ANZSIC)",
      a: ["Estimates in these datasets are disaggregated by economic sector (Australia-New Zealand Standard Industry Classifications (ANZSIC)) rather than by IPCC sector. Datasets are available for national and state and territory estimates, and are disaggregated by ANZSIC sector, gas and financial year."],
    },
    {
      q: "Scope 2",
      a: ["Estimates in these datasets are known as “indirect” emissions from the generation of purchased electricity. Emissions from the generation of electricity have been allocated to electricity consumers according to the share of electricity consumption of each economic sector (Australia-New Zealand Standard Industry Classifications, ANZSIC). Datasets are available for national and state and territory scope 2 estimates, and are disaggregated by ANZSIC sector and financial year."],
    },
  ];

  forthassessment = [
    {
      q: "UNFCCC Inventory",
      a: ["Estimates in these datasets are based on the classification system used to report Australia's greenhouse gas emission inventory under UNFCCC guidelines. Datasets are available for national and state and territory estimates, and are disaggregated by IPCC sector, gas and financial year."],
    },
    {
      q: "Kyoto Protocol Inventory",
      a: ["Estimates in this dataset are based on the classification system used to report Australia's greenhouse gas emission inventory to assess Australia's performance against its 2020 targets. A dataset is available for national estimates, and are disaggregated by IPCC sector, gas and financial year"],
    },
    {
      q: "Inventory by Economic Sector (ANZSIC)",
      a: ["Estimates in these datasets are disaggregated by economic sector (Australia-New Zealand Standard Industry Classifications (ANZSIC)) rather than by IPCC sector. Datasets are available for national and state and territory estimates, and are disaggregated by ANZSIC sector, gas and financial year."],
    },
  ];

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          This website allows users to programmatically query data using{" "}
          <Link
            href={ExternalURL.ODATA.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            title={ExternalURL.ODATA.title}
            alt={ExternalURL.ODATA.alt}
          >
            {ExternalURL.ODATA.text}
          </Link>. A number of applications such as PowerPivot for Excel, Excel Add-In, Drupal, JBoss, 
          Joomla, Power BI, Tableau, Spotfire and Excel 2016 or later can access data from this feed. Earlier 
          versions of Excel can access this data using{" "}
          <Link
            href={ExternalURL.POWERQUERY.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            title={ExternalURL.POWERQUERY.title}
            alt={ExternalURL.POWERQUERY.alt}
          >
            {ExternalURL.POWERQUERY.text}
          </Link>. For a more complete list please see{" "}
          <Link
            href={ExternalURL.ODATACONSUMERS.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            title={ExternalURL.ODATACONSUMERS.title}
            alt={ExternalURL.ODATACONSUMERS.alt}
          >
            {ExternalURL.ODATACONSUMERS.text}
          </Link>{" "}
          page on odata.org web site.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          To retrieve data sets please use the following URL: {" "}
          {window.location.href}OData
        </Typography>

        <Typography variant="h2" sx={MStyles.h2}>
          Data sets
        </Typography>
        <Typography variant="h3" sx={MStyles.h3}>
          Datasets compiled using global warming potentials from the IPCC Fifth
          Assessment Report
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Datasets are based on the system used to report Australia&apos;s
          greenhouse gas emission inventory to track Australia&apos;s progress
          towards its 2030 Paris Agreement target. These estimates are compiled
          using the global warming potentials from the Intergovernmental Panel
          on Climate Change (IPCC) Fifth Assessment Report (AR5).
        </Typography>

        <CustomAccordion data={this.fifthassessment} variant="h4" />

        <Typography variant="h3" sx={MStyles.h3}>
          Datasets compiled using global warming potentials from the IPCC Fourth
          Assessment Report
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Datasets are based on the system used to report Australia&apos;s
          greenhouse gas emission inventory under UNFCCC guidelines and the
          Kyoto Protocol. These estimates are compiled using the global warming
          potentials from the IPCC Fourth Assessment Report (AR4). Please see
          the{" "}
          <Link
            href={ExternalURL.NIR.url}
            sx={MStyles.linkstyle}
            alt={ExternalURL.NIR.alt}
          >
            {ExternalURL.NIR.text}
          </Link>{" "}
          for further information.
        </Typography>
        <CustomAccordion data={this.forthassessment} variant="h4" />
      </div>
    );
  }
}
