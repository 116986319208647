// React
import * as React from "react";

// MUI
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Display data for Quarterluy
 */
export default class Quarterly extends React.Component {
  page = Page.Quarterly;

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          National greenhouse gas inventory estimates are updated quarterly.
        </Typography>
        <Typography variant="h2" sx={MStyles.h2}>
          View Australia&apos;s quarterly inventory update
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          View this report on the Department of Climate Change, Energy, the Environment and Water website.
        </Typography>

        <Typography sx={MStyles.body}>
          <Link href={ExternalURL.QUARTERLY.url} sx={MStyles.linkstyle} rel="noreferrer" alt={ExternalURL.QUARTERLY.alt}>
          {ExternalURL.QUARTERLY.text}
          </Link>.
        </Typography>
      </div>
    );
  }
}
