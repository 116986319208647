/**
 * Required file name is prefix_DD-MM-YYYY_HH-MM-SS.extension
 * See email from Aarthy Fri 10/06/2022 5:43 PM
 * @param {*} prefix 
 * @param {*} extension 
 * @returns 
 */
export default function FilenameGenerator(prefix, extension){
    const d = new Date()

    const year = d.getFullYear().toString();   
    const m = d.getMonth()+1;
    const month = (m < 10) ? "0"+m.toString() : m.toString();

    const dd = d.getDate(); 
    const day = (dd < 10) ? "0"+dd.toString() : dd.toString(); 

    const h = d.getHours();
    const hour = (h < 10) ? "0"+h.toString() : h.toString(); 

    const mm = d.getMinutes();
    const minute = (mm < 10) ? "0"+mm.toString() : mm.toString(); 

    const s = d.getSeconds();
    const second = (s < 10) ? "0"+s.toString() : s.toString(); 

    return prefix +"_"+day +"-"+month+"-"+year+"_"+hour+"-"+minute+"-"+second+"."+extension;
}