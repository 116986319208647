// React
import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * No data message
 */
export default function PartialData(props) {
  const [nd, setND] = React.useState(props.partialdata);

  React.useEffect(() => {
    setND(props.partialdata);
  }, [props.partialdata]);

  if (!nd) return null;

  return (
    <Alert
      severity="info"
      icon={<ErrorIcon fontSize="large" sx={{ color: "#2E5299"  }} />}
      sx={MStyles.dimensionboxmessagestyle}
    >
      <AlertTitle>
        <Typography component="h4" sx={MStyles.warning}>
          Some of the data is unavailable
        </Typography>
      </AlertTitle>
      <Typography paragraph sx={MStyles.body}>
        A chart for the available data is displayed.
      </Typography>
    </Alert>
  );
}
