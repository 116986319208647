import ExcelJS from "exceljs";
/**
 * Convert table and data into csv string
 * @param {*} headers
 * @param {*} tabledata
 * @returns Blob.
 * @see https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export default async function TableToExcel(displaylocation, displaysector, location, sector, gas, fuel, emissiontype, year, headers, tabledata) {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "ANGA";
  workbook.created = new Date();
  const sheet = workbook.addWorksheet("Data");


  const cols = [];
  for (let i = 0; i < headers.length; i++) {
    const h = headers[i];
    cols.push({ header: h.label, key: h.key, width: 32 });
  }
  sheet.columns = cols;

  for (let r = 0; r < tabledata.length; r++) {
    const row = tabledata[r];
    const data = [];
    for (let i = 0; i < headers.length; i++) {
      const h = headers[i];
      data.push(row[h.key]);
    }    
    sheet.addRow(data);
  }

  if(location && displaylocation) {
    const t = [];
    t.push("Location: "+location);
    sheet.addRow(t);
  }
  if(sector && displaysector) {
    const t = [];
    t.push("Sector: "+sector);
    sheet.addRow(t);
  }
  if(gas) {
    const t = [];
    t.push("Gas: "+gas);
    sheet.addRow(t);
  }
  if(fuel) {
    const t = [];
    t.push("Fuel: "+fuel);
    sheet.addRow(t);
  }
  if(emissiontype) {
    const t = [];
    t.push("Emission: "+emissiontype);
    sheet.addRow(t);
  }
  if(year) {
    const t = [];
    t.push("Year: "+year);
    sheet.addRow(t);
  }

  const sourcerow = [];
  sourcerow.push("Source : https://greenhouseaccounts.climatechange.gov.au");
  sheet.addRow(sourcerow);

  const t = await workbook.xlsx.writeBuffer();
  return new Blob([new Uint8Array(t, 0, t.byteLength)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
}
