export default class TreeUtility {

  /**
   * Check if all values are children of any parent in the parents list
   * Used to determine if context dependent hierarchies can be displayed
   * @param idarray - Array of ids
   * @param parents - Array of parent nodes
   */
  static allWithinParents(tree, idarray, parents) {
    if (!Array.isArray(parents)) return false;
    if (0 === parents.length) return false;

    for (const s of idarray) {
      let f = false;
      for (const n of parents) {
        if (TreeUtility.findinTree(tree, n, s)) {
          f = true;
          break;
        }
      }
      if (!f) return false;
    }

    return true;
  }

  /**
   * Return node from a tree with specified id.
   * @param {*} tree Tree to search through
   * @param {*} startnode Node from which to start search or null if searching from the top
   * @param {*} id Node id to search for
   * @returns Specified node or null
   */
  static findinTree(tree, startnode, id) {
    if (tree === null) return null;
    if (!id) return null;
    if (startnode) {
      if (startnode.id === id) return startnode;
      else {
        for (const c of startnode.children) {
          const v = TreeUtility.findinTree(tree, c, id);
          if (v) {
            return v;
          }
        }
      }
    } else {
      for (const c of tree) {
        const v = TreeUtility.findinTree(tree, c, id);
        if (v) {
          return v;
        }
      }
    }
    return null;
  }

  /**
   * Return all ascendants containing this id.
   * This id should be removed if ascendant is included
   * @param {*} tree
   * @param {*} iddescendant
   */
  static findAscendantsTree(tree, iddescendant) {
    let ascendants = new Set();
    if (Array.isArray(iddescendant)) {
      for (const i of iddescendant) {
        if (Array.isArray(tree)) {
          for (const n of tree) {
            if (this.isAscendant(n, i, false)) {
              ascendants.add(n.id);
              for (const c of n.children) {
                const m = this.findAscendantsTree(c, i);
                if (m.has(i)) m.delete(i);
                const m1 = this.union(ascendants, m);                
                ascendants = m1;
              }
            }
          }
        }
        else {
          const n = tree;
          if (this.isAscendant(n, i, false)) {
            ascendants.add(n.id);
            for (const c of n.children) {
              const m = this.findAscendantsTree(c, i);
              if (m.has(i)) m.delete(i);
              const m1 = this.union(ascendants, m);
              ascendants = m1;
            }
          }
        }
      }
    }
    else {
      const i = iddescendant
      if (Array.isArray(tree)) {
        for (const n of tree) {
          if (this.isAscendant(n, i)) {
            ascendants.add(n.id);
            for (const c of n.children) {
              const m = this.findAscendantsTree(c, i);
              if (m.has(i)) m.delete(i);
              const m1 = this.union(ascendants, m);
              ascendants = m1;
            }
          }
        }
      }
      else {
        const n = tree;
        if (this.isAscendant(n, i, false)) {
          ascendants.add(n.id);
          for (const c of n.children) {
            const m = this.findAscendantsTree(c, i);
            if (m.has(i)) m.delete(i);
            const m1 = this.union(ascendants, m);
            ascendants = m1;
          }
        }
      }
    }
    return ascendants;
  }

  /**
 * Return all ascendants containing this id.
 * This includes current id
 * @param {*} tree
 * @param {*} iddescendant
 */
  static findAscendantsAndItselfTree(tree, iddescendant) {
    let found = new Set();
    let ascendants = new Set();
    if (Array.isArray(iddescendant)) {
      for (const i of iddescendant) {
        let a = new Set();
        for (const t of tree) {
          if (this.isDescendant(t, i)) {
            if (t.id !== i) {
              a.add(t.id);
              found.add(i);
            }
            for (const c of t.children) {
              const ascendantsc = this.findAscendantsNode(c, i);
              if (0 < ascendantsc.size) {
                const t = this.union(a, ascendantsc);
                a = t;
              }
            }
          }
        }
        const t1 = this.union(ascendants, a);
        ascendants = t1;
      }
    } else {
      for (const t of tree) {
        if (this.isDescendant(t, iddescendant)) {
          if (t.id !== iddescendant) {
            ascendants.add(t.id);
            found.add(iddescendant);
          }
          for (const c of t.children) {
            const ascendantsc = this.findAscendantsNode(c, iddescendant);
            if (0 < ascendantsc.size) {
              const t = this.union(ascendants, ascendantsc);
              ascendants = t;
            }
          }
        }
      }
    }
    return ascendants;
  }

  /**
   * Return all ascendants containing this id.
   * @param {*} node
   * @param {*} id
   */
  static findAscendantsNode(node, id) {
    let ascendants = new Set();

    if (this.isDescendant(node, id)) {
      ascendants.add(node.id);
      for (const c of node.children) {
        const ascendantsc = this.findAscendantsNode(c, id);
        if (0 < ascendantsc.size) {
          const t = this.union(ascendants, ascendantsc);
          ascendants = t;
        }
      }
    }
    return ascendants;
  }

  /**
   * True if id is a ascendant of node within tree
   * @param {*} node
   * @param {*} id
   * @param {*} includeitself - If true a node is a parent of itself
   */
  static isAscendant(node, id) {
    if (node.id === id) return true;
    if (0 === node.children.length) return false;
    for (const c of node.children) {
      if (this.isAscendant(c, id)) return true;
    }
    return false;
  }

  /**
   * True if id is a descendant of node within tree
   * @param {*} tree
   * @param {*} node
   * @param {*} id
   */
  static isDescendant(node, id) {
    if (node.id === id) return true;
    if (0 === node.children.length) return false;
    for (const c of node.children) {
      if (this.isDescendant(c, id)) return true;
    }
    return false;
  }

  /**
   * Search tree for term
   * @param {*} tree
   * @param {*} term
   * @returns Array of search results. Array will be empty if no results found
   */
  static searchTree(tree, term) {
    let r = [];
    if (!tree) return r;
    if (!term) return r;

    // Search case insensitive
    const re = new RegExp(term, "i");

    if (Array.isArray(tree)) {
      for (const a of tree) {
        if (a.name.match(re)) {
          r.push({ id: a.id, value: a.name, c: a.confidential });
        }
        for (const c of a.children) {
          const r1 = TreeUtility.searchTree(c, term);
          if (r1) {
            r = r.concat(r1);
          }
        }
      }
    } else {
      if (tree.name.match(re)) {
        r.push({ id: tree.id, value: tree.name, c: tree.confidential });
      }
      for (const c of tree.children) {
        const r1 = TreeUtility.searchTree(c, term);
        if (r1) {
          r = r.concat(r1);
        }
      }
    }
    let i = 1;
    for (const row of r) {
      row.count = i++;
    }
    return r;
  }

  /**
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
   * @param {*} setA
   * @param {*} setB
   * @returns
   */
  static union(setA, setB) {
    let _union = new Set(setA);
    for (let elem of setB) {
      _union.add(elem);
    }
    return _union;
  }
}
