// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import IconClose from "@mui/icons-material/Close";
import IconHome from "@mui/icons-material/Home";
import IconMenu from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";
import Pages from "../classes/Page";

export default class Topbar extends React.Component {

  anchorbox = React.createRef();

  pages = [
    Pages.EmissionsHighlights,
    Pages.EmissionsInventories,
    Pages.EmissionsProjections,
    Pages.DataSetAPI,
    Pages.HelpSupport,
  ];

  state = {
    openmobilemenu: false,
    selectedmenu: Pages.Home.page,
  };

  handleOpenNavMenu = (event) => {
    this.setState({ openmobilemenu: true });
  };

  handleCloseNavMenu = (event) => {
    // Set the selected element to null to close menu
    event.stopPropagation();
    event.preventDefault()
    this.setState({ openmobilemenu: false });
  };

  handleNavigation = (page) => {
    // Set currently selected page
    this.setState({ selectedmenu: page });
    // Pass the name of the selcted page to the parent component
    this.props.onHandleNavigation(page);
    // Close menu
    this.setState({ openmobilemenu: false });
  };

  render() {
    return (
      <Box sx={MStyles.navbox} >
        <Container>
          <nav aria-label="Main group navigation">
            {/* Mobile */}
            <Box
              sx={{ display: { xs: "box", md: "none" } }}
              id="mbox"
              ref={this.anchorbox}
              role="dialog"
            >
              <IconButton
                id="openmenu"
                size="large"
                aria-label="open dropdown menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleOpenNavMenu}
                color="inherit"
                sx={!this.state.openmobilemenu ? { display: "flex" } : { display: "none" }}
              >
                <IconMenu />
                <Typography sx={{ ml: 2, display: { xs: "flex", md: "none" } }}>
                  Menu
                </Typography>
              </IconButton>

              <IconButton
                id="closemenu"
                size="large"
                aria-label="close dropdown menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleCloseNavMenu}
                color="inherit"
                disableRipple
                sx={this.state.openmobilemenu ? { display: "flex" } : { display: "none" }
                }
              >
                <IconClose />
                <Typography sx={{ ml: 2, display: { xs: "flex", md: "none" } }}>
                  Close menu
                </Typography>
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={this.anchorbox.current}
                open={this.state.openmobilemenu}
                onClose={this.handleCloseNavMenu}
                sx={MStyles.menumobilebox}
              >
                <MenuItem
                  disableRipple
                  disableTouchRipple
                  onClick={() => { this.handleNavigation(Pages.Home.page); }}
                  sx={this.state.selectedmenu === Pages.Home.page
                    ? MStyles.menuitemselectedmobile
                    : MStyles.menuitemmobile}
                  selected={this.state.selectedmenu === Pages.Home.page}
                  tabIndex={0}
                >
                  <IconHome titleAccess="Home" />
                </MenuItem>
                {this.pages.map((p) => (
                  <MenuItem
                    disableRipple
                    disableTouchRipple
                    key={p.page}
                    onClick={() => { this.handleNavigation(p.page); }}
                    sx={
                      this.state.selectedmenu === p.page
                        ? MStyles.menuitemselectedmobile
                        : MStyles.menuitemmobile
                    }
                    selected={this.state.selectedmenu === p.page}
                    tabIndex={0}
                  >
                    {p.title}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Desktop */}
            <Stack
              direction="row"
              alignItems="stretch"
              spacing={0}
              sx={MStyles.navstackdesktop}
              role="menubar"
            >
              <MenuItem
                disableRipple
                onClick={() => {
                  this.handleNavigation(Pages.Home.page);
                }}
                sx={
                  this.state.selectedmenu === Pages.Home.page
                    ? MStyles.menuitemselected
                    : MStyles.menuitem
                }
                tabIndex={0}
                title="Home"
                selected={this.state.selectedmenu === Pages.Home.page}
              >
                <IconHome />
              </MenuItem>

              {this.pages.map((p) => (
                <MenuItem
                  disableRipple
                  key={p.page}
                  onClick={() => {
                    this.handleNavigation(p.page);
                  }}
                  sx={
                    this.state.selectedmenu === p.page
                      ? MStyles.menuitemselected
                      : MStyles.menuitem
                  }
                  tabIndex={0}
                  title={p.title}
                  selected={this.state.selectedmenu === p.page}
                >
                  {p.title}
                </MenuItem>
              ))}
            </Stack>
          </nav>
        </Container>
      </Box>
    );
  }
}
