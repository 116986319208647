// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Images
import Logo from "../img/DCCEEW_hires_inline_mono.png";
import LogoMobile from "../img/DCCEEW_hires_stacked_mono.png";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";

// Components
import Search from "./Search";

export default function Header(props) {
  const [searchterm, setSearchTerm] = React.useState(props.searchterm);
  const [skiplinkheight, setSkipLinkHeight] = React.useState(0);

  React.useEffect(() => {
    setSearchTerm(props.searchterm);
  }, [props.searchterm]);

  const handleSearch = (term) => {
    props.onHandleSearch(term);
  };

  const linkhasfocus = () => {
    setSkipLinkHeight(45);
  }

  const linklostfocus = () => {
    setSkipLinkHeight(0);
  }

  // To detect if this is a a mobile screen we need to check for sm
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  
  return (
    <>
      <Box id="skiplinks" sx={{ height: skiplinkheight }} >
        <Link sx={MStyles.skiplink} id="skiplinkmain" href="#content" onFocus={linkhasfocus} onBlur={linklostfocus} >Skip to main content</Link>
        <Link sx={MStyles.skiplink} id="skiplinknavigation" href="#navigation" onFocus={linkhasfocus} onBlur={linklostfocus}>Skip to main navigation</Link>
        <Link sx={MStyles.skiplink} id="skiplinkfooter" href="#footer" onFocus={linkhasfocus} onBlur={linklostfocus}>Skip to footer</Link>
        <Link sx={MStyles.skiplink} id="skiplinksearch" href="#search" onFocus={linkhasfocus} onBlur={linklostfocus}>Skip to search</Link>
      </Box>
      <Container role="banner" id="HeaderBox">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: { xs: 2.5, sm: 2.5, md: 2.5 } }}
        >
          <Grid item sx={MStyles.logostyle} component="a" id="logolink" href={ExternalURL.DEPARTMENT.url} alt={ExternalURL.DEPARTMENT.alt} tabIndex={0}>
            {!small && (<img style={{display: "inline"}} src={LogoMobile} width="343" alt={ExternalURL.DEPARTMENT.alt} />)}
            {small && (<img style={{display: "inline"}} src={Logo} width="308" alt={ExternalURL.DEPARTMENT.alt} />)}
          </Grid>
          <Grid item sx={{ mt: { xs: 2.5, sm: 2.5, md: 2.5 } }} xs={12} sm={4} md={3}>
            <Search searchterm={searchterm} onHandleSearch={handleSearch} />
          </Grid>
        </Grid>

        <Typography sx={MStyles.header_title}>
          Australia's National Greenhouse Accounts
        </Typography>
      </Container>
    </>
  );
}
