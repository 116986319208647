// React
import * as React from "react";

// MUI
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Display information about current selection in the data visualisation box
 * @param {*} props
 * @returns
 */
export default function CurrentSelection(props) {
  const [isProjections, setIsProjections] = React.useState(props.isprojections);
  const [location, setLocation] = React.useState(props.location);
  const [sector, setSector] = React.useState(props.sector);
  const [gas, setGas] = React.useState(props.gas);
  const [fuel, setFuel] = React.useState(props.fuel);
  const [emissiontype, setEmissionType] = React.useState(props.emissiontype);
  const [year, setYear] = React.useState(props.year);

  React.useEffect(() => {
    setIsProjections(props.isprojections);
    setLocation(props.location);
    setSector(props.sector);
    setGas(props.gas);
    setFuel(props.fuel);
    setEmissionType(props.emissiontype);
    setYear(props.year);
  }, [
    props.isprojections,
    props.location,
    props.sector,
    props.gas,
    props.fuel,
    props.emissiontype,
    props.year,
  ]);

  return (
    <div>
      <Typography variant="h3" sx={MStyles.h3}>
        {!isProjections && ("Emissions for:")}
        {isProjections && ("Projections for:")}
      </Typography>
      <Typography variant="body1" sx={MStyles.body}>
        <strong>Location:</strong> {location}
      </Typography>
      <Typography variant="body1" sx={MStyles.body}>
        <strong>Sector(s):</strong> {sector}
      </Typography>
      {year ? (
        <Typography variant="body1" sx={MStyles.body}>
          <strong>Year:</strong> {year}
        </Typography>
      ) : null}
      <Typography variant="body1" sx={MStyles.body}>
        <strong>Gas:</strong> {gas}
      </Typography>
      {fuel ? (
        <Typography variant="body1" sx={MStyles.body}>
          <strong>Fuel:</strong> {fuel}
        </Typography>
      ) : null}
      {emissiontype ? (
        <Typography variant="body1" sx={MStyles.body}>
          <strong>Emissions:</strong> {emissiontype}
        </Typography>
      ) : null}
    </div>
  );
}
