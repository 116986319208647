// React
import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Best experience message
 */
export default function BestExperience() {
  
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  if (sm) return null;

  return (
    <Alert
      severity="info"
      icon={<ErrorIcon fontSize="large" sx={{ color: "#2E5299" }} />}
      sx={MStyles.dimensionboxmessagestyle}
    >
      <AlertTitle>
        <Typography component="h4" sx={MStyles.warning}>
        For best experience, please view this graph in landscape mode.
        </Typography>
      </AlertTitle>
    </Alert>
  );
}
