// React
import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// recharts
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Classes
import ChartUtility from "../classes/ChartUtility";
import MStyles from "../classes/MStyles";

// Components
import BestExperience from "./BestExperience";

/**
 * Generate line chart. Used in visualisation
 * @param {*} props
 * @returns
 */
export default function CustomLineChart(props) {
  const myref = React.useRef();
  const [digits, setDigits] = React.useState(props.digits);
  const [key, setKey] = React.useState(Date.now());
  const [isdate, setIsDate] = React.useState(props.isdate);
  const [chartData, setChartData] = React.useState(props.data);
  const [lines, setLines] = React.useState(props.lines);
  const [xAxisTitle, setXAxisTitle] = React.useState(props.XAxisTitle);
  const [units, setUnits] = React.useState(props.units);

   /**
   * Update value after changing props
   */
  React.useLayoutEffect(() => {
    setDigits(props.digits);
    setIsDate(props.isdate);
    setChartData(props.data);
    setLines(props.lines);
    setXAxisTitle(props.XAxisTitle);
    setUnits(props.units);
    setKey(Date.now());
  }, [
    props.digits,
    props.isdate,
    props.data,
    props.line,
    props.lines,
    props.units,
    props.XAxisTitle,
    props.YAxisTitle,
  ]);
  
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const xtitle = (!sm) ? "" : xAxisTitle;
  const ytitle = (!sm) ? "" : "Emissions";
  const s = { 
    "marginInlineStart" : (sm ? "40px" : "0px"),
    "marginInlineEnd" : (sm ? "40px" : "0px")
  }

  if (!props.isvisible) return null;

  return (
    <Grid item xs={12}>
      <BestExperience />
      <figure style={s}>
        <figcaption style={{ captionSide: "top", marginBottom:10 }}>
          <Typography variant="h4" sx={MStyles.h4}>
            {props.title}
          </Typography>
        </figcaption>
        <ResponsiveContainer width="100%" aspect="1.6" key={key} ref={myref} >
          <LineChart
            key={key}
            data={chartData}
            margin={MStyles.chartmargin}
            ref={props.forwardRef}
            width="100%"
            height="100%"
            stackOffset="sign"
          >
            <CartesianGrid
              stroke="#E6E6E6"
              strokeDasharray="5 5"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey={isdate ? "date" : "label"}
              axisLine={{ stroke: "#333333" }}
              label={{
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
                position: "bottom",
                value: xtitle,
              }}
              padding={{ bottom: 20 }}
              minTickGap="7"
              tick={{
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
              }}
              tickFormatter={
                isdate
                  ? ChartUtility.tickdateFormater
                  : ChartUtility.ticklocationFormatter
              }
            />
            <YAxis
              yAxisId="yline"
              domain={[0, "auto"]}
              scale="auto"
              type="number"
              label={{
                angle: -90,
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "nomal",
                // offset: 5, // needs to depend on scale. default 5
                position: "left",
                value: ytitle,
              }}
              position="left"
              tick={{
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
              }}
              tickFormatter={ChartUtility.formatYAxisNumbers}
              unit={units}
            />
            <Tooltip
              itemStyle={{ color: "#333333" }} // Remove colour requested by Bree email 11/04/2022
              formatter={(x) => {return new 
                Intl.NumberFormat("en-AU",{
                  minimumFractionDigits: digits,
                  maximumFractionDigits: digits,
                }).format(x) + " "+ props.units;
              }}
            />
            {sm && (
              <Legend
                wrapperStyle={{
                  fontFamily: "Public Sans, Arial, Segoe",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: "normal",
                  color: "#333333",
                  postion: "bottom",
                  paddingTop: 30, // Needs to be big enough to accommodate xAxis title
                }}
                formatter={(value, entry, index) => (
                  <span style={{ color: "#333333" }}>{value}</span>
                )} // Remove colour requested by Bree email 11/04/2022
              ></Legend>
            )}
            <ReferenceLine yAxisId="yline" y={0} stroke="#333333" />
            {lines.map((line) => (
              <Line
                key={line.key}
                type="monotone"
                dataKey={line.key}
                stroke={MStyles.chartcolours[lines.indexOf(line) % 16]}
                strokeDasharray={
                  MStyles.linestrokedash[lines.indexOf(line) % 16]
                }
                yAxisId="yline"
                label={line.label}
                name={line.label}
              />
            ))}

            {/* {sm & ChartUtility.titlex(windowDimensions.width) &&
              ChartUtility.titley(windowDimensions.width) && (
                <text
                  x={ChartUtility.titlex(windowDimensions.width)}
                  y={ChartUtility.titley(windowDimensions.width)}
                  style={{
                    fontSize: "14",
                    fontFamily: "Public Sans",
                    fontWeight: "normal",
                    color: "#333333",
                  }}
                >
                  {title}
                </text>
              )}
            {sm & ChartUtility.sourcey(windowDimensions.width) && (
              <text
                x={0}
                y={ChartUtility.sourcey(windowDimensions.width)}
                style={{
                  fontSize: "14",
                  fontFamily: "Public Sans",
                  fontWeight: "normal",
                  color: "#333333",
                }}
              >
                Source: https://ageis.climagechange.gov.au
              </text>
            )} */}
          </LineChart>
        </ResponsiveContainer>
      </figure>
    </Grid>
  );
}
