// React
import * as React from "react";

// MUI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";

import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Classes
import MStyles from "../classes/MStyles";
import TreeUtility from "../classes/TreeUtility";

/**
 * Single select dialog for tree data. Used for displaying hierarchies.
 * Properties
 * open : true or false to show this dialog
 * tree : data structure to be supplied to the TreeView component
 * selected : key of the selected node
 * title : Title to display
 * onCancel : Handle to cancel selection
 * onSave : Handle to pass new selection
 */
export default function SingleSelectTree(props) {
  const [selected, setSelected] = React.useState(props.selected);
  const [expanded, setExpanded] = React.useState([props.selected]);

  /**
   * Find all ascendants of selected nodes and expand them
   */
  React.useEffect(() => {
    const t = [];
    const m = TreeUtility.findAscendantsTree(props.tree, props.selected);
    for (const s of m) {
      t.push(s);
    }
    setExpanded(t);
  }, [props.tree, props.selected]);

  /**
* Aria requirement to close dialog on escape
* @param {*} e 
*/
  function ariaescape(e) {
    if (27 === e.keyCode) {
      cancelselect();
    }
  }

  function cancelselect() {
    props.onCancel();
  }

  function confirm() {
    props.onSave(selected);
  }

  /**
   * Handle colapse event
   * @param {*} event
   * @param {*} nodeId
   */
  function colapseNode(event, nodeId) {
    const t = []
    for (const n of expanded) {
      if (n !== nodeId) {
        t.push(n);
      }
    }
    setExpanded(t);
  }

  /**
   * Handle colapse event
   * @param {*} event
   * @param {*} nodeId
   */
  function expandNode(event, nodeId) {
    const t = [];
    for (const n of expanded) {
      t.push(n);
    }
    t.push(nodeId);
    setExpanded(t);
  }

  function handleToggle(event, nodeIds) {

    setExpanded(nodeIds);
  }

  /**
   * Capturing click from the tree. Do nothing.
   * @param {*} event
   * @param {*} nodeIds
   * @returns
   */
  function handleSelect(event, nodeIds) {
    return;
  }

  /**
   * Click on label will trigger effect
   * @param {*} event
   * @param {*} nodeID
   */
  function labelClick(event, nodeID) {
    setSelected(nodeID);
  }

  /**
   * Recursively render tree nodes.
   * See for customization
   * https://smartdevpreneur.com/how-to-create-a-material-ui-treeview-with-styled-checkboxes/
   * @param {*} nodes
   * @returns
   */
  function renderTree(nodes) {
    if (null == nodes) return null;
    if (Array.isArray(nodes)) {
      return nodes.map((node) => renderTree(node));
    } else {
      if (nodes.confidential) {
        return (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            expandIcon={
              <ExpandMore
                onClick={(event) => {
                  expandNode(event, nodes.id);
                }}
              />
            }
            collapseIcon={
              <ExpandLessIcon
                onClick={(event) => {
                  colapseNode(event, nodes.id);
                }}
              />
            }
            label={
              <Typography sx={MStyles.treelabeldisabledstyle}>
                {nodes.name}
              </Typography>
            }
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTree(node))
              : null}
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={
              <FormControlLabel
                control={
                  <Radio
                    onClick={(event) => {
                      labelClick(event, nodes.id);
                    }}
                  />
                }
                id={nodes.id}
                value={nodes.name}
                checked={nodes.id === selected}
                label={
                  <Typography sx={MStyles.treelabelstyle}>
                    {nodes.name}
                  </Typography>
                }
              />
            }
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTree(node))
              : null}
          </TreeItem>
        );
      }
    }
  }

  // To detect if this is a a mobile screen we need to check for sm
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Dialog open={props.open} fullScreen={!small} aria-labelledby="title" aria-describedby="title" aria-modal="true" onKeyDown={ariaescape}>
      <Box
        display="flex"
        sx={{ justifyContent: "flex-end", marginBottom: 0, paddingBottom: 0 }}
      >
        <IconButton
          onClick={() => {
            cancelselect();
          }}
        >
          <CloseIcon titleAccess="Close dialog" />
        </IconButton>
      </Box>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1" sx={MStyles.h1} id="title">
              {props.title}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TreeView
          aria-label={props.title}
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          multiSelect={false}
          selected={selected}
          sx={{ flexGrow: 1 }}
          onNodeSelect={(event, nodeIds) => {
            handleSelect(event, nodeIds);
          }}
          onNodeToggle={(event, nodeIds) => {
            handleToggle(event, nodeIds);
          }}
        >
          {renderTree(props.tree)}
        </TreeView>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.linkbutton}
          onClick={() => {
            cancelselect();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.buttondimstyle}
          onClick={() => {
            confirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
