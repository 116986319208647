// React
import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

// Components
import NormalCard from "./NormalCard";

/**
 * Navigation page for emissions inventories
 */
export default class EmissionsInventories extends React.Component {
  page = Page.EmissionsInventories;

  cards = [
    {
      page: Page.Paris.page,
      header: "Paris Agreement inventory",
      text: "National inventory used to track progress towards Australia's Paris Agreement targets.",
    },
    {
      page: Page.States.page,
      header: "State and territory emissions",
      text: "National inventory disaggregated by Australian state and territory.",
    },
    {
      page: Page.Economic.page,
      header: "National inventory by economic sector",
      text: "National inventory disaggregated using Australian and New Zealand Standard Industrial Classification (ANZSIC).",
    },
    {
      page: Page.Quarterly.page,
      header: "National Greenhouse Inventory Quarterly Update",
      text: "The latest quarterly update of Australia's national greenhouse gas inventory.",
    },
    {
      page: Page.Scope2.page,
      header: "Scope 2 emissions by economic sector",
      text: "Estimates of indirect emissions from purchased electricity generation.",
    },
    {
      page: Page.ParisMemo.page,
      header: "Paris Agreement inventory memo items",
      text: "Additional emissions sources reported under the Paris Agreement (including international aviation and shipping).",
    },
    {
      page: Page.UNFCCC.page,
      header: "UNFCCC inventory",
      text: "National inventory used to report emissions under the UNFCCC.",
    },
    {
      page: Page.Kyoto.page,
      header: "Kyoto Protocol inventory",
      text: "National inventory used to track progress against Australia's 2020 targets.",
    },
  ];

  handleNavigation = (page) => {
    this.props.onHandleNavigation(page);
  };

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          Find information on Australia&apos;s greenhouse gas emissions, including inventories used to
          meet emissions reporting requirements under the Paris Agreement,
          UNFCCC and Kyoto Protocol. Emissions data is reported since 1990 and
          can be viewed by state and territory, economic sector and scope 2
          emissions.
        </Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-center"
          sx={{marginTop: 0, marginBottom:1, marginRight:1, marginLeft:1, paddingTop:1, paddingBottom:1, paddingLeft:1, paddingRight:1 }}
        >
          {this.cards.map((row) => (
            <NormalCard
              key={row.page}
              page={row.page}
              header={row.header}
              text={row.text}
              onHandleNavigation={() => {
                this.handleNavigation(row.page);
              }}
            />
          ))}
        </Grid>
      </div>
    );
  }
}
