
export default class Page {
    page = "";
    title = "";
    path = [];

    constructor (Page, Title, Path)
    {
        this.page = Page;
        this.title = Title;
        this.path = Path;
    }
    static About = new Page("About", "About this website", ["Home","HelpSupport","About"]);
    static Accessibility= new Page("Accessibility", "Our approach to accessibility", ["Home","Accessibility"]);
    static Activity = new Page("Activity", "Activity tables", ["Home","DataSetAPI", "Activity"]);
    static Baseline = new Page("Baseline", "Baseline scenario", ["Home","EmissionsProjections","Baseline"]);
    static Compiled = new Page("Compiled", "API (bulk data download)", ["Home","DataSetAPI","Compiled"]);
    static Contact = new Page("Contact", "Contact us", ["Home","HelpSupport","Contact"]);
    static DataSetAPI = new Page("DataSetAPI", "Datasets and API", ["Home","DataSetAPI"]);
    static Economic = new Page("Economic", "National inventory by economic sector", ["Home","EmissionsInventories","Economic"]);
    static EmissionsHighlights = new Page("EmissionsHighlights", "Latest emissions data", ["Home","EmissionsHighlights"]);
    static EmissionsInventories = new Page("EmissionsInventories", "Emissions inventories", ["Home","EmissionsInventories"]);
    static EmissionsProjections = new Page("EmissionsProjections", "Emissions projections", ["Home","EmissionsProjections"]);
    static HelpSupport = new Page("HelpSupport", "Help and support", ["Home","HelpSupport"]);
    static FAQ = new Page("FAQ", "Frequently asked questions", ["Home","HelpSupport","FAQ"]);
    static Glossary = new Page("Glossary", "Glossary", ["Home","HelpSupport","Glossary"]);
    static Hightech = new Page("Hightech", "'With additional measures' scenario", ["Home","EmissionsProjections","Hightech"]);
    static Home = new Page("Home", "Home", ["Home"]);
    static Kyoto = new Page("Kyoto", "Emissions under Kyoto Protocol", ["Home","EmissionsInventories","Kyoto"]);
    static Paris = new Page("Paris", "Paris Agreement inventory", ["Home","EmissionsInventories","Paris"]);
    static ParisMemo = new Page("ParisMemo", "Paris Agreement inventory memo items", ["Home","EmissionsInventories","ParisMemo"]);
    static Quarterly = new Page("Quarterly", "National Greenhouse Inventory Quarterly Update", ["Home","EmissionsInventories","Quarterly"]);
    static Scope2 = new Page("Scope2", "Scope 2 emissions by economic sector", ["Home","EmissionsInventories","Scope2"]);
    static SearchResults = new Page("SearchResults", "Search", ["Home","SearchResults"]);
    static States = new Page("States", "Emissions by state and territory", ["Home","EmissionsInventories","States"]);
    static UNFCCC = new Page("UNFCCC", "UNFCCC emissions (AR4)", ["Home","EmissionsInventories","UNFCCC"]);

    static list = [
        this.About,
        this.Accessibility,
        this.Activity,
        this.Baseline,
        this.Compiled,
        this.Contact,
        this.DataSetAPI,
        this.Economic,
        this.EmissionsHighlights,
        this.EmissionsInventories,
        this.EmissionsProjections,
        this.HelpSupport,
        this.FAQ,
        this.Glossary,
        this.Hightech,
        this.Home,
        this.Kyoto,
        this.Paris,
        this.ParisMemo,
        this.Quarterly,
        this.Scope2,
        this.SearchResults,
        this.States,
        this.UNFCCC
        ];
}
