// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TransformationUtility from "../classes/TransformationUtility";
import TreeUtility from "../classes/TreeUtility";

// Components
import MultipleSelectTree from "./MultipleSelectTree";
import SingleSelectGasTree from "./SingleSelectGasTree";
import SingleSelectTree from "./SingleSelectTree";
import Visualisation from "./Visualisation";

/**
 * Display data for Memo Items
 * @param {*} props
 * @returns
 */
export default function ParisMemo(props) {

  // Reducer function for a filter
  function reducer(state, filter) {
    return {
      fueldisabled: filter.fueldisabled,
      fuelselected: filter.fuelselected,
      gasselected: filter.gasselected,
      locationselected: filter.locationselected,
      sectorselected: filter.sectorselected,
      sectortree: filter.sectortree
    };
  }

  // Use reducer to minimize invocations of API
  // This should contain all variables that are invoked by API
  // It should be initialized with default values
  const [filter, dispatch] = React.useReducer(
    reducer,
    {
      fueldisabled : true,
      fuelselected: props.fuelnotavailableid,
      gasselected: props.gasdefaultar,
      locationselected: props.locationselected,
      sectorselected: props.sectorselected,
      sectortree: props.sectortree
    }
  )

  const [charttype, setChartType] = React.useState("StackedBar");
  const [displayline, setDisplayLine] = React.useState(true);
  const [displaybar, setDisplayBar] = React.useState(false);
  const [displaystackedbar, setDisplayStackedBar] = React.useState(true);

  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);

  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);

  const [fueldialog, setFuelDialog] = React.useState(false);
  const [fuelkey, setFuelKey] = React.useState("f" + Date.now());

  const [gasdialog, setGasDialog] = React.useState(false);
  const [gaskey, setGasKey] = React.useState("g" + Date.now());

  const [locationdialog, setLocationDialog] = React.useState(false);
  const [locationkey, setLocationKey] = React.useState("l" + Date.now());

  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  const [loading, setLoading] = React.useState(false);

  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);
  const [location, setLocation] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [gas, setGas] = React.useState("");
  const [fuel, setFuel] = React.useState("");
  const [emissiontype] = React.useState("");
  const [units, setUnits] = React.useState("");

  const page = Page.ParisMemo;
  const digits = 4;

  //#region Effects. Effects are run in order they are specified
  /**
   * Reset to default if pages changes.
   */
  React.useEffect(() => {
    if (props.page === Page.SearchResults.page) return;
    if (props.page !== page.page) {
      dispatch({
        fueldisabled: true,
        fuelselected: props.fuelnotavailableid,
        gasselected: props.gasdefaultar,
        locationselected: props.locationselected,
        sectorselected: props.sectorselected,
        sectortree: props.sectortree  
      })
    }
  }, [
    page.page,
    props.page,
    props.fuelnotavailableid,
    props.gasdefaultar,
    props.locationselected,
    props.sectorselected,
    props.sectortree,
  ]);

    /**
   * Set search terms. When entering values should be set to defaults
   */
     React.useEffect(() => {
      if (props.page === Page.SearchResults.page) {
  
        let f = props.fuelnotavailableid;
        let s = props.sectorselected;
 
        if (-1 !== props.searchmemoid) { s = props.searchmemoid; }
  
        const fdisabled = !TreeUtility.allWithinParents(filter.sectortree, [s], props.topfuelsectors);
        if (!fdisabled ) { f = props.fuelselected; }
  
        dispatch({
          fueldisabled: fdisabled,
          fuelselected: f,
          gasselected: props.gasdefaultar,
          locationselected: props.locationselected,
          sectorselected: s,
          sectortree: props.sectortree  
  
        })
      }
    }, [
      filter,
      props.fuelnotavailableid,
      props.fuelselected,
      props.gasdefaultar,
      props.locationselected,
      props.page,
      props.searchmemoid,
      props.sectorselected,
      props.sectortree,
      props.topfuelsectors
    ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    /**
     * Transform data into components used by visualization.
     * This follows successful data load so it is included in this effect.
     * @param {*} apid
     */
    function transformdata(apid) {
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];

      // Populate data for XAxis key and name are reserved names
      // Populae data for year table column
      TransformationUtility.prepareTimeSeries(
        props.firstyear,
        props.lastyear,
        _headers,
        _chartdata,
        _tabledata
      );

      const _generationspread = TransformationUtility.calculateGenerationSpread(
        filter.sectortree,
        filter.sectorselected
      );
      const maxvalue = TransformationUtility.getMaxValue(
        apid,
        filter.sectorselected,
        _tabledata
      );

      const scale = TransformationUtility.getScale(maxvalue);

      const hd = TransformationUtility.populateTimeSeries(apid, filter.sectorselected, filter.sectortree, _chartdata, _lines, _tabledata, _headers, digits, scale.factor);

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const gas = TransformationUtility.calculateGasDisplayName(
        props.gastreeraw,
        props.gastreear,
        filter.gasselected
      );

      const loc = TransformationUtility.calculateTitle(
        false,
        props.locationtree,
        filter.locationselected
      );
      const sectors1 = TransformationUtility.calculateSelectionList(
        filter.sectortree,
        filter.sectorselected
      );
      const f = TransformationUtility.calculateTitle(
        filter.fueldisabled,
        props.fueltree,
        filter.fuelselected
      );

      // Annual Australian Emissions CO2-e in (kt) accounted under Total UNFCCC
      const g = _generationspread ? "Line" : "StackedBar";

      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setChartType(g);
      setDisplayLine(true);
      setDisplayBar(false);
      setDisplayStackedBar(!_generationspread);
      setLocation(loc);
      setSector(sectors1);
      setHasData(hd.hasdata);
      setPartialData(hd.partialdata);
      setGas(gas);
      setFuel(f);
      setUnits(scale.units);
    }

    if (props.page !== page.page) return;
    if (!filter) return
    if (!filter.sectorselected) return;
    if (!props.fuelnotavailableid) return;
    if (!filter.fuelselected) return;
    if (!filter.locationselected) return;
    if (!filter.gasselected) return;
    if (!props.datatypenotavailableid) return;

    const base = "/api/OutputTimeSeries?";
    setLoading(true);
    const sectorstring = ArrayUtility.convertArrayToStringIDs(filter.sectorselected);
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        urlencodedsectorlist: sectorstring,
        locationid: filter.locationselected,
        fuelid: filter.fuelselected,
        gasid: filter.gasselected,
        datatypeid: props.datatypenotavailableid,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            setLines(null);
            setChartData(null);
            setHeaders(null);
            setTableData(null);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformdata(d);
          }
        } else {
          setLines(null);
          setChartData(null);
          setHeaders(null);
          setTableData(null);
        }
      })
      .catch((data) => {
        console.log("Error");
        console.log(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.datatypenotavailableid,
    props.firstyear,
    props.fuelnotavailableid,
    props.fueltree,
    props.gastreear,
    props.gastreeraw,
    props.lastyear,
    props.locationtree,
    props.page
  ]);

  /**
   * Get sector hierarchy if location has changed
   */
  function loadHierarchy(location) {
    setLoading(true);
    const base = "/api/SectorHierarchy?";
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(
      base +
      new URLSearchParams({
        LocationID: location,
        top: "Memo",
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          dispatch({
            fueldisabled: true,
            fuelselected: props.fuelnotavailableid,
            gasselected: props.gasdefaultar,
            locationselected: location,
            sectorselected: data.Default,
            sectortree: data.Nodes             
          })
        } else {
          console.error("Error: no data")
        }
      })
      .catch((data) => {
        console.log("Error");
        console.log(data);
      });
    // Need to load data so do not change loading status
  }

  //#region Fuel

  /**
   * Cancel selection of Fuel
   */
  function cancelselectFuel() {
    setFuelDialog(false);
  }

  /**
   * Open fuel selection dialog
   */
  function openFuelDialog() {
    setFuelKey("f" + Date.now());
    setFuelDialog(true);
  }

  /**
   * Save selection of fuel. Updata data
   * @param {*} value selected key
   */
  function saveselectFuel(value) {
    setFuelDialog(false);
    const f = filter;
    dispatch({
      fueldisabled: f.fueldisabled,
      fuelselected: value,
      gasselected: f.gasselected,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      sectortree: f.sectortree
    })
  }

  //#endregion

  //#region Gas

  /**
   * Cancel selection of Gas
   */
  function cancelselectGas() {
    setGasDialog(false);
  }

  /**
   * Open Gas dialog
   */
  function openGasDialog() {
    setGasKey("g" + Date.now());
    setGasDialog(true);
  }

  /**
   * Save selection of Gas
   */
  function saveselectGas(value) {
    setGasDialog(false);
    const f = filter;
    dispatch({
      fueldisabled: f.fueldisabled,
      fuelselected: f.fuelselected,
      gasselected: value,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      sectortree: f.sectortree
    })
  }

  //#endregion

  //#region Location

  /**
   * Cancel selection of Location
   */
  function cancelselectLocation() {
    setLocationDialog(false);
  }

  /**
   * Open location selection dialog
   */
  function openLocationDialog() {
    setLocationKey("l" + Date.now());
    setLocationDialog(true);
  }

  /**
   * Save selection of location.
   * Sector hierarchies are slightly different for different locations so need to update
   * both hierarchy and data after change of location
   */
  function saveselectLocation(value) {
    setLocationDialog(false);
    loadHierarchy(value);
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    const fdisabled = !TreeUtility.allWithinParents(filter.sectortree,value,props.topfuelsectors);

       
    let fuel = props.fuelnotavailableid;    
    if (fdisabled) {
      fuel = props.fuelnotavailableid;
    }
    else {
      if (filter.fueldisabled)
      {
        fuel = props.fuelselected;
      }
      else
      {
        fuel = filter.fuelselected;
      }
    }

    setSectorDialog(false);
    const f = filter;
    dispatch({
      fueldisabled: fdisabled,
      fuelselected: fuel,
      gasselected: f.gasselected,
      locationselected: f.locationselected,
      sectorselected: value,
      sectortree: f.sectortree
    })
  }

  //#endregion

  if (props.page !== page.page) return null;

  if (loading || !props.gastreear)
    return (
      <Box sx={MStyles.progressboxstyle} display={"flex"}>
        <CircularProgress></CircularProgress>
      </Box>
    );

  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        View emission estimates for sectors reported as Memo Items under the
        Paris Agreement. These estimates are not included in national inventory
        totals consistent with rules agreed by parties to the Paris Agreement.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        The chart shows total emissions accounted under UNFCCC Memo Items since 1990.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        Selecting a point on the chart will display the emissions for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openLocationDialog();
            }}
          >
            Select location
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
          >
            Select sector
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={filter.fueldisabled ? MStyles.hidestyle : MStyles.buttondimstyle}
            onClick={() => {
              openFuelDialog();
            }}
          >
            Select fuel
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openGasDialog();
            }}
          >
            Select gas
          </Button>
        </Stack>
      </Box>
      <Visualisation
        digits={digits}
        isprojections={false}
        isdate={true}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={false}
        ariatitle="Paris Agreement inventory memo items emissions"
        bar={displaybar}
        data={chartdata}
        defaulttype={charttype}
        displaylocation={true}
        displaysector={false}
        fileprefix={page.title}
        headers={headers}
        line={displayline}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title="Paris Agreement inventory memo items emissions"
        units={units}
        XAxisTitle="Year"
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={fuel}
        emissiontype={emissiontype}
      />
      <SingleSelectTree
        key={fuelkey}
        open={fueldialog}
        tree={props.fueltree}
        selected={filter.fuelselected}
        title="Select fuel"
        onCancel={() => {
          cancelselectFuel();
        }}
        onSave={(selection) => {
          saveselectFuel(selection);
        }}
      />
      <SingleSelectGasTree
        key={gaskey}
        isAR5={true}
        isProjection={false}
        open={gasdialog}
        defaultar={props.gasdefaultar}
        treear={props.gastreear}
        defaultraw={props.gasdefaultraw}
        treeraw={props.gastreeraw}
        selected={filter.gasselected}
        title="Select gas"
        onCancel={() => {
          cancelselectGas();
        }}
        onSave={(selection) => {
          saveselectGas(selection);
        }}
      />
      <SingleSelectTree
        key={locationkey}
        open={locationdialog}
        tree={props.locationtree}
        selected={filter.locationselected}
        title="Select Location"
        onCancel={() => {
          cancelselectLocation();
        }}
        onSave={(selection) => {
          saveselectLocation(selection);
        }}
      />
      <MultipleSelectTree
        key={sectorkey}
        messages={Messages.SECTOR_MEMO_INFO}
        open={sectordialog}
        tree={filter.sectortree}
        selected={filter.sectorselected}
        title="Select Sector"
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />
      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          Estimates are based on the UNFCCC classification system used to report
          Australia&apos;s greenhouse gas emission inventory. These estimates are
          compiled using the global warming potentials from the IPCC Fifth
          Assessment Report.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Emission estimates for subsectors in certain locations are not
          available due to confidentiality constraints.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Bar charts are not available for queries with different levels of the sectoral hierarchy selected. For example, if the user selects International Bunkers and Aviation sectors the data will be available as a line chart or table only, as Aviation is a subsector of International Bunkers.
        </Typography>
      </Box>
    </div>
  );
}
