// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TransformationUtility from "../classes/TransformationUtility";
import TreeUtility from "../classes/TreeUtility";

// Components
import MultipleSelectTree from "./MultipleSelectTree";
import SingleSelectTree from "./SingleSelectTree";
import Visualisation from "./Visualisation";

/**
 * Display Scope 2 data
 * @param {*} props
 * @returns
 */
export default function Scope2(props) {
  // Reducer function for a filter
  function reducer(state, filter) {
    return {
      locationselected: filter.locationselected,
      sectorselected: filter.sectorselected
    };
  }

  // Use reducer to minimize invocations of API
  // This should contain all variables that are invoked by API
  // It should be initialized with default values
  const [filter, dispatch] = React.useReducer(
    reducer,
    {
      locationselected: props.locationselected,
      sectorselected: props.sectorselected
    }
  )

  const [displaystackedbar, setDisplayStackedBar] = React.useState(true);

  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);

  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);

  // const [locationselected, setLocationSelected] = React.useState(props.locationselected);
  const [locationdialog, setLocationDialog] = React.useState(false);
  const [locationkey, setLocationKey] = React.useState("l" + Date.now());

  // const [sectorselected, setSectorSelected] = React.useState(props.sectorselected);
  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [location, setLocation] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [gas] = React.useState("Carbon Dioxide Equivalent - AR5");
  const [units, setUnits] = React.useState("");
  
  const page = Page.Scope2;
  const digits = 4;

  //#region Effects
  /**
   * Reset to default if pages changes.
   */
  React.useEffect(() => {
    if (props.page !== page.page) {
      dispatch({
        locationselected : props.locationselected,
        sectorselected: props.sectorselected
      })
    }
  }, [
    page.page,
    props.page,
    props.locationselected,
    props.sectorselected
  ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    if (props.page !== page.page) return null;

    function transformData(apid) {
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];

      TransformationUtility.prepareTimeSeries(
        props.firstyear,
        props.lastyear,
        _headers,
        _chartdata,
        _tabledata
      );
      const maxvalue = TransformationUtility.getMaxValue(
        apid,
        filter.sectorselected,
        _tabledata
      );

      const scale = TransformationUtility.getScale(maxvalue);

      const hd = TransformationUtility.populateTimeSeries(
        apid,
        filter.sectorselected,
        props.sectortree,
        _chartdata,
        _lines,
        _tabledata,
        _headers,
        digits,
        scale.factor
      );

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const _generationspread = TransformationUtility.calculateGenerationSpread(
        props.sectortree,
        filter.sectorselected
      );

      const loc = TransformationUtility.calculateTitle(
        false,
        props.locationtree,
        filter.locationselected
      );
      const sectors1 = TransformationUtility.calculateSelectionList(
        props.sectortree,
        filter.sectorselected
      );

      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setHasData(hd.hasdata);
      setPartialData(hd.partialdata);
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setDisplayStackedBar(!_generationspread);
      setLocation(loc);
      setSector(sectors1);
      setUnits(scale.units);
    }

    const base = "/api/Scope2WebTimeSeries?";
    setLoading(true);
    const sectorstring = ArrayUtility.convertArrayToStringIDs(filter.sectorselected);
    fetch(
      base +
      new URLSearchParams({
        urlencodedanzsiclist: sectorstring,
        locationid: filter.locationselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            console.log(data);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformData(d);
          }
        } else {
          console.log("Error");
        }
      })
      .catch((data) => {
        console.log("Error");
        console.log(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.firstyear,
    props.lastyear,
    props.locationtree,
    props.page,
    props.sectortree
  ]);

  //#endregion

  //#region Location

  /**
   * Cancel selection of Location
   */
  function cancelselectLocation() {
    setLocationDialog(false);
  }

  /**
   * Open location selection dialog
   */
  function openLocationDialog() {
    setLocationKey("l" + Date.now());
    setLocationDialog(true);
  }

  /**
   * Save selection of location.
   * Sector hierarchies are slightly different for different locations so need to update
   * both hierarchy and data after change of location
   */
  function saveselectLocation(value) {
    setLocationDialog(false);
    // Although it is not neccessary set sector to default to preserve user interface behaviour
    // DISER Bug 328945
    dispatch({
      locationselected: value,
      sectorselected: props.sectorselected
    })
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    const arr = [];
    if (Array.isArray(value)) {
      for (const s of value) {
        const n = TreeUtility.findinTree(props.sectortree, null, s);
        if (n) {
          arr.push(n.name);
        }
      }
    } else {
      const n = TreeUtility.findinTree(props.sectortree, null, value);
      if (n) {
        arr.push(n.name);
      }
    }

    setSectorDialog(false);
    const f = filter;
    dispatch({
      locationselected: f.locationselected,
      sectorselected: value
    })
    setSector(ArrayUtility.convertArrayToString(arr));
  }
  //#endregion

  //#region Navigation
  function handleNavigation(page) {
    props.onHandleNavigation(page);
  }

  //#endregion
  if (props.page !== page.page) return null;
  if (loading || !props.sectortree)
    return (
      <Box sx={MStyles.progressboxstyle} display={"flex"}>
        <CircularProgress></CircularProgress>
      </Box>
    );

  // Do not render if the page name is different
  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        Australia&apos;s estimates of indirect emissions from purchased electricity
        generation by economic sector. Indirect emissions from this source are known as &apos;scope 2&apos; emissions.
      </Typography>

      <Typography paragraph sx={MStyles.body}>
        A chart is presented below for emissions for all of Australia and for
        all sectors.
      </Typography>

      <Typography paragraph sx={MStyles.body}>
        Selecting a point on the chart will display the emissions for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openLocationDialog();
            }}
          >
            Select location
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
          >
            Select sector
          </Button>
        </Stack>
      </Box>

      <Visualisation
        digits={digits}
        isprojections={false}
        isdate={true}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={false}
        ariatitle={"Scope 2 emissions by economic sector"}
        bar={false}
        data={chartdata}
        defaulttype={"Line"}
        displaylocation={true}
        displaysector={false}
        fileprefix={page.title}
        headers={headers}
        line={true}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title={"Scope 2 emissions by economic sector"}
        units={units}
        XAxisTitle="Year"
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={""}
        emissiontype={""}
      />

      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          The figures above represent indirect (scope 2) emissions from the
          generation of purchased electricity. For completeness, the allocation
          of scope 2 emissions includes the electricity and gas supply sector
          (including electricity for generator's own use). Direct emissions from
          each ANZSIC sector can be viewed on the{" "}
          <Link
            title="National Inventory by Economic Sector"
            alt="Go to National Inventory by Economic Sector page"
            sx={MStyles.linkstyle}
            onClick={() => {
              handleNavigation(Page.Economic.page);
            }}
          >
            National Inventory by Economic Sector
          </Link>{" "}
          page.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Bar charts are not available for queries with different levels of the sectoral hierarchy selected. For example, if the user selects D Electricity, Gas, Water and Waste Services and 26 Electricity Supply sectors the data will be available as a line chart or table only, as 26 Electricity Supply is a subdivision of D Electricity, Gas, Water and Waste Services.
        </Typography>        
      </Box>

      <SingleSelectTree
        key={locationkey}
        open={locationdialog}
        tree={props.locationtree}
        selected={filter.locationselected}
        title="Select Location"
        onCancel={() => {
          cancelselectLocation();
        }}
        onSave={(selection) => {
          saveselectLocation(selection);
        }}
      />

      <MultipleSelectTree
        key={sectorkey}
        messages={Messages.SECTOR_SCOPE2_INFO}
        open={sectordialog}
        tree={props.sectortree}
        selected={filter.sectorselected}
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />
    </div>
  );
}
