// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ActivityTabs() {
  const [value, setValue] = React.useState(0);

  const datainventory = [
    ExternalURL.ACTIVITY_AGG_CROP,
    ExternalURL.ACTIVITY_AGG_FERT,
    ExternalURL.ACTIVITY_AGG_LIVS,
    ExternalURL.ACTIVITY_AGG_OTHER,
    ExternalURL.ACTIVITY_FUGI,
    ExternalURL.ACTIVITY_SE,
    ExternalURL.ACTIVITY_TRANS,
    ExternalURL.ACTIVITY_IP,
    ExternalURL.ACTIVITY_WAST,
    ExternalURL.ACTIVITY_LULUCF,
    ExternalURL.ACTIVITY_ANZSIC,
    ExternalURL.ACTIVITY_BC,
    ExternalURL.ACTIVITY_STATES,
    ExternalURL.STATE_EMISSION_INTENSITY,
    ExternalURL.STATE_REALCULATION,
    ExternalURL.STATE_SECTORAL_BREAKDOWN
  ];

  const dataprojections = [
    ExternalURL.ACTIVITY_PROJECTION
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabstyle = {
    color: "#005677",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "normal",
    textTransform: "capitalize",
    mr: 2,
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      border: "2px solid #9263DE",
      textDecoration: "none",
    },
  };

  const tabtheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 7,
          },
        },
      },
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "7px solid #F5F5F5",
        }}
      >
        <ThemeProvider theme={tabtheme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Tabs providing access to activity tables for inventories and for projections"
          >
            <Tab
              disableRipple
              label="Inventories"
              sx={tabstyle}
              {...a11yProps(0)}
            />
            <Tab
              disableRipple
              label="Projections"
              sx={tabstyle}
              {...a11yProps(1)}
            />
          </Tabs>
        </ThemeProvider>
      </Box>

      {/* Inventory Tab Panel */}
      <TabPanel value={value} index={0}>
        <TableContainer
          sx={{ mb: 3, border: "1px solid #999999", padding: "20px" }}
        >
          <Table>
            <caption style={{"captionSide": "top"}}><Typography variant="h2" sx={MStyles.h2}>Select an activity table from the list</Typography></caption>
            <TableHead sx={MStyles.tableheadstyle}>
              <TableRow>
                <TableCell sx={MStyles.tableheadercell}>Table details</TableCell>
                <TableCell sx={MStyles.tableheadercell}>Upload date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={MStyles.tablebodystyle}>
              {datainventory.map((row) => (
                <TableRow key={row.url}>
                  <TableCell sx={MStyles.tablecell}>
                    <Link href={encodeURI(row.url)} sx={MStyles.linkstyle}>
                      {row.text}
                    </Link>
                  </TableCell>
                  <TableCell sx={MStyles.tablecell}>{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      {/* Projections Tab Panel */}
      <TabPanel value={value} index={1}>
        <TableContainer
          sx={{ mb: 3, border: "1px solid #999999", padding: "20px" }}
        >
          <Table aria-label="simple table">
            <caption style={{"captionSide": "top"}}><Typography variant="h2" sx={MStyles.h2}>Select an activity table from the list</Typography></caption>
            <TableHead sx={MStyles.tableheadstyle}>
              <TableRow>
                <TableCell sx={MStyles.tableheadercell}>Table details</TableCell>
                <TableCell sx={MStyles.tableheadercell}>Upload date</TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={MStyles.tablebodystyle}>
              {dataprojections.map((row) => (
                <TableRow key={row.url}>
                  <TableCell sx={MStyles.tablecell}>
                    <Link href={encodeURI(row.url)} sx={MStyles.linkstyle} >
                      {row.text}
                    </Link>
                  </TableCell>
                  <TableCell sx={MStyles.tablecell}>{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Box>
  );
}
