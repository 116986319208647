// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Components
import SearchResultDetail from "./SearchResultDetail";

// Utilities
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TreeUtility from "../classes/TreeUtility";

/**
 * Search results page
 * @param {*} props
 * @returns
 */
export default function SearchResults(props) {
  const [page] = React.useState(Page.SearchResults);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isValid, setIsValid] = React.useState(false);
  const [hasTerm, setHasTerm] = React.useState(false);
  const [hasAny, setHasAny] = React.useState(false);
  const [hasNIT, setHasNIT] = React.useState(false);
  const [hasProjections, setHasProjection] = React.useState(false);

  const [anzsicRows, setANZSICRows] = React.useState([]);
  const [fuelRows, setFuelRows] = React.useState([]);
  const [gasrawRows, setGasRawRows] = React.useState([]);
  const [gasrawprojectionRows, setGasRawProjectionsRows] = React.useState([]);
  const [gasar4Rows, setGasAR4Rows] = React.useState([]);
  const [gasar5Rows, setGasAR5Rows] = React.useState([]);
  const [gasar5projectionRows, setGasAR5ProjectionsRows] = React.useState([]);
  const [sectorBaselineRows, setSectorBaselineRows] = React.useState([]);
  const [sectorKPRows, setSectorKPRows] = React.useState([]);
  const [sectorMemoRows, setSectorMemoRows] = React.useState([]);
  const [sectorParisRows, setsectorParisRows] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(props.searchTerm);

  /**
   * Reset page after navigation change
   */
  React.useEffect(() => {
    if (page.page !== props.page) {
      setANZSICRows([]);
      setFuelRows([]);
      setGasRawRows([]);
      setGasRawProjectionsRows([]);
      setGasAR4Rows([]);
      setGasAR5Rows([]);
      setGasAR5ProjectionsRows([]);
      setSectorBaselineRows([]);
      setSectorKPRows([]);
      setSectorMemoRows([]);
      setsectorParisRows([]);
      setSearchTerm("");
      setIsEmpty(true);
      setIsValid(false);
      setHasTerm(false);
    }
  }, [page.page, props.page]);

  /**
   * On entry from header search set search term
   * If we are in searchResult page ignore changes to the header
   */
  React.useEffect(() => {
    if (props.searchTerm) {
      setSearchTerm(props.searchTerm);
      setIsEmpty(0 === props.searchTerm.length);
      const v = verify(props.searchTerm);
      setIsValid(v);
      if (v) {
        const a = TreeUtility.searchTree(props.anzsic, props.searchTerm);
        const f = TreeUtility.searchTree(props.fuel, props.searchTerm);
        const gr = TreeUtility.searchTree(props.gasraw, props.searchTerm);
        const grp = TreeUtility.searchTree(
          props.gasrawprojections,
          props.searchTerm
        );
        const gar4 = TreeUtility.searchTree(props.gasar4, props.searchTerm);
        const gar5 = TreeUtility.searchTree(props.gasar5, props.searchTerm);
        const gar5p = TreeUtility.searchTree(
          props.gasar5projections,
          props.searchTerm
        );
        const b = TreeUtility.searchTree(
          props.sectorBaseline,
          props.searchTerm
        );
        const kp = TreeUtility.searchTree(props.sectorKP, props.searchTerm);
        const memo = TreeUtility.searchTree(props.sectorMemo, props.searchTerm);
        const paris = TreeUtility.searchTree(
          props.sectorParis,
          props.searchTerm
        );

        setSectorBaselineRows(b);
        setANZSICRows(a);
        setFuelRows(f);
        setGasRawRows(gr);
        setGasRawProjectionsRows(grp);
        setGasAR4Rows(gar4);
        setGasAR5Rows(gar5);
        setGasAR5ProjectionsRows(gar5p);
        setSectorKPRows(kp);
        setSectorMemoRows(memo);
        setsectorParisRows(paris);
        setHasNIT(
          0 < a.length ||
          0 < f.length ||
          0 < gr.length ||
          0 < gar4.length ||
          0 < gar5.length ||
          0 < kp.length ||
          0 < memo.length ||
          0 < paris.length
        );
        setHasProjection(0 < b.length || 0 < grp.length || 0 < gar5p.length);
        setHasAny(
          0 < a.length ||
          0 < f.length ||
          0 < gr.length ||
          0 < gar4.length ||
          0 < gar5.length ||
          0 < kp.length ||
          0 < memo.length ||
          0 < paris.length ||
          0 < b.length ||
          0 < grp.length ||
          0 < gar5p.length
        );

        setHasTerm(true);
      }
      props.onChangeTerm();
    }

    if (undefined === props.searchTerm) {
      setANZSICRows([]);
      setFuelRows([]);
      setGasRawRows([]);
      setGasRawProjectionsRows([]);
      setGasAR4Rows([]);
      setGasAR5Rows([]);
      setGasAR5ProjectionsRows([]);
      setSectorBaselineRows([]);
      setSectorKPRows([]);
      setSectorMemoRows([]);
      setsectorParisRows([]);
      setSearchTerm("");
      setIsEmpty(true);
      setIsValid(false);
      setHasTerm(false);
    }
  }, [
    props,
    props.anzsic,
    props.fuel,
    props.gasar4,
    props.gasar5,
    props.gasar5projections,
    props.gasraw,
    props.gasrawprojections,
    props.page,
    props.onChangeTerm,
    props.searchTerm,
    props.sectorBaseline,
    props.sectorKP,
    props.sectorMemo,
    props.sectorParis
  ]);

  const handleChange = (event) => {
    setHasTerm(false);
    setIsEmpty(!event.target.value || (0 === event.target.value.length));
    setIsValid(verify(event.target.value));
    setSearchTerm(event.target.value);
  };

  const verify = (t) => {
    if (!t) return false;
    if (t.length < 4) return false;
    if (32 < t.length) return false;
    const re = new RegExp("^[\\s]+$");
    if (t.match(re)) return false;
    const r = new RegExp("^[a-zA-Z0-9 ]+$");
    if (t.match(r)) return true;
    return false;
  };

  /* Trees that are provided for search through props
    anzsic
    fuel
    gasraw
    gasrawprojections
    gasar4
    gasar5
    gasar5projections
    sectorBaseline
    sectorKP
    sectorMemo
    sectorParis
  */

  const searchClicked = () => {
    // Requested teleconference 28/06/2022
    if (!verify(searchTerm)) return;

    const a = TreeUtility.searchTree(props.anzsic, searchTerm);
    const f = TreeUtility.searchTree(props.fuel, searchTerm);
    const gr = TreeUtility.searchTree(props.gasraw, searchTerm);
    const grp = TreeUtility.searchTree(props.gasrawprojections, searchTerm);
    const gar4 = TreeUtility.searchTree(props.gasar4, searchTerm);
    const gar5 = TreeUtility.searchTree(props.gasar5, searchTerm);
    const gar5p = TreeUtility.searchTree(props.gasar5projections, searchTerm);
    const b = TreeUtility.searchTree(props.sectorBaseline, searchTerm);
    const kp = TreeUtility.searchTree(props.sectorKP, searchTerm);
    const memo = TreeUtility.searchTree(props.sectorMemo, searchTerm);
    const paris = TreeUtility.searchTree(props.sectorParis, searchTerm);

    setSectorBaselineRows(b);
    setANZSICRows(a);
    setFuelRows(f);
    setGasRawRows(gr);
    setGasRawProjectionsRows(grp);
    setGasAR4Rows(gar4);
    setGasAR5Rows(gar5);
    setGasAR5ProjectionsRows(gar5p);
    setSectorKPRows(kp);
    setSectorMemoRows(memo);
    setsectorParisRows(paris);
    setHasNIT(
      0 < a.length ||
      0 < f.length ||
      0 < gr.length ||
      0 < gar4.length ||
      0 < gar5.length ||
      0 < kp.length ||
      0 < memo.length ||
      0 < paris.length
    );
    setHasProjection(0 < b.length || 0 < grp.length || 0 < gar5p.length);
    setHasAny(
      0 < a.length ||
      0 < f.length ||
      0 < gr.length ||
      0 < gar4.length ||
      0 < gar5.length ||
      0 < kp.length ||
      0 < memo.length ||
      0 < paris.length ||
      0 < b.length ||
      0 < grp.length ||
      0 < gar5p.length
    );
    setHasTerm(true);
  };

  const veANZSIC = (id) => {
    props.onHandleSearch({ type: "ANZSIC", id: id });
  };
  const veFuel = (id) => {
    props.onHandleSearch({ type: "Fuel", id: id });
  };
  const veGasRaw = (id) => {
    props.onHandleSearch({ type: "Raw", id: id });
  };
  const veGasRawProjection = (id) => {
    props.onHandleSearch({ type: "RawP", id: id });
  };
  const veGasAR4 = (id) => {
    props.onHandleSearch({ type: "AR4", id: id });
  };
  const veGasAR5 = (id) => {
    props.onHandleSearch({ type: "AR5", id: id });
  };
  const veGasAR5Projection = (id) => {
    props.onHandleSearch({ type: "AR5P", id: id });
  };
  const veBaseline = (id) => {
    props.onHandleSearch({ type: "Baseline", id: id });
  };
  const veKP = (id) => {
    props.onHandleSearch({ type: "KP", id: id });
  };
  const veMemo = (id) => {
    props.onHandleSearch({ type: "Memo", id: id });
  };
  const veParis = (id) => {
    props.onHandleSearch({ type: "Paris", id: id });
  };

  // Do not render if the page name is different
  if (props.page !== page.page) return null;

  return (
    <div style={{ marginBottom: "100px" }}>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        How search works
      </Typography>
      <Typography sx={MStyles.body}>
        This search finds emission data related to the terms that you enter. For
        example, entering &apos;electricity&apos;, will search for emissions
        associated with electricity in the inventories and projections data.
      </Typography>
      <Typography sx={MStyles.body}>
        Search terms must be between 4 and 32 characters long. If a gas abbreviation is fewer than 4 characters long, enter its full name.
        For example, enter &apos;carbon dioxide&apos; not &apos;CO2&apos;.
      </Typography>
      <Typography sx={MStyles.body}>
        Search results for confidential data will be disabled.
      </Typography>
      <Typography sx={MStyles.body}>
        This search function cannot be used to find information about this website. 
        Please refer to the &apos;Help and support&apos; page for frequently asked questions, the glossary and background information.
      </Typography>
      <Typography variant="h3" sx={MStyles.h3}>
      Enter a search term in the search box. 
      </Typography>
      <Typography sx={MStyles.body}>
        The term must be between 4 and 32 characters long and contain only letters, digits and spaces.
      </Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        role="search"
        spacing={{ xs: 1 }}
        sx={{
          maxWidth: 1000,
          border: { sx: 0, sm: 1 },
          paddingtop: 0,
          marginBottom: 2,
          borderRadius: 0,
        }}
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        tabIndex={-1}
      >
        <InputBase
          id="outlined-search"
          label="Enter a search term"
          placeholder="Enter a search term"
          type="search"
          size="small"
          variant="standard"
          fullWidth={true}
          inputProps={{
            minLength: 4,
            maxLength: 32,
          }}
          value={searchTerm}
          tabIndex={0}
          sx={{
            paddingLeft: 1,
            border: { xs: 1, sm: 0 },
            "&:focus": {
              outline: "2px solid #9263DE",
              textDecoration: "underline",
            },
          }}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          disableElevation
          disableRipple
          disableTouchRipple
          sx={MStyles.buttonprops}
          onClick={searchClicked}
        >
          Search
        </Button>
      </Stack>

      <Typography
        variant="p"
        sx={MStyles.body}
        display={!isValid && !isEmpty ? "box" : "none"}
      >
        {Messages.SEARCH_TERM_NOT_VALID}
      </Typography>
      <Typography
        variant="p"
        sx={MStyles.body}
        display={!isValid && isEmpty ? "box" : "none"}
      >
        {Messages.SEARCH_TERM_EMPTY}
      </Typography>

      <Typography
        variant="h2"
        sx={MStyles.h2_search}
        display={isValid && hasTerm ? "box" : "none"}
      >
        Search Results for <em>{searchTerm}</em>
      </Typography>

      <Typography
        variant="h3"
        sx={MStyles.h3}
        display={isValid && hasTerm && hasNIT ? "box" : "none"}
      >
        National Inventory (
        {sectorParisRows.length +
          sectorMemoRows.length +
          fuelRows.length +
          anzsicRows.length +
          gasrawRows.length +
          gasar4Rows.length +
          gasar5Rows.length +
          sectorKPRows.length}
        )
      </Typography>
      <Box
        display={isValid && hasTerm && hasNIT ? "box" : "none"}
        sx={{ mb: 5 }}
      >
        <SearchResultDetail
          name={"Paris Agreement inventory sectors"}
          columnname="Sector"
          rows={sectorParisRows}
          onSelected={veParis}
        />
        <SearchResultDetail
          name={"Paris Agreement inventory Memo items"}
          columnname="Sector"
          rows={sectorMemoRows}
          onSelected={veMemo}
        />
        <SearchResultDetail
          name={"National inventory by economic sector"}
          columnname="Economic Sector"
          rows={anzsicRows}
          onSelected={veANZSIC}
        />
        <SearchResultDetail
          name={"Kyoto protocol inventory sector"}
          columnname="Sector"
          rows={sectorKPRows}
          onSelected={veKP}
        />
        <SearchResultDetail
          name={"Raw Gas"}
          columnname="Gas"
          rows={gasrawRows}
          onSelected={veGasRaw}
        />
        <SearchResultDetail
          name={"Gas AR4"}
          columnname="Gas"
          rows={gasar4Rows}
          onSelected={veGasAR4}
        />
        <SearchResultDetail
          name={"Gas AR5"}
          columnname="Gas"
          rows={gasar5Rows}
          onSelected={veGasAR5}
        />
        <SearchResultDetail
          name={"Fuel"}
          columnname="Fuel"
          rows={fuelRows}
          onSelected={veFuel}
        />
      </Box>

      <Typography
        variant="p"
        sx={MStyles.body}
        display={isValid && hasTerm && !hasNIT && hasAny ? "box" : "none"}
      >
        {Messages.SEARCH_TERM_NO_RESULTS_NIR}
      </Typography>

      <Typography
        variant="h3"
        sx={MStyles.h3}
        display={isValid && hasTerm && hasProjections ? "box" : "none"}
      >
        Projections(
        {gasrawprojectionRows.length +
          gasar5projectionRows.length +
          sectorBaselineRows.length}
        )
      </Typography>
      <Box display={isValid && hasTerm && hasProjections ? "box" : "none"}>
        <SearchResultDetail
          name={"Paris Agreement inventory sectors"}
          columnname="Sector"
          rows={sectorBaselineRows}
          onSelected={veBaseline}
        />
        <SearchResultDetail
          name={"Raw Gas"}
          columnname="Gas"
          rows={gasrawprojectionRows}
          onSelected={veGasRawProjection}
        />
        <SearchResultDetail
          name={"Gas AR5"}
          columnname="Gas"
          rows={gasar5projectionRows}
          onSelected={veGasAR5Projection}
        />
      </Box>
      <Typography
        variant="h3"
        sx={MStyles.h3}
        display={isValid && hasTerm && !hasProjections && hasAny ? "box" : "none"}
      >
        Projections
      </Typography>
      <Typography
        variant="p"
        sx={MStyles.body}
        display={
          isValid && hasTerm && !hasProjections && hasAny ? "box" : "none"
        }
      >
        {Messages.SEARCH_TERM_NO_RESULTS_PROJECTIONS}
      </Typography>
      <Typography
        variant="p"
        sx={MStyles.body}
        display={isValid && hasTerm && !hasAny ? "box" : "none"}
      >
        {Messages.SEARCH_TERM_NO_RESULTS}
      </Typography>
    </div>
  );
}
