// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import ExternalURL from "../classes/ExternalURL";
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TransformationUtility from "../classes/TransformationUtility"
import TreeUtility from "../classes/TreeUtility";

// Components
import MultipleSelectTree from "./MultipleSelectTree";
import SingleSelectGasTree from "./SingleSelectGasTree";
import Visualisation from "./Visualisation";

/**
 * Hightech scenario
 * @param {*} props
 * @returns
 */
export default function Hightech(props) {
  // Reducer function for a filter
  function reducer(state, filter) {
    return {
      gasselected: filter.gasselected,
      sectorselected: filter.sectorselected
    };
  }

  // Use reducer to minimize invocations of API
  // This should contain all variables that are invoked by API
  // It should be initialized with default values
  const [filter, dispatch] = React.useReducer(
    reducer,
    {
      gasselected: props.gasdefaultar,
      sectorselected: props.sectorselected
    }
  )
  const [displaybar, setDisplayBar] = React.useState(false);
  const [displaystackedbar, setDisplayStackedBar] = React.useState(true);
  const [gasdialog, setGasDialog] = React.useState(false);
  const [gaskey, setGasKey] = React.useState("g" + Date.now());

  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);

  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [page] = React.useState(Page.Hightech);
  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);
  const [location] = React.useState("Australia");
  const [sector, setSector] = React.useState("");
  const [gas, setGas] = React.useState("");
  const [units, setUnits] = React.useState("");  
  const digits = 2;

    /**
   * Reset to default if pages changes.
   */
    React.useEffect(() => {
      // Entry from search conditions
      if (props.page === Page.SearchResults.page) {
          dispatch({
            gasselected: props.gasdefaultar,
            sectorselected: props.sectorselected
          });
      } else if (props.page !== page.page) {
        dispatch({
          gasselected: props.gasdefaultar,
          sectorselected: props.sectorselected
        });
      }
    }, [
      page.page,
      props.gasdefaultar,
      props.sectorselected,
      props.sectortree,
      props.page,
    ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    if (props.page !== page.page) return;
    /**
     * Transform data into components used by visualization.
     * This follows successful data load so it is included in this effect.
     * @param {*} apid
     */
    function transformdata(apid) {
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];

      // Populate data for XAxis key and name are reserved names
      // Populae data for year table column
      TransformationUtility.prepareTimeSeries(
        props.firstyear,
        props.lastyear,
        _headers,
        _chartdata,
        _tabledata
      );

      const _generationspread = TransformationUtility.calculateGenerationSpread(
        props.sectortree,
        filter.sectorselected
      );

      const maxvalue = TransformationUtility.getMaxValue(
        apid,
        filter.sectorselected,
        _tabledata
      );

      const scale = TransformationUtility.getScale(maxvalue);

      const hd = TransformationUtility.populateTimeSeries(
        apid,
        filter.sectorselected,
        props.sectortree,
        _chartdata,
        _lines,
        _tabledata,
        _headers,
        digits,
        scale.factor
      );

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const gas = TransformationUtility.calculateGasDisplayName(
        props.gastreeraw,
        props.gastreear,
        filter.gasselected
      );

      const sectors1 = TransformationUtility.calculateSelectionList(
        props.sectortree,
        filter.sectorselected
      );


      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setLines(_lines);
      setChartData(_chartdata);
      setHasData(hd.hasdata);
      setPartialData(hd.partialdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setDisplayBar(!_generationspread);
      setDisplayStackedBar(!_generationspread);
      setSector(sectors1);
      setGas(gas);
      setUnits(scale.units);
    }

    // Guard if argument have been set
    if (!filter.sectorselected || !filter.gasselected) {
      setLines(null);
      setChartData(null);
      setHeaders(null);
      setTableData(null);
      return null;
    }

    setLoading(true);
    let base = "/api/HightechTimeSeries?";
    const sectorstring = ArrayUtility.convertArrayToStringIDs(filter.sectorselected);
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        urlencodedsectorlist: sectorstring,
        gasid: filter.gasselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            setLines(null);
            setChartData(null);
            setHeaders(null);
            setTableData(null);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformdata(d);
          }
        } else {
          setLines(null);
          setChartData(null);
          setHeaders(null);
          setTableData(null);
        }
      })
      .catch((data) => {
        console.log("Error");
        setLines(null);
        setChartData(null);
        setHeaders(null);
        setTableData(null);
        console.log(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.firstyear,
    props.gastreear,
    props.gastreeraw,
    props.lastyear,
    props.page,
    props.sectortree,
  ]);

  //#region Gas

  /**
   * Cancel selection of Gas
   */
  function cancelselectGas() {
    setGasDialog(false);
  }

  /**
   * Open Gas dialog
   */
  function openGasDialog() {
    setGasKey("g" + Date.now());
    setGasDialog(true);
  }

  /**
   * Save selection of Gas
   */
  function saveselectGas(value) {
    setGasDialog(false);
    const f = filter;
    dispatch({
      gasselected: value,
      sectorselected: f.sectorselected
    })
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    const arr = [];
    if (Array.isArray(value)) {
      for (const s of value) {
        const n = TreeUtility.findinTree(props.sectortree, null, s);
        if (n) {
          arr.push(n.name);
        }
      }
    } else {
      const n = TreeUtility.findinTree(props.sectortree, null, value);
      if (n) {
        arr.push(n.name);
      }
    }

    setSectorDialog(false);
    setSector(ArrayUtility.convertArrayToString(arr));
    const f = filter;
    dispatch({
      gasselected: f.gasselected,
      sectorselected: value
    });
  }

  //#endregion

  // Do not render if the page name is different
  if (props.page !== page.page) return null;
  if (loading)
    return (
      <Box sx={MStyles.progressboxstyle} display={loading}>
        <CircularProgress></CircularProgress>
      </Box>
    );
  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        The 'with additional measures' scenario is an estimate of Australia's projected future emissions under policies and measures in place at the time of publication, as well as some policies and measures under development at the time of publication.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        This page provides data from the current emissions projections 'with additional measures' scenario. Visit {" "}<Link href={ExternalURL.PROJECTION.url} sx={MStyles.linkstyle} alt={ExternalURL.PROJECTION.alt}>
          {ExternalURL.PROJECTION.text}
        </Link>{" "}
        for more information.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        The chart shows Australia's total emissions projections. Selecting a point in the chart will show the emissions projections for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
          >
            Select sector
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openGasDialog();
            }}
          >
            Select gas
          </Button>
        </Stack>
      </Box>
      <Visualisation
        digits={digits}
        isprojections={true}
        isdate={true}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={false}
        ariatitle={"'With additional measures' emissions projections."}
        bar={displaybar}
        data={chartdata}
        defaulttype={"Line"}
        displaylocation={true}
        displaysector={false}
        fileprefix={page.title}
        headers={headers}
        line={true}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title={"'With additional measures' emissions projections."}
        units={units}
        XAxisTitle="Year"
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={""}
        emissiontype={""}
      />

      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          Estimates are based on the IPCC classification system. These estimates
          are compiled using the global warming potentials from the IPCC 5th
          Assessment Report.
        </Typography>

        <Typography paragraph sx={MStyles.body}>
        <span style={{fontStyle: "italic" }}>Public electricity and heat production</span> is presented as the{" "}
        <span style={{fontStyle: "italic" }}>electricity</span> sector and{" "}
        <span style={{fontStyle: "italic" }}>fugitive emissions from fuels</span> is presented as the{" "}
        <span style={{fontStyle: "italic" }}>fugitives</span> sector in Australia's emissions projections report. The{" "}
        <span style={{fontStyle: "italic" }}>stationary energy (excluding electricity)</span> sector includes total{" "}
        <span style={{fontStyle: "italic" }}>energy</span> emissions minus the emissions from{" "}
        <span style={{fontStyle: "italic" }}>public electricity and heat production, transport and fugitive emissions</span> from fuels.{" "}
        <span style={{fontStyle: "italic" }}>Stationary energy</span> emissions projections are available in the{" "}
          <Link
            href={ExternalURL.PROJECTIONCHARTDATA.url}
            sx={MStyles.linkstyle}
            title={ExternalURL.PROJECTIONCHARTDATA.title}
            alt={ExternalURL.PROJECTIONCHARTDATA.alt}
          >
            {ExternalURL.PROJECTIONCHARTDATA.text}
          </Link>{" "}workbook published with Australia's emissions projections report.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
        Synthetic greenhouse gases are available as CO<sub>2</sub>-e only.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Bar charts are not available for queries with different levels of the sectoral hierarchy selected. 
          For example, if the user selects 1 Energy and 1.A Fuel Combustion sectors the data will be available 
          as a line chart or table only, as 1.A Fuel Combustion is a subsector of 1 Energy.
        </Typography>
      </Box>

      <SingleSelectGasTree
        key={gaskey}
        isAR5={true}
        isProjection={true}
        open={gasdialog}
        defaultar={props.gasdefaultar}
        treear={props.gastreear}
        defaultraw={props.gasdefaultraw}
        selected={filter.gasselected}
        treeraw={props.gastreeraw}
        title="Select gas"
        onCancel={() => {
          cancelselectGas();
        }}
        onSave={(selection) => {
          saveselectGas(selection);
        }}
      />

      <MultipleSelectTree
        key={sectorkey}
        messages={Messages.SECTOR_BASELINE_INFO}
        open={sectordialog}
        tree={props.sectortree}
        selected={filter.sectorselected}
        title="Select Sector"
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />
    </div>
  );
}
