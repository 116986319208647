// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

// Classes 
import MStyles from "../classes/MStyles";

// Components
import CustomAccordion from "./CustomAccordion";

/**
 * Display panels for FAQ
 * @param {*} props 
 * @returns 
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FAQTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const generaldata = [
    {
      q: "How do I use the website?",
      a: [
        "This website presents data on Australia's greenhouse gas inventories and projections. On the pages for the inventories (except the National Greenhouse Inventory Quarterly Update) and for the projections scenarios, a chart is presented for the 'top level' data, for example, emissions for Australia and for all economic sectors.  The data can be filtered using the buttons on the page, for example, to view data for particular sectors or for a specific gas.  For each filter (sector, gas, location), select the category or categories required and then confirm.  The chart will update.",
        "It is also possible to toggle between a chart view of the data and a table view using the Graph/Table buttons above the chart or table. The data can be downloaded in chart or spreadsheet form using the Download data drop down button above the chart or table."
      ],
    },
    {
      q: "Why is some data 'unavailable'?",
      a: [
        "Data to compile Australia's National Greenhouse Accounts is obtained from a range of sources. Sometimes a particular listed emissions source is not occurring or is accounted for elsewhere in the inventory and  so is unavailable against that particular category.",
      ],
    },
    {
      q: "Why is some data 'confidential'?",
      a: [
        "Some of the data provided for the compilation of Australia's National Greenhouse Accounts is commercial in confidence.  When this happens, the data is aggregated either into its parent or aggregated in alternative categories. Lower-level data is not shown where confidential data could be identified.",
      ],
    },
    {
      q: "Why are bar charts not available for some queries?",
      a: ["Bar charts are not available for queries where the user has selected sectors across different levels of the sectoral hierarchy. For example, if the user selects 1 Energy and 1.A Fuel Combustion only a line chart will be available as 1.A Fuel Combustion is a child category of 1 Energy. However, if the user selected 1 Energy and 2 Industrial Processes, which are on the same level in the hierarchy, then the user would have the option to visualise the data as a bar chart or line chart."]
    },
    {
      q: "Is more granular data available?",
      a: [
        "The alternative breakdown of data available in the National Inventory by Economic Sector inventory uses Australia and New Zealand Standard Industry Classifications (ANZSIC) categories rather than those of the United Nations Framework Convention on Climate Change (UNFCCC). It may provide additional emissions information within the current confidentiality constraints.",
      ],
    },
    {
      q: "What is the difference between the inventory and the projections?",
      a: [
        "Quarterly updates of Australia's National Greenhous Gas Inventory are the most up to date source of information on Australia's national emissions. They provide a summary of Australia's national emissions, updated on a quarterly basis. In contrast, the emissions projections are estimates of future greenhouse emissions to 2035, analysing the factors driving emissions in each sector and estimating the effort needed to meet Australia's emissions reduction targets. ",
      ],
    },
    {
      q: "What sorts of things are covered in each sector?",
      a: [
        "The content of each sector can be found in the glossary, under the 'Sectors' heading.",
      ],
    },
  ];
  const inventorydata = [
    {
      q: "Why is there a difference in estimates between UNFCCC (AR4) and Kyoto Protocol estimates for the land sector?",
      a: [
        "The National Greenhouse Accounts are compiled consistent with the Paris Agreement, UNFCCC and Kyoto Protocol accounting rules.",
        "The principal source of difference between the UNFCCC and KP accounting frameworks is the treatment of emissions sources and sinks from the land use, land use change and forestry sector.",
        "UNFCCC accounting rules are underpinned by a comprehensive approach to emissions accounting and require the inclusion of all sources and sinks where there is adequate data while Kyoto rules require a more limited set of sources and sinks from land use change and forestry activities (i.e. afforestation, reforestation and deforestation).",
        "The Paris Agreement framework will use the same comprehensive approach as the UNFCCC."
      ],
    },
    {
      q: "What is the difference between IPCC/UNFCCC and ANZSIC sectors?",
      a: [
        "The IPCC published sectors as part of its 2006 IPCC Guidelines for National Greenhouse Gas Inventories (2006 Guidelines). The use of these sectors are a requirement of our reporting under the Paris Agreement, UNFCCC and the Kyoto Protocol.",
        "Australian and New Zealand Standard Industrial Classification (ANZSIC) is derived from international classifications (ISIC, International Standard Industrial Classifications) and provides a framework for organising data about economic activity.",
        "We provide the emissions estimates disaggregated by ANZSIC to enable comparison and analysis against other economic datasets."
      ],
    },
    {
      q: "Why do inventories have different GWPs?",
      a: [
        "Global warming potentials (or GWPs) represent the relative warming effect of a unit mass of a greenhouse gas compared with the same mass of CO2 over a specific period. GWPs are updated by the IPCC as part of its Assessment Report, approximately every seven years. Updated GWPs are then considered by parties to international climate agreements for use in emissions reporting.",
        "The GWPs used in the Paris Agreement inventory are from the IPCC Fifth Assessment Report, consistent with rules agreed under the Paris Agreement. The GWPs for inventories reported under the UNFCCC and Kyoto Protocol are from the IPCC Fourth Assessment Report."
      ]
    },    
    {
      q: "What is the difference between Scope 1 and Scope 2 emissions?",
      a: [
        "Scope 1 greenhouse gas emissions are the emissions released to the atmosphere as a direct result of an activity.",
        "Scope 2 emissions are indirect greenhouse gas emissions from the generation of purchased electricity. Purchased electricity is defined as electricity that is purchased or otherwise brought into the organisational boundary of the entity. The sum of scope 2 emissions is equal to the direct (scope 1) emissions from electricity generation."
      ]
    },
    {
      q: "How can I get more data?",
      a: [
        "Additional data is available for use in spreadsheets via our OData feed.  In Excel or PowerBI, go to Data or Select Data -> Get data -> From other sources -> From OData feed and enter https://greenhouseaccounts.climatechange.gov.au/OData.  Further information can be found in this website by navigating to Datasets and API -> API.  Please note that some subsector information is not available due to confidentiality constraints."
      ]
    }
  ];
  const projectionsdata = [
    {
      q: "What is the difference between the baseline scenario and the 'with additional measures' scenario ?",
      a: ["The baseline scenario is an estimate of Australia's future emissions under the policies and measures in place at the time of publication. The 'with additional measures' scenario is an estimate of Australia's future emissions under policies and measures in place at the time of publication, as well as some policies and measures under development at the time of publication."],
    }
  ];

  return (
    <Box sx={{ width: "100%", mb: 3 }}>
      <Box
        sx={{
          borderBottom: "7px solid #F5F5F5",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="faqs tabs">
          <Tab disableRipple label="General" sx={MStyles.tabstyle} {...a11yProps(0)} />
          <Tab
            disableRipple
            label="Inventories"
            sx={MStyles.tabstyle}
            {...a11yProps(1)}
          />
          <Tab
            disableRipple
            label="Projections"
            sx={MStyles.tabstyle}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      {/* General Tab Panel */}
      <TabPanel value={value} index={0}>
        <Typography variant="h2" sx={MStyles.h2}>
          General questions
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Expand a question to view its answer
        </Typography>
        <CustomAccordion data={generaldata} variant="h3" />
      </TabPanel>

      {/* Inventory Tab Panel */}
      <TabPanel value={value} index={1}>
        <Typography variant="h2" sx={MStyles.h2}>
          Inventory questions
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Expand a question to view its answer
        </Typography>
        <CustomAccordion data={inventorydata} variant="h3" />
      </TabPanel>

      {/* Projections Tab Panel */}
      <TabPanel value={value} index={2}>
        <Typography variant="h2" sx={MStyles.h2}>
          Projections questions
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Expand a question to view its answer
        </Typography>
        <CustomAccordion data={projectionsdata} variant="h3" />
      </TabPanel>
    </Box>
  );
}
