// React
import * as React from "react";

// MUI
import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import ExternalURL from "../classes/ExternalURL";

export default class About extends React.Component {
  page = Page.About;

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          Australia's National Greenhouse Accounts is the authoritative source for data about greenhouse gas emissions inventories and projections for Australia.  The data is compiled by the Department of Climate Change, Energy, the Environment and Water (DCCEEW) and is presented in the following reports:
        </Typography>
        <Box sx={MStyles.unorderedlist}>
          <ul>
            <li style={MStyles.unorderedlistelement}>National Inventory Report</li>
            <li style={MStyles.unorderedlistelement}>National Greenhouse Gas Inventory: Quarterly Updates</li>
            <li style={MStyles.unorderedlistelement}>State and Territory Greenhouse Gas Inventories</li>
            <li style={MStyles.unorderedlistelement}>National Inventory by Economic Sector.</li>
          </ul>
        </Box>

        <Typography paragraph sx={MStyles.body}>
        The accounts fulfil our international reporting obligations and inform governments, business and the public of past greenhouse gas emissions and progress towards our emissions reduction targets.
         </Typography>

        <Typography paragraph sx={MStyles.body}>
        The data published on this website facilitates the filtering and analysis of data for the benefit of its users.
        </Typography>

        <Typography variant="h2" sx={MStyles.h2}>
        Emissions Reporting
        </Typography>

        <Typography paragraph sx={MStyles.body}>
        For more information on the reporting of Australia’s greenhouse gas emissions inventories and projections, see the {" "}
          <Link
            href={ExternalURL.EMISSION_REPORTING.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            alt={ExternalURL.EMISSION_REPORTING.alt}
          >
            {ExternalURL.EMISSION_REPORTING.title}
          </Link>{" "}.
        </Typography>

        <Typography paragraph sx={MStyles.version}>
          Version: {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
    );
  }
}
