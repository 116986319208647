// React
import * as React from "react";

// MUI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";

import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Classes
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import TreeUtility from "../classes/TreeUtility";

/**
 * Multiple select dialog for tree data. Used for displaying hierarchies.
 * Properties
 * open : true or false to show this dialog
 * tree : data structure to be supplied to the TreeView component
 * selected : key of the selected node
 * title : Title to display
 * onCancel : Handle to cancel selection
 * onSave : Handle to pass new selection
 * 
 * Comments: 
 * This component follows design of the modal dialog as specified in 
 *  "AGEIS website visualisation -Business rules_17062022_v0.54.docx" Section 1.3.12
 *  "AGEIS enhanced website style guide_V1.8_07_07_2022.docx" Section 7.15
 * 
 * This design breaks ARIA accessible design of MUI TreeView component.
 * The Department has been notified (teleconferences with Beta, Aarthy and Bree)
 * and email to Tamara on release 25 July 2022 and it accepts this design
 * in order to use mouse click without additional keys (Nicola's requirement).
 */
export default function MultipleSelectTree(props) {
  const [selected, setSelected] = React.useState(
    Array.isArray(props.selected) ? props.selected : [props.selected]
  );
  const [expanded, setExpanded] = React.useState(
    Array.isArray(props.selected) ? props.selected : [props.selected]
  );
  const [error, setError] = React.useState("");
  const [notvalid, setNotValid] = React.useState(false);
  const timer = React.useRef();

  /**
   * Find all ascendants of selected nodes and expand them
   */
  React.useEffect(() => {
    const t = [];
    const m = TreeUtility.findAscendantsTree(props.tree, props.selected);
    for (const s of m) { t.push(s); }
    if(0 === m.size)
    {
      setExpanded([props.selected]);
    }
    else {
      setExpanded(t);
    }
  }, [props.tree, props.selected]);

  /**
  * Aria requirement to close dialog on escape
  * @param {*} e 
  */
  function ariaescape(e) {
    if (27 === e.keyCode) {
      cancelselect();
    }
  }

  /**
   * Cancel display without updating selection
   */
  function cancelselect() {
    props.onCancel();
  }

  /**
   * Clear selection see Style Guid section 6.48
   */
  function clear() {
    setSelected([]);
    setExpanded([]);
    setError(Messages.SECTORCOUNTERROR1);
    setNotValid(true);
  }

  function confirm() {
    // Make sure that there are no duplicates
    const t = [];
    for (const s of selected) {
      if (-1 === t.indexOf(s)) {
        t.push(s);
      }
    }
    props.onSave(t);
  }

  /**
   * We are manipulating expansions on icons.
   * Se make sure that this function does nothing
   */
  function handleExpandCollapse(event, value) {
    const a = [];
    let isExpand = false;
    for (const e of expanded) {
      a.push(e);
    }

    if (Array.isArray(value)) {
      for (const n of value) {
        const i = a.indexOf(n);
        if (-1 < i) {
          a.splice(i, 1);
        }
        else {
          a.push(n);
          isExpand = true;
        }
      }
    }
    else {
      const i = a.indexOf(value);
      if (-1 < i) {
        a.splice(i, 1);
      }
      else {
        a.push(value);
        isExpand = true;
      }
    }
    
    const t = [];
    const m = (isExpand) ?
      TreeUtility.findAscendantsAndItselfTree(props.tree, a) :
      TreeUtility.findAscendantsTree(props.tree, a);
    
    // Special condition. Nothing is selected
    if (0 < m.size) {
      for (const s of m) {
        t.push(s);
      }
      setExpanded(t);
    }
    else {
      setExpanded(a);
    }
  }

  /**
   * Single click for a label
   * @param {*} id
   */
  function singleClick(value) {
    const t = [];
    for (const s of selected) {
      t.push(s);
    }
    if (Array.isArray(value)) {
      for (const n of value) {
        const i = t.indexOf(n);
        // It is currently selected item
        if (-1 < i) {
          t.splice(i, 1);
        }
        // It is not currently selected
        else {
          if (16 < t.length) return;
          t.push(n);
        }
      }
    }
    else {
      const i = t.indexOf(value);
      // It is currently selected item
      if (-1 < i) {
        t.splice(i, 1);
      }
      // It is not currently selected
      else {
        if (16 < t.length) return;
        t.push(value);
      }
    }

    const nodes = [];

    // Remove confidential from selection
    for (const id of t) {
      const n = TreeUtility.findinTree(props.tree, null, id);
      if (!n.confidential) {
        nodes.push(n);
      }
    }

    // Sort selection using abbrv
    const t1 = [];
    nodes.sort((a, b) => {
      return a.ord - b.ord;
    });
    for (const n of nodes) {
      t1.push(n.id);
    }

    if (validateSelection(t1)) {
      setSelected(t1);
    }
    // Bug 337040
    else if (0 === t1.length)
    {
      setSelected([]);
    }

  }

  /**
   * Double click on a label
   * @param {*} id
   */
  function doubleClick(id) {
    if (Array.isArray(id)) return;

    const a = [];
    const parentandchildren = [];
    const n = TreeUtility.findinTree(props.tree, null, id);
    if (n) {
      for (const c of n.children) {
        parentandchildren.push(c.id);
      }
      parentandchildren.push(id);
    }


    const i = selected.indexOf(id);
    // It is currently selected item
    if (-1 < i) {
      // Do not copy children into a
      for (const n of selected) {
        if (-1 === parentandchildren.indexOf(n)) a.push(n);
      }
    }
    // It is not currently selected
    else {
      for (const s of selected) {
        a.push(s)
      }
      // Add parentchildren to selection
      for (const s1 of parentandchildren) {
        a.push(s1);
      }
    }

    // Remove confidential from selection
    const nodes = [];
    for (const id of a) {
      const n = TreeUtility.findinTree(props.tree, null, id);
      if (!n.confidential) {
        nodes.push(n);
      }
    }

    // Sort selection using abbrv
    const t = [];
    nodes.sort((a, b) => {
      return a.ord - b.ord;
    });
    for (const n of nodes) {
      t.push(n.id);
    }

    if (validateSelection(t)) {
      setSelected(t);
    }
    // Bug 337040
    else if (0 === t.length)
    {
      setSelected([]);
    }
  }

  /**
   * Manual handler to differentiate a single click from double click
   * @param {*} event
   * @param {*} id
   * @param {*} count
   */
  function labelClick(event, id, count) {
    clearTimeout(timer.current);
    event.bubbles = false;
    if (event.detail === 1) {
      timer.current = setTimeout(singleClick(id), 500);
    } else if (event.detail === 2) {
      doubleClick(id);
    }
  }

  /**
   * Verification of selection
   * @param {*} nodes
   * @returns
   */
  function validateSelection(nodes) {
    // Nothing left
    if (nodes.length === 0) {
      // Bug 329807. 
      setError(Messages.SECTORCOUNTERROR1);
      setNotValid(true);
      return;
    }

    // More than 16 selected
    if (nodes.length > 16) {
      setError(Messages.SECTORCOUNTERROR);
      setNotValid(true);
      return false;
    }

    // Success
    setError("");
    setNotValid(false);
    return true;
  }

  /**
   * We are manipulating selection and expansions on icons and labels.
   * Se make sure that this function does nothing
   */
  function handleSelect(event, nodeIds) {
    return;
  }

  /**
   * We are manipulating toggle and expansions on icons and labels.
   * Se make sure that this function does nothing
   */
   function handleToggle(event, value){
    return;
  }
  /**
   * Recursively render tree nodes
   * @param {*} nodes
   * @returns
   */
  function renderTree(nodes) {
    if (null == nodes) return null;
    if (Array.isArray(nodes)) {
      return nodes.map((node) => renderTree(node));
    } else {
      if (nodes.confidential) {
        return (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            expandIcon={
              <ExpandMore
                onClick={(event) => {
                  handleExpandCollapse(event, nodes.id);
                }}
              />
            }
            collapseIcon={
              <ExpandLessIcon
                onClick={(event) => {
                  handleExpandCollapse(event, nodes.id);
                }}
              />
            }
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={false}
                    disabled={true}
                    onClick={(event) => {
                      handleExpandCollapse(event, nodes.id);
                    }}
                    tabIndex={0}
                  />
                }
                label={<Typography sx={MStyles.treelabelstyle}
                  onClick={(event) => {
                    handleExpandCollapse(event, nodes.id);
                  }}>
                  {nodes.name}
                </Typography>
                }
              />
            }
            focused={{
              outline: "2px solid #9263DE",
              textDecoration: "underline",
            }}
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTree(node))
              : null}
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            expandIcon={
              <ExpandMore
                onClick={(event) => {
                  handleExpandCollapse(event, nodes.id);
                }}
              />
            }
            collapseIcon={
              <ExpandLessIcon
                onClick={(event) => {
                  handleExpandCollapse(event, nodes.id);
                }}
              />
            }
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={(event) => { labelClick(event, nodes.id) }}
                    checked={-1 < selected.indexOf(nodes.id)}
                  ></Checkbox>
                }
                label={<Typography sx={MStyles.treelabelstyle}
                  onClick={(event) => {
                    handleExpandCollapse(event, nodes.id);
                  }}>
                  {nodes.name}
                </Typography>
                }
              />
            }
            focused={{
              outline: "2px solid #9263DE",
              textDecoration: "underline",
            }}
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTree(node))
              : null}
          </TreeItem>
        );
      }
    }
  }

  // To detect if this is a a mobile screen we need to check for sm
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Dialog open={props.open} fullScreen={!small} aria-labelledby="title" aris-describedby="title" aria-modal="true" onKeyDown={ariaescape}  >
      <Box
        display="flex"
        sx={{ justifyContent: "flex-end", marginBottom: 0, paddingBottom: 0 }}
      >
        <IconButton
          onClick={() => {
            cancelselect();
          }}
        >
          <CloseIcon titleAccess="Close dialog" />
        </IconButton>
      </Box>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1" sx={MStyles.h1} id="title">
              Select sectors
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.messages.map((row, index) => (
          <DialogContentText sx={MStyles.body} key={index} >{row}</DialogContentText>
        ))}
        <Alert
          severity="error"
          sx={error ? MStyles.dialogwarning : MStyles.dialogwarninghide}
        >
          {error}
        </Alert>
        <Stack justifyContent="flex-end" direction="row" sx={{ width: "100%" }}>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.clearselectionbutton}
            onClick={() => {
              clear();
            }}
            disabled={0 === selected.length}
          >
            Clear selection
          </Button>
        </Stack>
        <TreeView
          aria-label="Select sector"
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ExpandLessIcon />}
          disabledItemsFocusable={true}
          expanded={expanded}
          selected={selected}
          multiSelect={true}
          sx={{ flexGrow: 1 }}
          onNodeSelect={(event, nodeIds) => {
            handleSelect(event, nodeIds);
          }}
          onNodeToggle={(event, nodeIds) => {
            handleToggle(event, nodeIds);
          }}
        >
          {renderTree(props.tree)}
        </TreeView>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.linkbutton}
          onClick={() => {
            cancelselect();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          disabled={notvalid}
          sx={MStyles.buttondimstyle}
          onClick={() => {
            confirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
