// React
import * as React from "react";

// MUI
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Custom drop down menu used by download drop down
 */
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    marginTop: theme.spacing(0.3),
    minWidth: 200,
    background: "#F2F2F2",
    border: "1px solid #999999",
    "& .MuiMenuItem-root": {
      padding: theme.spacing(1.8),
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function VisualisationDownload(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [graphvisible, setGraphVisible] = React.useState(props.graphvisible);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadcsv = () => {
    setAnchorEl(null);
    props.ondownloadcsv();
  };

  const downloadexcel = () => {
    setAnchorEl(null);
    props.ondownloadexcel();
  };

  const downloadpng = () => {
    setAnchorEl(null);
    props.ondownloadchart();
  };

  React.useEffect(() => {
    setGraphVisible(props.graphvisible);
  }, [props.graphvisible]);

  if (graphvisible) {
    return (
      <div>
        <Button
          id="download-data-button"
          aria-controls={open ? "download-data-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          disableElevation
          disableRipple
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={MStyles.downloaddatabutton}
        >
          Download data
        </Button>
        <StyledMenu
          id="download-data-menu"
          MenuListProps={{
            "aria-labelledby": "download-data-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={downloadpng}
            sx={MStyles.downloaddatabutton}
            divider
          >
            Download graph as PNG
          </MenuItem>
          <Divider sx={{ my: 0.4 }} />
          <MenuItem
            onClick={downloadcsv}
            sx={MStyles.downloaddatabutton}
            divider
          >
            Download table as CSV
          </MenuItem>
          <Divider sx={{ my: 0.4 }} />
          <MenuItem
            onClick={downloadexcel}
            sx={MStyles.downloaddatabutton}
            divider
          >
            Download table as XLSX
          </MenuItem>
        </StyledMenu>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          id="download-data-button"
          aria-controls={open ? "download-data-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          disableElevation
          disableRipple
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={MStyles.downloaddatabutton}
          key={graphvisible}
        >
          Download data
        </Button>
        <StyledMenu
          id="download-data-menu"
          MenuListProps={{"aria-labelledby": "download-data-button",  }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={downloadcsv}
            sx={MStyles.downloaddatabutton}
            divider
          >
            Download table as CSV
          </MenuItem>
          <Divider sx={{ my: 0.4 }} />
          <MenuItem
            onClick={downloadexcel}
            sx={MStyles.downloaddatabutton}
            divider
          >
            Download table as XLSX
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }
}
