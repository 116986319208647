// React
import * as React from "react";

// MUI
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

// Components
import FAQTabs from "./FAQTabs";

/**
 * Display information about Frequently Asked Questions
 */
export default class FAQ extends React.Component {
  page = Page.FAQ;
  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
        <div maxwidth="xl">
          <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
          <Typography paragraph sx={MStyles.body}>
            Select a category to find the answers to frequently asked questions.
          </Typography>

          {/* FAQ Tabs Area */}
          <FAQTabs />

        </div>
    );
  }
}
