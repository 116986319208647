// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TreeUtility from "../classes/TreeUtility";
import TransformationUtility from "../classes/TransformationUtility";

// Components
import MultipleSelectTree from "./MultipleSelectTree";
import SingleSelectGasTree from "./SingleSelectGasTree";
import SingleSelectTree from "./SingleSelectTree";
import Visualisation from "./Visualisation";

/**
 * Kyoto Protocol page
 * @param {*} props
 * @returns
 */
export default function Kyoto(props) {

   // Reducer function for a filter
   function reducer(state, filter) {
    return {
      datatypedisabled: filter.datatypedisabled,
      datatypeselected: filter.datatypeselected,
      fueldisabled: filter.fueldisabled,
      fuelselected: filter.fuelselected,
      gasselected: filter.gasselected,
      sectorselected: filter.sectorselected,
    };
  }

  // Use reducer to minimize invocations of API
  // This should contain all variables that are invoked by API
  // It should be initialized with default values
  const [filter, dispatch] = React.useReducer(
    reducer,
    {
      datatypedisabled : true,
      datatypeselected: props.datatypeselected,
      fueldisabled: true,
      fuelselected: props.fuelnotavailableid,
      gasselected: props.gasdefaultar,
      sectorselected: props.sectorselected,
    }
  )

  const [charttype, setChartType] = React.useState("StackedBar");
  const [displayline, setDisplayLine] = React.useState(true);
  const [displaybar, setDisplayBar] = React.useState(false);
  const [displaystackedbar, setDisplayStackedBar] = React.useState(true);

  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);

  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);

  const [datatypedialog, setDataTypeDialog] = React.useState(false);
  const [datatypekey, setDataTypeKey] = React.useState("dt" + Date.now());

  const [fueldialog, setFuelDialog] = React.useState(false);
  const [fuelkey, setFuelKey] = React.useState("f" + Date.now());

  const [gasdialog, setGasDialog] = React.useState(false);
  const [gaskey, setGasKey] = React.useState("g" + Date.now());

  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  const [loading, setLoading] = React.useState(false);

  const [location] = React.useState("Australia");
  const [sector, setSector] = React.useState("");
  const [gas, setGas] = React.useState("");
  const [units, setUnits] = React.useState("");
  const [fuel, setFuel] = React.useState("");
  const [emissiontype, setEmissionType] = React.useState("");
  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);

  const page = Page.Kyoto;
  const digits = 4;
  //#region Effects. Effects are run in order they are specified

  /**
   * Reset to default if pages changes.
   */
  React.useEffect(() => {
    if (props.page === Page.SearchResults.page) {
      if (-1 !== props.searchkyotoid) {
        dispatch({
          datatypedisabled: true,
          datatypeselected: props.datatypenotavailableid,
          fueldisabled: true,
          fuelselected: props.fuelnotavailableid,          
          gasselected: props.gasdefaultar,
          sectorselected: props.searchkyotoid,
        })
      }
    } else if (props.page !== page.page) {
      dispatch({
        datatypedisabled: true,
        datatypeselected: props.datatypenotavailableid,
        fueldisabled: true,
        fuelselected: props.fuelnotavailableid,
        gasselected: props.gasdefaultar,
        sectorselected: props.sectorselected,
      })
    }
  }, [
    page.page,
    props.page,
    props.datatypenotavailableid,
    props.fuelnotavailableid,
    props.gasdefaultar,
    props.searchkyotoid,
    props.sectorselected
  ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    /**
     * Transform data into components used by visualization.
     * This follows successful data load so it is included in this effect.
     * @param {*} apid
     */
    function transformdata(apid) {
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];

      const _generationspread = TransformationUtility.calculateGenerationSpread(
        props.sectortree,
        filter.sectorselected
      );

      TransformationUtility.prepareTimeSeries(
        props.firstyear,
        props.lastyear,
        _headers,
        _chartdata,
        _tabledata
      );

      const maxvalue = TransformationUtility.getMaxValue(apid, filter.sectorselected, _tabledata);
      const scale = TransformationUtility.getScale(maxvalue);
      const hd = TransformationUtility.populateTimeSeries(
        apid,
        filter.sectorselected,
        props.sectortree,
        _chartdata,
        _lines,
        _tabledata,
        _headers,
        digits,
        scale.factor
      );

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const gas = TransformationUtility.calculateGasDisplayName(
        props.gastreeraw,
        props.gastreear,
        filter.gasselected
      );

      const sectors1 = TransformationUtility.calculateSelectionList(
        props.sectortree,
        filter.sectorselected
      );
      const f = TransformationUtility.calculateTitle(
        filter.fueldisabled,
        props.fueltree,
        filter.fuelselected
      );
      const e = TransformationUtility.calculateTitle(
        filter.datatypedisabled,
        props.datatypetree,
        filter.datatypeselected
      );

      const g = _generationspread ? "Line" : "StackedBar";

      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setChartType(g);
      setDisplayLine(true);
      setDisplayBar(false);
      setDisplayStackedBar(!_generationspread);
      setHasData(hd.hasdata);
      setPartialData(hd.partialdata);
      setSector(sectors1);
      setGas(gas);
      setFuel(f);
      setEmissionType(e);
      setUnits(scale.units);
    }

    if (
      !filter.sectorselected ||
      !filter.gasselected ||
      (!props.fuelnotavailableid && !filter.fuelselected) ||
      (!props.datatypenotavailableid && !props.datatypenotavailableid) ||
      props.page !== page.page
    ) {
      setLines(null);
      setChartData(null);
      setHeaders(null);
      setTableData(null);
      return null;
    }

    setLoading(true);
    let base = "/api/OutputTimeSeries?";
    const sectorstring = ArrayUtility.convertArrayToStringIDs(filter.sectorselected);
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        urlencodedsectorlist: sectorstring,
        locationid: props.locationselected,
        fuelid: filter.fuelselected,
        gasid: filter.gasselected,
        datatypeid: filter.datatypeselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            setLines(null);
            setChartData(null);
            setHeaders(null);
            setTableData(null);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformdata(d);
          }
        } else {
          setLines(null);
          setChartData(null);
          setHeaders(null);
          setTableData(null);
        }
      })
      .catch((data) => {
        console.log("Error");
        setLines(null);
        setChartData(null);
        setHeaders(null);
        setTableData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.datatypenotavailableid,
    props.datatypetree,
    props.firstyear,
    props.fuelnotavailableid,
    props.fueltree,
    props.gastreear,
    props.gastreeraw,
    props.lastyear,
    props.locationselected,
    props.page,
    props.sectortree
  ]);

  //#endregion

  //#region Data Type
  /**
   * Cancel selection of Data Type
   */
  function cancelselectDataType() {
    setDataTypeDialog(false);
  }

  /**
   * Open data type selection dialog
   */
  function openDataTypeDialog() {
    setDataTypeKey("dt" + Date.now());
    setDataTypeDialog(true);
  }

  /**
   * Save selection of data type. Update Data
   */
  function saveselectDataType(value) {
    const d = TreeUtility.findinTree(props.datatypetree, null, value);
    setDataTypeDialog(false);
    setEmissionType(d ? d.name : "");
    const f = filter;
    dispatch({
      datatypedisabled: f.datatypedisabled,
      datatypeselected: value,
      fueldisabled: f.fuelselected,
      fuelselected: f.fuelselected,
      gasselected: f.gasselected,
      sectorselected: f.sectorselected,
    })
  }
  //#endregion

  //#region Fuel

  /**
   * Cancel selection of Fuel
   */
  function cancelselectFuel() {
    setFuelDialog(false);
  }

  /**
   * Open fuel selection dialog
   */
  function openFuelDialog() {
    setFuelKey("f" + Date.now());
    setFuelDialog(true);
  }

  /**
   * Save selection of fuel. Updata data
   * @param {*} value selected key
   */
  function saveselectFuel(value) {
    setFuelDialog(false);
    const f = filter;
    dispatch({
      datatypedisabled: f.datatypedisabled,
      datatypeselected: f.datatypeselected,
      fueldisabled: f.fueldisabled,
      fuelselected: value,
      gasselected: f.gasselected,
      sectorselected: f.sectorselected,
    })    
  }

  //#endregion

  //#region Gas

  /**
   * Cancel selection of Gas
   */
  function cancelselectGas() {
    setGasDialog(false);
  }

  /**
   * Open Gas dialog
   */
  function openGasDialog() {
    setGasKey("g" + Date.now());
    setGasDialog(true);
  }

  /**
   * Save selection of Gas
   */
  function saveselectGas(value) {
    setGasDialog(false);
    const f = filter;
    dispatch({
      datatypedisabled: f.datatypedisabled,
      datatypeselected: f.datatypeselected,
      fueldisabled: f.fueldisabled,
      fuelselected: f.fuelselected,      
      gasselected: value,
      sectorselected: f.sectorselected,
    })    
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    const arr = [];
    if (Array.isArray(value)) {
      for (const s of value) {
        const n = TreeUtility.findinTree(props.sectortree, null, s);
        if (n) {
          arr.push(n.name);
        }
      }
    } else {
      const n = TreeUtility.findinTree(props.sectortree, null, value);
      if (n) {
        arr.push(n.name);
      }
    }

    const fdisabled = !TreeUtility.allWithinParents(
      props.sectortree,
      value,
      props.topfuelsectors
    );
    const dtdisabled = !TreeUtility.allWithinParents(
      props.sectortree,
      value,
      props.topdatatypesectors
    );

    setSectorDialog(false);
    setSector(ArrayUtility.convertArrayToString(arr));
   
    let fuel = props.fuelnotavailableid;    
    if (fdisabled) {
      fuel = props.fuelnotavailableid;
    }
    else {
      if (filter.fueldisabled)
      {
        fuel = props.fuelselected;
      }
      else
      {
        fuel = filter.fuelselected;
      }
    }

    let datatype = props.datatypenotavailableid;
    if (dtdisabled)
    {
      datatype = props.datatypenotavailableid;
    }
    else {
      if (filter.datatypedisabled)
      {
        datatype = props.datatypeselected;
      }
      else {
        datatype = filter.datatypeselected;
      }
    }

    const f = filter;
    dispatch({
      datatypedisabled: dtdisabled,
      datatypeselected: datatype,
      fueldisabled: fdisabled,
      fuelselected: fuel,
      gasselected: f.gasselected,
      sectorselected: value,
    })
  }

  //#endregion

  //#region Glossary
  /**
   * Go to Glossary for specified term
   * @param {*} term
   */
  function glossaryClick(term) {
    props.onHandleGlossary(term);
  }
  //#endregion

  if (props.page !== page.page) return null;

  if (loading)
    return (
      <Box sx={MStyles.progressboxstyle} display={"flex"}>
        <CircularProgress></CircularProgress>
      </Box>
    );

  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        View emission estimates used to track Australia&apos;s progress against our
        2020 targets.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        2020 was the final year of the Kyoto Protocol Second Commitment Period.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        The chart shows total emissions accounted using{" "}
        <Link
          onClick={() => {
            glossaryClick("KP");
          }}
        >
          Kyoto Protocol classifications
        </Link>{" "}
        since 1990.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        Selecting a point on the chart will display the emissions for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
          >
            Select sector
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={filter.fueldisabled ? MStyles.hidestyle : MStyles.buttondimstyle}
            onClick={() => {
              openFuelDialog();
            }}
          >
            Select fuel
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openGasDialog();
            }}
          >
            Select gas
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={filter.datatypedisabled ? MStyles.hidestyle : MStyles.buttondimstyle}
            onClick={() => {
              openDataTypeDialog();
            }}
          >
            Select emission type
          </Button>
        </Stack>
      </Box>

      <Visualisation
        digits={digits}
        isprojections={false}
        isdate={true}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={false}
        ariatitle="Emissions under Kyoto Protocol"
        bar={displaybar}
        data={chartdata}
        defaulttype={charttype}
        displaylocation={true}
        displaysector={false}
        fileprefix={page.title}
        headers={headers}
        line={displayline}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title="Emissions under Kyoto Protocol"
        units={units}
        XAxisTitle="Year"
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={fuel}
        emissiontype={emissiontype}
      />

      <SingleSelectTree
        key={datatypekey}
        open={datatypedialog}
        tree={props.datatypetree}
        selected={filter.datatypeselected}
        title="Select emission type"
        onCancel={() => {
          cancelselectDataType();
        }}
        onSave={(selection) => {
          saveselectDataType(selection);
        }}
      />

      <SingleSelectTree
        key={fuelkey}
        open={fueldialog}
        tree={props.fueltree}
        selected={filter.fuelselected}
        title="Select fuel"
        onCancel={() => {
          cancelselectFuel();
        }}
        onSave={(selection) => {
          saveselectFuel(selection);
        }}
      />

      <SingleSelectGasTree
        key={gaskey}
        isAR5={false}
        isProjection={false}
        open={gasdialog}
        defaultar={props.gasdefaultar}
        treear={props.gastreear}
        defaultraw={props.gasdefaultraw}
        treeraw={props.gastreeraw}
        selected={filter.gasselected}
        title="Select gas"
        onCancel={() => {
          cancelselectGas();
        }}
        onSave={(selection) => {
          saveselectGas(selection);
        }}
      />

      <MultipleSelectTree
        key={sectorkey}
        messages={Messages.SECTOR_KP_INFO}
        open={sectordialog}
        tree={props.sectortree}
        selected={filter.sectorselected}
        title="Select Sector"
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />
      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          Estimates are based on the Kyoto Protocol classification system which
          primarily effects emissions from the land sector. These estimates are
          compiled using the global warming potentials from the IPCC Fourth
          Assessment Report.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Emission estimates for subsectors in certain locations are not
          available due to confidentiality constraints.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Bar charts are not available for queries with different levels of the sectoral hierarchy selected. For example, if the user selects 1 Energy and 1.A Fuel Combustion sectors the data will be available as a line chart or table only, as 1.A Fuel Combustion is a subsector of 1 Energy.
        </Typography>
      </Box>
    </div>
  );
}
