// React
import * as React from "react";

// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Navigation card
 */
export default class NormalCard extends React.Component {
  state = {
    selectionPage: "",
  };

  handleNavigation = (page) => {
    this.props.onHandleNavigation(page);
  };

  render() {
    return (
      <Grid
        item={true}
        xs={12} sm={6} md={4} lg={3} xl={3}
        sx={MStyles.normalcardgridstyle}
      >
        <Card
          variant="outlined"
          sx={MStyles.normalcardstyle}
          tabIndex={0}
          onClick={() => {
            this.handleNavigation(this.props.page);
          }}
        >
          <CardHeader
            component="h2"
            sx={MStyles.normalcardheaderstyle}
            title={this.props.header}
          ></CardHeader>
          <CardContent>
            <Typography paragraph sx={MStyles.body}>
              {this.props.text}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}
