// React
import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * No data message
 */
export default function NoData(props) {
  const [nd, setND] = React.useState(props.nodata);

  React.useEffect(() => {
    setND(props.nodata);
  }, [props.nodata]);

  if (!nd) return null;

  return (
    <Alert
      severity="info"
      icon={<ErrorIcon fontSize="large" sx={{ color: "#2E5299" }} />}
      sx={MStyles.dimensionboxmessagestyle}
    >
      <AlertTitle>
        <Typography component="h4" sx={MStyles.warning}>
          No data is available
        </Typography>
      </AlertTitle>
      <Typography paragraph sx={MStyles.body}>
        A chart cannot be produced.
      </Typography>
    </Alert>
  );
}
