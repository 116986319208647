// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Classes
import ArrayUtility from "../classes/ArrayUtility";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";
import TransformationUtility from "../classes/TransformationUtility";
import TreeUtility from "../classes/TreeUtility";

// Components
import MultipleSelectLocationTree from "./MultipleSelectLocationTree";
import SelectYears from "./SelectYears";
import SingleSelectGasTree from "./SingleSelectGasTree";
import SingleSelectTree from "./SingleSelectTree";
import Visualisation from "./Visualisation";

/**
 * Display data for states
 * @param {*} props
 * @returns
 */
export default function States(props) {

   // Reducer function for a filter
   function reducer(state, filter) {
    return {
      gasselected: filter.gasselected,
      isseries: filter.isseries,
      locationselected: filter.locationselected,
      sectorselected: filter.sectorselected,
      yearsselected: filter.yearsselected
    };
  }

  // Use reducer to minimize invocations of API
  // This should contain all variables that are invoked by API
  // It should be initialized with default values
  const [filter, dispatch] = React.useReducer(
    reducer,
    {
      gasselected: props.gasdefaultar,
      isseries: false,
      locationselected: props.locationselected,
      sectorselected: props.sectorselected,
      yearsselected: props.lastyear
    }
  )

  const [charttype] = React.useState("StackedBar");
  const [displayline, setDisplayLine] = React.useState(true);
  const [displaybar] = React.useState(false);
  const [displaystackedbar] = React.useState(true);
  // Chart elements. Data transformed for chart display
  const [chartdata, setChartData] = React.useState(null);
  const [lines, setLines] = React.useState(null);
  // Table elements. Data transformed for table display
  const [tabledata, setTableData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);

  const [gasdialog, setGasDialog] = React.useState(false);
  const [gaskey, setGasKey] = React.useState("g" + Date.now());

  const [locationdialog, setLocationDialog] = React.useState(false);
  const [locationkey, setLocationKey] = React.useState("l" + Date.now());

  const [sectordialog, setSectorDialog] = React.useState(false);
  const [sectorkey, setSectorKey] = React.useState("s" + Date.now());

  const [years, setYears] = React.useState();
  const [yearsdialog, setYearsDialog] = React.useState(false);
  const [yearskey, setYearsKey] = React.useState("y" + Date.now());

  const [confidential, setConfidential] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasdata, setHasData] = React.useState(false);
  const [partialdata, setPartialData] = React.useState(false);
  const [location, setLocation] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [gas, setGas] = React.useState("");
  const [units, setUnits] = React.useState("");
  const [displaylocation, setDisplayLocation] = React.useState(false);
  const [displaysector, setDisplaySector] = React.useState(false);

  const page = Page.States;
  const digits = 4;

  //#region Effects. Effects are run in order they are specified

  /**
   * Set Years, Reset to default after page change
   */
  React.useEffect(() => {
    if (!props.gasdefaultar) return;
    if (!props.locationselected) return;
    if (!props.sectorselected) return;
    if (!props.firstyear) return;
    if (!props.lastyear) return;

    const yy = [];
    for (let i = props.firstyear; i <= props.lastyear; i++) {
      yy.push({ id: i, name: i });
    }

    setYears(yy);

    if (props.page !== page.page) {
      dispatch({
        gasselected: props.gasdefaultar,
        isseries: false,
        locationselected: props.locationselected,
        sectorselected: props.sectorselected,
        yearsselected: props.lastyear
      });
    }
  }, [
    page.page,
    props.firstyear,
    props.gasdefaultar,
    props.lastyear,
    props.locationselected,
    props.page,
    props.sectorselected
  ]);

  /**
   * Get emissions by location for a single year
   */
  React.useEffect(() => {
    if (props.page !== page.page) return null;
    if (filter.isseries) return null;
    if (!filter.gasselected) return null;
    if (!filter.locationselected) return null;
    if (!filter.sectorselected) return null;
    if (!props.fuelnotavailableid) return null;

    function transformLocation(apid) {
      // This is a selected sector id
      const sectorid = filter.sectorselected;
      let hd = false;
      let pd = false;

      const sectorchildren = TreeUtility.findinTree(
        props.sectortree,
        null,
        sectorid
      ).children;

      const sectornode = TreeUtility.findinTree(
        props.sectortree,
        null,
        sectorid
      );

      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];
      let maxvalue = -1;

      // Location Header column
      _headers.push({ key: "loc", label: "Location", align: "left" });
      if (0 !== sectorchildren.length) {
        for (const s of sectorchildren) {
          _headers.push({ key: s.id, label: s.name, align: "right" });
          _lines.push({ key: s.id, label: s.name });
        }
      } else {
        _headers.push({
          key: sectornode.id,
          label: sectornode.name,
          align: "right",
        });
        _lines.push({ key: sectornode.id, label: sectornode.name });
      }

      const location_nodes = [];
      for (const l of filter.locationselected) {
        location_nodes.push(
          TreeUtility.findinTree(props.locationtree, null, l)
        );
      }

      // Populate data for XAxis key and name are reserved names
      for (const l of location_nodes) {
        _chartdata.push({ key: l.id, label: l.name });
        // The name of the second attribute needs to agree with
        _tabledata.push({ key: l.id, loc: l.name });
      }

      // Populate table data with empty values
      if (0 !== sectorchildren.length) {
        for (const s of sectorchildren) {
          for (const row of _tabledata) {
            row[s.id] = "Not available";
          }
        }
      } else {
        for (const row of _tabledata) {
          row[sectornode.id] = "Not available";
        }
      }

      // Calculate the absolute maximum value to determine the scale of data.
      for (const row of _tabledata) {
        for (const h of _headers) {
          for (const id of apid) {
            if (id.s.toString() === h.key && row.key === id.l.toString()) {
              if (maxvalue < Math.abs(id.v)) maxvalue = Math.abs(id.v);
            }
          }
        }
      }

      const scale = TransformationUtility.getScale(maxvalue);

      // Populate chart data
      for (const row of _chartdata) {
        for (const h of _headers) {
          for (const id of apid) {
            if (id.s.toString() === h.key && row.key === id.l.toString()) {
              row[h.key] = Number((id.v * scale.factor).toFixed(digits));
              hd = true;
              break;
            }
          }
        }
      }

      // Populate table data
      for (const row of _tabledata) {
        for (const h of _headers) {
          for (const id of apid) {
            if (id.s.toString() === h.key && row.key === id.l.toString()) {
              row[h.key] = Intl.NumberFormat("en-AU", {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              }).format(id.v * scale.factor);
              hd = true;
              break;
            }
          }
        }
      }

      // Calculate confidentiality. Loop through headers and rows
      let conf = false;
      if (processConfidential("ACT", props.confidentialSectorACT)) conf = true;
      if (processConfidential("AUSTRALIA", props.confidentialSectorAU)) conf = true;
      if (processConfidential("EXTERNAL TERRITORIES (ET)",props.confidentialSectorET)) conf = true;
      if (processConfidential("NSW", props.confidentialSectorNSW)) conf = true;
      if (processConfidential("NT", props.confidentialSectorNT)) conf = true;
      if (processConfidential("QLD", props.confidentialSectorQLD)) conf = true;
      if (processConfidential("SA", props.confidentialSectorSA)) conf = true;
      if (processConfidential("TAS", props.confidentialSectorTAS)) conf = true;
      if (processConfidential("VIC", props.confidentialSectorVIC)) conf = true;
      if (processConfidential("WA", props.confidentialSectorWA)) conf = true;

      // Check partial data. Make sure that this is run after confidentiality.
      pd = TransformationUtility.hasNotAvailable(_tabledata);

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const selectedgasraw = TreeUtility.findinTree(
        props.gastreeraw,
        null,
        filter.gasselected
      );

      const selectedgasar = TreeUtility.findinTree(
        props.gastreear,
        null,
        filter.gasselected
      );

      const gas = selectedgasraw ? selectedgasraw.name : selectedgasar.name;

      const selectedsector = TreeUtility.findinTree(
        props.sectortree,
        null,
        filter.sectorselected
      );

      const ll = [];
      for (const loc of filter.locationselected) {
        const tn = TreeUtility.findinTree(props.locationtree, null, loc);
        if (tn) ll.push(tn.name);
      }

      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }
      setHasData(hd);
      setPartialData(pd);
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setGas(gas);
      setDisplayLine(false);
      setConfidential(conf);
      setDisplayLocation(false);
      setDisplaySector(false);
      setUnits(scale.units);

      if (ll) setLocation(ArrayUtility.convertArrayToStringIDs(ll));
      if (selectedsector) {
        setSector("Subsectors of " + selectedsector.name);
      }

      /**
       * Check if sector is in
       * @param {*} location
       * @param {*} list
       * @returns
       */
      function processConfidential(location, list) {
        if (0 === list.length) return false;
        let t = false;
        for (const l of location_nodes) {
          for (const s of sectorchildren) {
            if (l.name === location) {
              if (-1 < list.indexOf(s.id)) {
                t = true;
                for (const r of _tabledata) {
                  if (r["loc"] === location) {
                    r[s.id] = "Confidential";
                  }
                }
              }
            }
          }
        }
        return t;
      }
    }

    setLoading(true);
    const base = "/api/StatesSingleYear?";
    const locationstring =
      ArrayUtility.convertArrayToStringIDs(filter.locationselected);

    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        sectorid: filter.sectorselected,
        urlencodedlocationlist: locationstring,
        yearid: filter.yearsselected,
        fuelid: props.fuelnotavailableid,
        gasid: filter.gasselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformLocation(d);
          }
        } else {
          console.error("Error no values returned");
        }
      })
      .catch((data) => {
        console.log("Error");
        console.log(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.confidentialSectorACT,
    props.confidentialSectorAU,
    props.confidentialSectorET,
    props.confidentialSectorNSW,
    props.confidentialSectorNT,
    props.confidentialSectorQLD,
    props.confidentialSectorSA,
    props.confidentialSectorTAS,
    props.confidentialSectorVIC,
    props.confidentialSectorWA,
    props.fuelnotavailableid,
    props.gasselected,
    props.gastreear,
    props.gastreeraw,
    props.locationtree,
    props.locationselected,
    props.page,
    props.sectorselected,
    props.sectortree,
  ]);

  /**
   * Get time series if any of the selections changed
   */
  React.useEffect(() => {
    if (props.page !== page.page) return;
    if (!filter.isseries) return;
    if (!filter.gasselected) return null;
    if (!filter.locationselected) return null;
    if (!filter.sectorselected) return null;
    if (!props.fuelnotavailableid) return null;

    function transformTimeseries(apid) {
      let hd = false;
      let pd = false;
      let _chartdata = [];
      let _lines = [];
      let _headers = [];
      let _tabledata = [];
      let maxvalue = -1;
      // Year Header column
      _headers.push({ key: "date", label: "Year", align: "left" });

      const location_nodes = [];
      for (const l of filter.locationselected) {
        location_nodes.push(
          TreeUtility.findinTree(props.locationtree, null, l)
        );
      }
      // Populate data for XAxis key and name are reserved names
      for (const l of location_nodes) {
        _lines.push({ key: l.id, label: l.name });
        // The name of the second attribute needs to agree with
        _headers.push({ key: l.id, label: l.name, align: "right" });
      }

      // Populate data for XAxis key and name are reserved names
      // Populae data for year table column
      for (let y = props.firstyear; y <= props.lastyear; y++) {
        _chartdata.push({ date: y.toString() });
        _tabledata.push({ date: y.toString() });
      }
      for (const l of location_nodes) {
        for (const row of _tabledata) {
          row[l.id] = "Not available";
        }
      }

      // Populate table data
      for (const row of _tabledata) {
        for (let id of apid) {
          if (id.date.toString() === row.date) {
            if (maxvalue < Math.abs(id.v)) maxvalue = Math.abs(id.v);
          }
        }
      }

      const scale = TransformationUtility.getScale(maxvalue);

      // Populate chart data
      for (const row of _chartdata) {
        for (const id of apid) {
          if (id.date.toString() === row.date) {
            row[id.l] = Number((id.v * scale.factor).toFixed(digits));
            hd = true;
          }
        }
      }
      // Populate table data
      for (const row of _tabledata) {
        for (let id of apid) {
          if (id.date.toString() === row.date) {
            row[id.l] = Intl.NumberFormat("en-AU", {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            }).format(id.v * scale.factor);
            hd = true;
          }
        }
      }

      // Process confidentiality
      let conf = false;
      for (const l of location_nodes) {
        if (
          l.name === "ACT" &&
          processConfidential(l.id, props.confidentialSectorACT)
        )
          conf = true;
        if (
          l.name === "AUSTRALIA" &&
          processConfidential(l.id, props.confidentialSectorAU)
        )
          conf = true;
        if (
          l.name === "EXTERNAL TERRITORIES (ET)" &&
          processConfidential(l.id, props.confidentialSectorET)
        )
          conf = true;
        if (
          l.name === "NSW" &&
          processConfidential(l.id, props.confidentialSectorNSW)
        )
          conf = true;
        if (
          l.name === "NT" &&
          processConfidential(l.id, props.confidentialSectorNT)
        )
          conf = true;
        if (
          l.name === "QLD" &&
          processConfidential(l.id, props.confidentialSectorQLD)
        )
          conf = true;
        if (
          l.name === "SA" &&
          processConfidential(l.id, props.confidentialSectorSA)
        )
          conf = true;
        if (
          l.name === "TAS" &&
          processConfidential(l.id, props.confidentialSectorTAS)
        )
          conf = true;
        if (
          l.name === "VIC" &&
          processConfidential(l.id, props.confidentialSectorVIC)
        )
          conf = true;
        if (
          l.name === "WA" &&
          processConfidential(l.id, props.confidentialSectorWA)
        )
          conf = true;
      }

      // Check partial data. Make sure that this is run after confidentiality.
      pd = TransformationUtility.hasNotAvailable(_tabledata);

      _lines = TransformationUtility.getLinesWithData(_lines, _chartdata);

      const selectedgasraw = TreeUtility.findinTree(
        props.gastreeraw,
        null,
        filter.gasselected
      );

      const selectedgasar = TreeUtility.findinTree(
        props.gastreear,
        null,
        filter.gasselected
      );

      const sector = TreeUtility.findinTree(
        props.sectortree,
        null,
        filter.sectorselected
      );
      const ll = [];
      for (const loc of filter.locationselected) {
        const tn = TreeUtility.findinTree(props.locationtree, null, loc);
        if (tn) ll.push(tn.name);
      }

      for (let h = 1; h < _headers.length; h++) {
        _headers[h].label += " (" + scale.units + ")";
      }

      setGas(selectedgasraw ? selectedgasraw.name : selectedgasar.name);
      setHasData(hd);
      setPartialData(pd);
      setLines(_lines);
      setChartData(_chartdata);
      setHeaders(_headers);
      setTableData(_tabledata);
      setDisplayLine(true);
      setDisplayLocation(false);
      setDisplaySector(true);

      setConfidential(conf);
      setSector(sector.name);
      setGas(selectedgasraw ? selectedgasraw.name : selectedgasar.name);
      if (ll) setLocation(ArrayUtility.convertArrayToStringIDs(ll));
      setUnits(scale.units);

      /**
       * Check if sector is in
       * @param {*} location
       * @param {*} list
       * @returns
       */
      function processConfidential(locationid, list) {
        if (0 === list.length) return false;
        let t = false;
        if (-1 < list.indexOf(filter.sectorselected)) {
          for (const r of _tabledata) {
            r[locationid] = "Confidential";
            t = true;
          }
        }
        return t;
      }
    }

    // Check required arguments. If missing set data to null

    setLoading(true);
    let base = "/api/StatesOutputTimeSeries?";
    const locationstring =
      ArrayUtility.convertArrayToStringIDs(filter.locationselected);
    // Documentation see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // Note that URLSearchParams will URLEncode strings
    fetch(
      base +
      new URLSearchParams({
        sectorid: filter.sectorselected,
        urlencodedlocationlist: locationstring,
        fuelid: props.fuelnotavailableid,
        gasid: filter.gasselected,
      }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json()) // Obtain promise
      .then((data) => {
        // Obtain data from promise
        if (data) {
          if (data.error) {
            console.log("Error: " + data.error);
            // console.log(data);
          } else {
            const d = data.data;
            for (let i = 0; i < d.length; i++) {
              d[i]["key"] = i;
            }
            transformTimeseries(d);
          }
        } else {
          console.log("Error");
        }
      })
      .catch((data) => {
        console.log("Error");
        // console.log(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    filter,
    page.page,
    props.confidentialSectorACT,
    props.confidentialSectorAU,
    props.confidentialSectorET,
    props.confidentialSectorNSW,
    props.confidentialSectorNT,
    props.confidentialSectorQLD,
    props.confidentialSectorSA,
    props.confidentialSectorTAS,
    props.confidentialSectorVIC,
    props.confidentialSectorWA,
    props.firstyear,
    props.fuelnotavailableid,
    props.gastreear,
    props.gastreeraw,
    props.lastyear,
    props.locationtree,
    props.page,
    props.sectortree,
  ]);

  //#endregion

  //#region Gas

  /**
   * Cancel selection of Gas
   */
  function cancelselectGas() {
    setGasDialog(false);
  }

  /**
   * Open Gas dialog
   */
  function openGasDialog() {
    setGasKey("g" + Date.now());
    setGasDialog(true);
  }

  /**
   * Save selection of Gas
   */
  function saveselectGas(value) {
    setGasDialog(false);
    const f = filter;
    dispatch({
      gasselected: value,
      isseries: f.isseries,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      yearsselected: f.yearsselected
    });
  }

  //#endregion

  //#region Location

  /**
   * Cancel selection of Location
   */
  function cancelselectLocation() {
    setLocationDialog(false);
  }

  /**
   * Open location selection dialog
   */
  function openLocationDialog() {
    setLocationKey("l" + Date.now());
    setLocationDialog(true);
  }

  /**
   * Save selection of location.
   * Sector hierarchies are slightly different for different locations so need to update
   * both hierarchy and data after change of location
   */
  function saveselectLocation(value) {
    setLocationDialog(false);
    const f = filter;
    dispatch({
      gasselected: f.gasselected,
      isseries: f.isseries,
      locationselected: value,
      sectorselected: f.sectorselected,
      yearsselected: f.yearsselected
    });    
  }

  //#endregion

  //#region Sector
  /**
   * Cancel selection
   */
  function cancelselectSector() {
    setSectorDialog(false);
  }

  /**
   * Open sector selection dialog
   */
  function openSectorDialog() {
    setSectorKey("s" + Date.now());
    setSectorDialog(true);
  }

  /**
   * Save selected sectors
   */
  function saveselectSector(value) {
    setSectorDialog(false);
    const f = filter;
    dispatch({
      gasselected: f.gasselected,
      isseries: f.isseries,
      locationselected: f.locationselected,
      sectorselected: value,
      yearsselected: f.yearsselected
    });    
  }

  //#endregion

  //#region Years
  /**
   * Cancel selection
   */
  function cancelselectYears() {
    setYearsDialog(false);
  }

  /**
   * Open year selection dialog
   */
  function openYearsDialog() {
    setYearsKey("y" + Date.now());
    setYearsDialog(true);
  }

  /**
   * Save selected year
   * @param value : {type: type, selected: selected}  where type is "One" or "Series"
   */
  function saveselectYears(value) {
    const i = value === "Series";
    setYearsDialog(false);
    const f = filter;
    dispatch({
      gasselected: f.gasselected,
      isseries: i,
      locationselected: f.locationselected,
      sectorselected: f.sectorselected,
      yearsselected: i ? null : value
    });
  }

  //#endregion

  // Do not render if the page name is different
  if (props.page !== page.page) return null;
  if (loading)
    return (
      <Box sx={MStyles.progressboxstyle} display={"flex"}>
        <CircularProgress></CircularProgress>
      </Box>
    );

  return (
    <div>
      <Typography variant="h1" sx={MStyles.h1_title} id="content">{page.title}</Typography>
      <Typography paragraph sx={MStyles.body}>
        View emission estimates for each state and territory.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        The chart shows emissions by sector for each state and territory in the
        latest inventory year.
      </Typography>
      <Typography paragraph sx={MStyles.body}>
        Selecting a point on the chart will display the emissions for that year.
      </Typography>
      <Typography variant="h2" sx={MStyles.h2}>
        Filter data using the buttons provided
      </Typography>
      <Box sx={MStyles.dimensionboxstyle}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openLocationDialog();
            }}
          >
            Select location
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openYearsDialog();
            }}
          >
            Select year(s)
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openSectorDialog();
            }}
          >
            Select sector
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.buttondimstyle}
            onClick={() => {
              openGasDialog();
            }}
          >
            Select gas
          </Button>
        </Stack>
      </Box>

      <Visualisation
        digits={digits}
        isprojections={false}
        isdate={filter.isseries}
        hasdata={hasdata}
        partialdata={partialdata}
        confidential={confidential}
        ariatitle="State and territory emissions"
        bar={displaybar}
        data={chartdata}
        defaulttype={charttype}
        displaylocation={displaylocation}
        displaysector={displaysector}
        fileprefix={page.title}
        headers={headers}
        line={displayline}
        lines={lines}
        stackedbar={displaystackedbar}
        tabledata={tabledata}
        title="State and territory emissions"
        units={units}
        XAxisTitle={filter.isseries ? "Year" : "State"}
        YAxisTitle="Emissions"
        location={location}
        sector={sector}
        gas={gas}
        fuel={""}
        emissiontype={""}
        year={filter.yearsselected}
      />

      <SingleSelectGasTree
        key={gaskey}
        isAR5={true}
        isProjection={false}
        open={gasdialog}
        defaultar={props.gasdefaultar}
        treear={props.gastreear}
        defaultraw={props.gasdefaultraw}
        treeraw={props.gastreeraw}
        selected={filter.gasselected}
        title="Select gas"
        onCancel={() => {
          cancelselectGas();
        }}
        onSave={(selection) => {
          saveselectGas(selection);
        }}
      />

      <MultipleSelectLocationTree
        key={locationkey}
        open={locationdialog}
        tree={props.locationtree}
        expanded={["3"]}
        selected={filter.locationselected}
        title="Select locations"
        onCancel={() => {
          cancelselectLocation();
        }}
        onSave={(selection) => {
          saveselectLocation(selection);
        }}
      />

      <SingleSelectTree
        key={sectorkey}
        open={sectordialog}
        tree={props.sectortree}
        selected={filter.sectorselected}
        title="Select Sector"
        onCancel={() => {
          cancelselectSector();
        }}
        onSave={(selection) => {
          saveselectSector(selection);
        }}
      />

      <SelectYears
        key={yearskey}
        data={years}
        open={yearsdialog}
        selected={props.lastyear.toString()}
        type={filter.isseries ? "Series" : "One"}
        onCancel={() => {
          cancelselectYears();
        }}
        onSave={(selection) => {
          saveselectYears(selection);
        }}
      ></SelectYears>

      <Box display={hasdata ? "block" : "none"} sx={MStyles.datafootnotebox}>
        <Typography paragraph sx={MStyles.body}>
          Estimates are based on the UNFCCC classification system used to report
          Australia&apos;s greenhouse gas emission inventory. These estimates
          are compiled using the global warming potentials from the IPCC Fifth
          Assessment Report.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Emission estimates for subsectors in certain locations are not
          available due to confidentiality constraints. As a consequence, some
          industries will present as greater than the sum of their available
          parts.
        </Typography>
      </Box>
    </div>
  );
}
