// React
import * as React from "react";

// MUI
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Display accessibility page
 */
export default class Accessibility extends React.Component {
  page = Page.Accessibility;

  handleNavigation = (page) => {
    this.props.onHandleNavigation(page);
  };

  render() {
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          The Australia&apos;s National Greenhouse Accounts website is dedicated to ensuring digital accessibility as a priority.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          We have used{" "}
          <Link
            href={ExternalURL.DTO.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            title={ExternalURL.DTO.text}
            alt={ExternalURL.DTO.alt}
          >{ExternalURL.DTO.text}</Link>
          &apos;s{" "}
          <Link
            href={ExternalURL.DTO_STANDARD.url}
            sx={MStyles.linkstyle}
            rel="noreferrer"
            title={ExternalURL.DTO_STANDARD.text}
            alt={ExternalURL.DTO_STANDARD.alt}
          >{ExternalURL.DTO_STANDARD.text}</Link>
          {" "}for designing this website where possible.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          The website underwent an accessibility audit in June 2022 as part of our compliance with the standard. Work is currently being carried out to remediate the issues found as a result of the audit.  Our intention is to fix as many accessibility issues as possible over the course of time.
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          If you experience difficulty accessing our content, please{" "}
          <Link tabIndex={0} 
              onClick={() => {
                this.handleNavigation(Page.Contact.page);
              }}
              sx={MStyles.linkstyle }
            >
              contact us.
            </Link>
        </Typography>
      </div>
    );
  }
}
