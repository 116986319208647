// React
import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// recharts-to-png
import { useCurrentPng } from "recharts-to-png";

// FileSaver
import FileSaver from "file-saver";

// Classes
import FilenameGenerator from "../classes/FilenameGenerator";
import MStyles from "../classes/MStyles";
import TableToCSV from "../classes/TabletoCSV";
import TableToExcel from "../classes/TableToExcel";

// Components
import ConfidentialDataAll from "./ConfidentialDataAll";
import ConfidentialDataSome from "./ConfidentialDataSome";
import CustomLineChart from "./CustomLineChart";
import CustomStackedBarChart from "./CustomStackedBarChart";
import CustomTable from "./CustomTable";
import CurrentSelection from "./CurrentSelection";
import NoData from "./NoData";
import VisualisationDownload from "./VisualisationDownload";
import PartialData from "./PartialData";

/**
 * Visualisation page as defined in Style Spec V0.7
 * Common Properties
 * 1. ariatitle: Aria title for display.
 * 2. fileprefix: For data/chart download.
 * 3. title: Title
 * 4. isdate: true if this is timeseries.
 * 5. confidential: true to display,
 *
 * Chart Properties:
 * 1. data: Array of data for chart display. It requires date as a key
 * 2. defaulttype: "Line", or "StackedBar"
 * 3. line: Display line option if true
 * 4. lines: Array defining data sets for chart display
 * 5. stackedbar : Display stacked bar option if true
 * 6. XAxisTitle: XAxis title for chart
 * 7. YAxisTitle: XAxis title for chart
 *
 * Table Properties:
 * 1. headers: Array of headers for table display
 * 2. tabledata: Data for table
 *
 * Selection Properties:
 * 1. emissiontype
 * 2. fuel
 * 3. gas
 * 4. location
 * 5. sector
 * 6. year
 * ,
 */
export default function Visualisation(props) {
  const [confidential, setConfidential] = React.useState(props.confidential);
  const [chartType, setChartType] = React.useState(props.defaulttype);
  const [chartData, setChartData] = React.useState(props.data);
  const [digits, setDigits] = React.useState(props.digits);
  const [hasdata, setHasData] = React.useState(props.hasdata);
  const [partialdata, setPartialData] = React.useState(props.partialdata);
  const [isdate, setIsDate] = React.useState(props.isdate);
  const [line, setLine] = React.useState(props.line);
  const [lines, setLines] = React.useState(props.lines);
  const [stackedbar, setStackedBar] = React.useState(props.stackedbar);
  const [xAxisTitle, setXAxisTitle] = React.useState(props.XAxisTitle);
  const [yAxisTitle, setYAxisTitle] = React.useState(props.YAxisTitle);
  const [title, setTitle] = React.useState(props.title);
  const [units, setUnits] = React.useState(props.units);
  const [graphvisible, setGraphVisible] = React.useState(true);

  const [ariatitle, setAriaTitle] = React.useState(props.ariatitle);
  const [headers, setHeaders] = React.useState(props.headers);
  const [tabledata, setTableData] = React.useState(props.tabledata);

  const [isProjections, setIsProjections] = React.useState(props.isprojections);
  const [displaylocation, setDisplayLocation] = React.useState(props.displaylocation);
  const [displaysector, setDisplaySector] = React.useState(props.displaysector);
  const [emissiontype, setEmissionType] = React.useState(props.emissiontype);
  const [fuel, setFuel] = React.useState(props.fuel);
  const [gas, setGas] = React.useState(props.gas);
  const [location, setLocation] = React.useState(props.location);
  const [sector, setSector] = React.useState(props.sector);
  const [year, setYear] = React.useState(props.year);

  //#region Implementation of rechart-to-png
  const [getPngLine, { ref: lineref, isLoading: lineisloading }] =
    useCurrentPng();
  const [getPngStacked, { ref: stackedref, isLoading: stackedisloading }] =
    useCurrentPng();

  /**
   * Update value after changing props
   */
  React.useLayoutEffect(() => {
    setDigits(props.digits);
    setIsProjections(props.isprojections);
    setChartType(props.defaulttype);
    setChartData(props.data);
    setIsDate(props.isdate);
    setHasData(props.hasdata);
    setLine(props.line);
    setLines(props.lines);
    setStackedBar(props.stackedbar);
    setXAxisTitle(props.XAxisTitle);
    setYAxisTitle(props.YAxisTitle);
    setTitle(props.title);
    setUnits(props.units);
    setEmissionType(props.emissiontype);
    setFuel(props.fuel);
    setGas(props.gas);
    setLocation(props.location);
    setSector(props.sector);
    setYear(props.year);
    setAriaTitle(props.ariatitle);
    setTableData(props.tabledata);
    setHeaders(props.headers);
    setConfidential(props.confidential);
    setPartialData(props.partialdata);
    setDisplayLocation(props.displaylocation);
    setDisplaySector(props.displaysector);
  }, [
    props.ariatitle,
    props.confidential,
    props.defaulttype,
    props.data,
    props.digits,
    props.displaylocation,
    props.displaysector,
    props.hasdata,
    props.headers,
    props.isdate,
    props.isprojections,
    props.line,
    props.lines,
    props.stackedbar,
    props.title,
    props.units,
    props.XAxisTitle,
    props.YAxisTitle,
    props.emissiontype,
    props.fuel,
    props.gas,
    props.location,
    props.partialdata,
    props.sector,
    props.tabledata,
    props.year,
  ]);

  function displayGraph() {
    setGraphVisible(true);
  }
  function displayTable() {
    setGraphVisible(false);
  }

  const handleDownload = React.useCallback(async () => {
    const s = FilenameGenerator(props.fileprefix, "png");
    const png =
      chartType === "Line" ? await getPngLine() : await getPngStacked();
    // Verify that png is not undefined
    if (png) {
      FileSaver.saveAs(png, s);
    }
  }, [chartType, getPngLine, getPngStacked, props.fileprefix]);

  /**
   * Convert tabledata to a CSV file for download
   */
  function downloadCSV() {
    const s = FilenameGenerator(props.fileprefix, "csv");
    const csv = TableToCSV(displaylocation, displaysector, location, sector, gas, fuel, emissiontype, year, headers, tabledata);
    if (csv) {
      FileSaver.saveAs(csv, s);
    }
  }

  /**
   * Convert tabledata to an Excel file for download
   */
  function downloadExcel() {
    const s = FilenameGenerator(props.fileprefix, "xlsx");
    TableToExcel(displaylocation, displaysector, location, sector, gas, fuel, emissiontype, year, headers, tabledata).then((xlsx) => {
      if (xlsx) {
        FileSaver.saveAs(xlsx, s);
      }
    });
  }

  const handleTypeChange = (event) => {
    setChartType(event.target.value);
  };
  //#endregion

  const stackgraphtabledownload = () => {
    if (!hasdata) return null;
    return (
      <Stack
        sx={{ pb: 5 }}
        display={lineisloading || stackedisloading ? "none" : "flex"}
        direction={{
          xs: "column",
          sm: "row",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        spacing={{
          xs: 1,
          sm: 4,
          md: 4,
          lg: 78,
          xl: 78,
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            disableElevation
            sx={ graphvisible ? MStyles.buttongraphtoggleselectedstyle : MStyles.buttongraphtogglestyle }
            onClick={() => {displayGraph();}}
          >
            Graph
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={
              !graphvisible
                ? MStyles.buttongraphtoggleselectedstyle
                : MStyles.buttongraphtogglestyle
            }
            onClick={() => {
              displayTable();
            }}
          >
            Table
          </Button>
        </Grid>
        <Box>
          <Grid item>
            {/* Imports Download Data */}
            <VisualisationDownload
              graphvisible={graphvisible}
              ondownloadchart={() => handleDownload()}
              ondownloadcsv={() => downloadCSV()}
              ondownloadexcel={() => downloadExcel()}
            />
          </Grid>
        </Box>
      </Stack>
    );
  };

  // Verify this drawing component makes sense
  if (!lines) return null;
  if (!headers) return null;

  return (
    <Box sx={{ border: 2, borderColor: "#D7D7D7", pt: 5, pb: 5, pl: 5, pr: 5 }}>
      <CurrentSelection
        isprojections={isProjections}
        location={location}
        sector={sector}
        gas={gas}
        fuel={fuel}
        emissiontype={emissiontype}
        year={year}
      />

      <NoData nodata={!hasdata && !confidential} />
      <ConfidentialDataSome confidential={graphvisible && confidential && hasdata} />
      <ConfidentialDataAll confidential={confidential && !hasdata} />
      <PartialData partialdata={graphvisible && hasdata && partialdata} />

      {stackgraphtabledownload()}

      <Grid
        container
        justifyItems="start"
        sx={{
          pb: 5,
          // Display only when there is a choice of at least two.
          display:
            hasdata && graphvisible && line && stackedbar ? "block" : "none",
        }}
      >
        <Grid item>
          {/* <Typography component="span" sx={body}>View as:</Typography> */}
          <RadioGroup
            value={chartType}
            defaultValue={chartType}
            row={true}
            sx={{ alignItems: "center" }}
          >
            <Typography sx={MStyles.formlabel}>View as:</Typography>
            <FormControlLabel
              control={<Radio value="StackedBar" onChange={handleTypeChange} />}
              value="StackedBar"
              label={<Typography sx={MStyles.radiochartstyle}>Bar</Typography>}
              sx={{ display: stackedbar ? "flex" : "none" }}
            />
            <FormControlLabel
              control={<Radio value="Line" onChange={handleTypeChange} />}
              value="Line"
              label={<Typography sx={MStyles.radiochartstyle}>Line</Typography>}
              sx={{ display: line ? "flex" : "none" }}
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid
        container
        display={
          !hasdata || lineisloading || stackedisloading ? "none" : "flex"
        }
      >
        <CustomLineChart
          forwardRef={lineref}
          digits={digits}
          isdate={isdate}
          isvisible={hasdata && graphvisible && line && chartType === "Line"}
          data={chartData}
          lines={lines}
          title={title}
          units={units}
          XAxisTitle={xAxisTitle}
          YAxisTitle={yAxisTitle}
        />
        <CustomStackedBarChart
          forwardRef={stackedref}
          digits={digits}
          isdate={isdate}
          isvisible={
            hasdata && graphvisible && stackedbar && chartType === "StackedBar"
          }
          data={chartData}
          lines={lines}
          title={title}
          units={units}
          XAxisTitle={xAxisTitle}
          YAxisTitle={yAxisTitle}
        />
        <CustomTable
          isvisible={hasdata && !graphvisible}
          ariatitle={ariatitle}
          headers={headers}
          tabledata={tabledata}
          title={title}
          units={units}
        />
      </Grid>
      <Box
        sx={MStyles.progressboxstyle}
        display={
          hasdata && (lineisloading || stackedisloading) ? "flex" : "none"
        }
      >
        <CircularProgress></CircularProgress>
      </Box>
    </Box>
  );
}
