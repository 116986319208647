// React
import * as React from "react";

// MUI
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from "@mui/material/Typography";

//Classes
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Navigation breadcrumbs
 */
export default class NavBreadcrumbs extends React.Component {

  handleNavigation(page) {
    this.props.onHandleNavigation(page);
  }

  render() {
    if (this.props.page === null) return null;
    if (this.props.page === "Home") return null;
    const p = Page.list.find(x => x.page === this.props.page);
    if(!p) return null;
    const path = p.path;
    if (2 === path.length)
    {
      const t0 = Page.list.find(x => x.page === path[0]);
      const t1 = Page.list.find(x => x.page === path[1]);
      return (
      <Breadcrumbs role="navigation" aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small"/>}>
        <Typography role="link" onClick={() => {this.handleNavigation(t0.page);}} sx={MStyles.linkstyle} tabIndex="0" alt={t0.title} >{t0.title}</Typography>
        <Typography color="text.primary" aria-current="page" sx={MStyles.breadcrumb_style} alt={"Your current page is "+t1.title} >{t1.title}</Typography>
      </Breadcrumbs>
      );
    }
    if (3 === path.length)
    {
      const t0 = Page.list.find(x => x.page === path[0]);
      const t1 = Page.list.find(x => x.page === path[1]);
      const t2 = Page.list.find(x => x.page === path[2]);
      return (
      <Breadcrumbs role="navigation" aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small"/>} >
        <Typography role="link" onClick={() => {this.handleNavigation(t0.page);}} sx={MStyles.linkstyle} tabIndex="0" alt={t0.title}>{t0.title}</Typography>
        <Typography role="link" onClick={() => {this.handleNavigation(t1.page);}} sx={MStyles.linkstyle} tabIndex="0" alt={t1.title}>{t1.title}</Typography>
        <Typography color="text.primary" aria-current="page" sx={MStyles.breadcrumb_style} alt={"Click to navigate to "+t2.title}>{t2.title}</Typography>
      </Breadcrumbs>
      );
    }
    return null;
  }
}
