// React
import * as React from "react";

// MUI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";

import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Classes
import MStyles from "../classes/MStyles";
import TreeUtility from "../classes/TreeUtility";

/**
 * Single select dialog for tree data. Used for displaying hierarchies.
 * Properties
 * open : true or false to show this dialog
 * treear : data structure to be supplied to the TreeView component
 * treeraw : data structure to be supplied to the TreeView component
 * selectedar : key of the selected node
 * selectedraw : key of the selected node
 * selected:
 * title : Title to display
 * onCancel : Handle to cancel selection
 * onSave : Handle to pass new selection
 */
export default function SingleSelectGasTree(props) {
  const [israw, setIsRaw] = React.useState(false);
  const [expanded, setExpanded] = React.useState([props.selected]);
  const [selected, setSelected] = React.useState(props.selected);

  /**
   * Find all ascendants of selected nodes and expand them
   */
  React.useEffect(() => {
    if (!props.treear) return;
    if (!props.treeraw) return;

    const n_raw = TreeUtility.findinTree(props.treeraw, null, props.selected);
    const n_ar = TreeUtility.findinTree(props.treear, null, props.selected);

    const t = [];
    if (n_raw) {
      const m = TreeUtility.findAscendantsTree(props.treeraw, n_raw.id);
      for (const s of m) {
        t.push(s);
      }
      setExpanded(t);
      setIsRaw(true);
    }
    if (n_ar) {
      const m = TreeUtility.findAscendantsTree(props.treear, n_ar.id);
      for (const s of m) {
        t.push(s);
      }
      setExpanded(t);
      setIsRaw(false);
    }
  }, [props.treear, props.treeraw, props.selected]);

  /**
* Aria requirement to close dialog on escape
* @param {*} e 
*/
  function ariaescape(e) {
    if (27 === e.keyCode) {
      cancelselect();
    }
  }

  /**
   * Cancel selection without changing filters
   */
  function cancelselect() {
    props.onCancel();
  }

  function confirm() {
    props.onSave(selected);
  }

  function handleToggle(event, nodeIds) {
    setExpanded(nodeIds);
  }

  function handleSelect(event, nodeIds) {
    // Artificial nodes have code 1000 and higher
    if (999 < nodeIds) return;
    setSelected(nodeIds);
  }

  /**
   * Toggle tree display. This resets selection to deault
   * @param {*} item boolean
   */
  function selectRaw(item) {
    if (item) {
      setIsRaw(true);
      setSelected(props.defaultraw);
    } else {
      setIsRaw(false);
      setSelected(props.defaultar);
    }
  }
  /**
   * Recursively render tree nodes.
   * See for customization
   * https://smartdevpreneur.com/how-to-create-a-material-ui-treeview-with-styled-checkboxes/
   * @param {*} nodes
   * @returns
   */
  function renderTreeAR(nodes) {
    if (null == nodes) return null;
    if (Array.isArray(nodes)) {
      return nodes.map((node) => renderTreeAR(node));
    }
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <FormControlLabel
            control={<Radio />}
            id={nodes.id}
            value={nodes.name}
            checked={nodes.id === selected}
            label={
              <Typography sx={MStyles.treelabelstyle}>{nodes.name}</Typography>
            }
          />
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTreeAR(node))
          : null}
      </TreeItem>
    );
  }
  /**
   * Recursively render tree nodes.
   * See for customization
   * https://smartdevpreneur.com/how-to-create-a-material-ui-treeview-with-styled-checkboxes/
   * @param {*} nodes
   * @returns
   */
  function renderTreeRaw(nodes) {
    if (null == nodes) return null;
    if (Array.isArray(nodes)) {
      return nodes.map((node) => renderTreeRaw(node));
    } else {
      if (!nodes.confidential) {
        return (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={
              <FormControlLabel
                control={<Radio />}
                id={nodes.id}
                value={nodes.name}
                checked={nodes.id === selected}
                label={
                  <Typography sx={MStyles.treelabelstyle}>
                    {nodes.name}
                  </Typography>
                }
              />
            }
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTreeRaw(node))
              : null}
          </TreeItem>
        );
      }
      // Do not display radio if confidential
      else {
        return (
          <TreeItem key={nodes.id} nodeId={nodes.id} label={
            <FormControlLabel
              control={<Radio disabled={true} />}
              id={nodes.id}
              value={nodes.name}
              disabled={true}
              label={
                <Typography sx={MStyles.treelabelstyle}>
                  {nodes.name}
                </Typography>
              }
            />} >
            {Array.isArray(nodes.children)
              ? nodes.children.map((node) => renderTreeRaw(node))
              : null}
          </TreeItem>
        );
      }
    }
  }

  // To detect if this is a a mobile screen we need to check for sm
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Dialog open={props.open} fullScreen={!small} aria-labelledby="title" aria-describedby="title" aria-modal="true" onKeyDown={ariaescape}>
      <Box
        display="flex"
        sx={{ justifyContent: "flex-end", marginBottom: 0, paddingBottom: 0 }}
      >
        <IconButton
          onClick={() => {
            cancelselect();
          }}
        >
          <CloseIcon titleAccess="Close dialog" />
        </IconButton>
      </Box>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1" sx={MStyles.h1} id="title">
              {props.title}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2" sx={MStyles.h2}>
          Select a category
        </Typography>
        <Typography paragraph sx={MStyles.body} id="description">Select a category to view either the raw gas or the carbon dioxide equivalent.</Typography>
        <RadioGroup defaultValue="AR">
          <FormControlLabel
            control={<Radio />}
            value="AR"
            label={
              props.isAR5
                ? "Carbon Dioxide Equivalent - AR5"
                : "Carbon Dioxide Equivalent - AR4"
            }
            onClick={(x) => selectRaw(false)}
            checked={!israw}
          />
          <FormControlLabel
            control={<Radio />}
            value="RAW"
            label="Raw Gas"
            onClick={(x) => selectRaw(true)}
            checked={israw}
          />
        </RadioGroup>
        <Typography variant="h3" sx={MStyles.h3}>
          Select gas
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Select a gas to view emissions. Only one gas can be selected at a time.
          {props.isProjection && (<> Data for synthetic gases is not available as raw gas.</>) }
        </Typography>
        <Box display={israw ? "box" : "none"}>
          <TreeView
            aria-label={props.title}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            disabledItemsFocusable={true}
            expanded={expanded}
            multiSelect={false}
            selected={selected}
            sx={{ flexGrow: 1 }}
            onNodeSelect={(event, nodeIds) => {
              handleSelect(event, nodeIds);
            }}
            onNodeToggle={(event, nodeIds) => {
              handleToggle(event, nodeIds);
            }}
          >
            {renderTreeRaw(props.treeraw)}
          </TreeView>
        </Box>
        <Box display={israw ? "none" : "box"}>
          <TreeView
            aria-label={props.title}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            multiSelect={false}
            selected={selected}
            sx={{ flexGrow: 1 }}
            onNodeSelect={(event, nodeIds) => {
              handleSelect(event, nodeIds);
            }}
            onNodeToggle={(event, nodeIds) => {
              handleToggle(event, nodeIds);
            }}
          >
            {renderTreeAR(props.treear)}
          </TreeView>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.linkbutton}
          onClick={() => {
            cancelselect();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.buttondimstyle}
          onClick={() => {
            confirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
