export default class MStyles {
  /*
   * ==================================================
   * DISER Global Stylesheet
   * ==================================================
   */
  //#region Header

  /**
   * See Guide v0.5 3.2	Main menu details (Creator: Brad)
   * Note: This is a work in progress.
   */
  static menuitem = {
    color: "#00283E",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "bold",

    display: "block",
    borderRadius: 0,
    alignItems: "center",
    marginBottom: "-10px",

    "&:hover": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
    },
    "&:focus": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
      border: "2px solid #9263DE",
    },
    "&:focus-visible": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
      border: "2px solid #9263DE",
    },
  };

  static menuitemselected = {
    color: "#00283E",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "bold",
    display: "block",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 0,
    borderColor: "#FFFFFF",
    borderBottom: "10px solid #FFFFFF",
    marginBottom: "-10px",

    "&:hover": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
      backgroundColor: "#DAF4F7",
      border: "2px solid #9263DE",
    },
    "&:focus-visible": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
      border: "2px solid #9263DE",
    },
  };

  static menuitemmobile = {
    color: "#00283E",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "bold",
    minwidth: "343px",
    display: "block",
    borderRadius: 0,
    alignItems: "center",
    borderBottom: 2,
    borderColor: "#888888",
    "&:hover": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
    },
    "&:focus": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
      border: "2px solid #9263DE",
    },
    "&:focus-visible": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
      border: "2px solid #9263DE",
    },
  };

  static menuitemselectedmobile = {
    color: "#00283E",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "bold",

    borderBottom: 2,
    borderColor: "#888888",

    minwidth: "343px",

    display: "block",
    backgroundColor: "#FFFFFF",
    borderRadius: 0,

    "&:hover": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
      backgroundColor: "#DAF4F7",
      // border: "2px solid #9263DE",
      outline: "2px solid #9263DE",

    },
    "&:focus-visible": {
      backgroundColor: "#DAF4F7",
      textDecoration: "underline",
      //border: "2px solid #9263DE",
      outline: "2px solid #9263DE",
    },
  };

  static menumobilebox = {
    alignItems: "center",
    // backgroundColor: "#FFFFFF",
    borderTop: "10px solid #00283E",
    maxWidth: "400",
  };

  static navbox = {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderBottom: "10px solid #00283E",
  };

  static navstackdesktop = {
    width: "100%",
    display: { xs: "none", md: "flex" },
  };

  static headerlink = {
    color: "#00283E",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "bold",
  };

  //#endregion

  //#region Typography
  /**
   * Typography Section 4.0-4.4 Style Guide V1.2
   */
  static header_title = {
    color: "#005677",
    fontWeight: "bold",
    paddingTop: 1,
    fontSize: "32px",
    fontFamily: "Public Sans, Arial, Segoe",
  };

  static h1_title = {
    color: "#005677",
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: [24, 38],
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static h1 = {
    color: "#005677",
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: [24, 38],
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static h2 = {
    color: "#333333",
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: [21, 28],
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static h2_search = {
    color: "#333333",
    paddingTop: 3,
    marginBottom: 3,
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: [21, 28],
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static h3 = {
    variant: "h3",
    color: "#00283E",
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: [18, 22],
    fontFamily: "Public Sans, Arial, Segoe",
    fontWeight: "bold",
  };

  static h3_activity = {
    variant: "h3",
    color: "#00283E",
    paddingTop: 1,
    paddingLeft: 2,
    paddingBottom: 1,
    fontSize: [18, 22],
    fontFamily: "Public Sans, Arial, Segoe",
    fontWeight: "bold",
  };

  static h4 = {
    variant: "h4",
    color: "#333333",
    paddingTop: 0,
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: [16, 18],
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static h5 = {
    variant: "h4",
    color: "#333333",
    paddingTop: 0,
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: [16, 18],
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static body = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
    marginBottom: 2,
  };

  static version = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 10,
    fontStyle: "normal",
    marginBottom: 2,
  };

  static warning = {
    color: "#333333",
    fontWeight: "bold",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
  };

  static formlabel = {
    align: "center",
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
    pr: 2,
  };

  //#endregion

  //#region Navigation

  /**
   * Skip links there is no design at this stage.
   */
  static skiplink = {
    background: "white",
    border: "none",
    clip : "rect(1px, 1px, 1px, 1px)",
    color: "white", 
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "bold",
    height: "45px",
    outline: "none",  
    overflow: "hidden",
    paddingTop: "15px",
    position: "absolute !important",
    textAlign: "center",
    width: "100%",
    "&:active" : {      
        background: "#416384",
        clip: "auto",
        overflow: "visible",
      },
    "&:focus" : {
        background: "#416384",
        clip: "auto",
        overflow: "visible",
    }
  }

  /**
   * See Guide v0.7 Section 6.8 and 6.9. Applies to links and to Breadcrumbs
   */
  static linkstyle = {
    my: 2,
    fontFamily: "Public Sans, Arial, Segoe",
    textDecoration: "underline",
    color: "#005677",
    "&:hover": {
      textDecoration: "none",
      cursor: "allowed",
    },
    "&:focus": {
      textDecoration: "none",
      borderRadius: 0,
      border: 2,
      borderColor: "#9263DE",
    },
  };

  static logostyle = {
    "&:hover": {
      cursor: "allowed",
    },
    "&:focus": {
      borderRadius: 0,
      border: "2px solid #FFFFFF",
      outline: "2px solid #9263DE",
    },
  };

  static breadcrumb_style = {
    color: "#005677",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
    cursor: "pointer",
  };

  //#endregion

  //#region Footer
  /**
   * See Guide v0.4 Section 3.4 Footer
   */
  static footerbox = {    
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#343434",
    pt: 3,
    pb: 5,
    mt: 5,
    mb: 0,
  };

  static footer = {
    justifyContent: "start",
    alignItems: {
      xs: "left",
      sm: "center",
    },
    backgroundColor: "#343434",
    pt: 2,
    pb: 2,
    pl: 1,
    pr: 1,
    mb: 0,
  };

  static footerbottom = {
    alignItems: {
      xs: "center",
      sm: "flex-start",
    },
    backgroundColor: "#343434",
    pt: 0,
    pb: 0,
    pl: 1,
    pr: 1,
  };

  /**
   * See Guide v0.5 Section 3.3 Footer
   */
  static footerlinks = {
    fontFamily: "Public Sans, Arial, Segoe",
    fontWeight: "bold",
    fontSize: 16,
    color: "white",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
    "&:active": {
      border: 2,
      borderColor: "#9263DE",
    },
    "&:focus": {
      border: 2,
      borderColor: "#9263DE",
    },
    "&:focus-visible": {
      outline: "0px solid #9263DE",
    },
  };

  /**
   * See Guide v0.5 Section 3.3 Footer
   */
  static footerlinkscopyrights = {
    fontFamily: "Public Sans, Arial, Segoe",
    fontWeight: "bold",
    fontSize: 14,
    color: "white",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
    "&:active": {
      border: 2,
      borderColor: "#9263DE",
    },
    "&:focus": {
      border: 2,
      borderColor: "#9263DE",
    },
    "&:focus-visible": {
      outline: "0px solid #9263DE",
    },
  };

  static footertext = {
    color: "#FFFFFF",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 11,
    fontStyle: "normal",
    fontWeight: "normals",
  };

  //#endregion

  /**
   * See Guide v0.5 Section 6.12 Cards on home page (Creator: Brad)
   */
  static pagetitle = {
    color: "#005677",
    fontSize: 36,
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Public Sans, Arial, Segoe",
  };

  //#region Home Page
  /**
   * See Guide v0.5 Section 6.11 Hero banner - home page
   */
  static banner = {
    color: "#ffffff",
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    fontSize: 28,
    fontFamily: "Public Sans, Arial, Segoe",
  };

  static cardcontent = {
    height: {xs : 220, sm: 290, md: 220}
  };

  static cardtitle = {
    color: "#005677",
    fontSize: 22,
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Public Sans, Arial, Segoe",
    marginBottom: 2,
  };

  static hpc1 = {
    borderTop: "10px solid #61C6C6",
    "&:hover": {
      backgroundColor: "#F2FFFD",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus": {
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus-visible": {
      backgroundColor: "#F2FFFD",
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
  };

  static hpc2 = {
    borderTop: "10px solid #C973AF",
    "&:hover": {
      backgroundColor: "#FFF5FC",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus-visible": {
      backgroundColor: "#F2FFFD",
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus": {
      backgroundColor: "#F2FFFD",
      outline: "2px solid #9263DE",
      "& div > span": { textDecoration: "underline" },
    },
  };

  static hpc3 = {
    borderTop: "10px solid #CCDAE1",
    "&:hover": {
      backgroundColor: "#F2F2F2",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus": {
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus-visible": {
      backgroundColor: "#F2FFFD",
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
  };

  static hpc4 = {
    borderTop: "10px solid #9CD9E0",
    "&:hover": {
      backgroundColor: "#F5FBFC",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus": {
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
    "&:focus-visible": {
      backgroundColor: "#F2FFFD",
      outline: "2px solid #9263DE",
      "& div > h3": { textDecoration: "underline" },
    },
  };

  static hpcheader = {
    variant: "h3",
    color: "#00283E",
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: [18, 22],
    fontFamily: "Public Sans, Arial, Segoe",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
    },
    "&:focus-visible": {
      textDecoration: "underline",
    },
  };
  //#endregion

  //#region Normal Card
  static normalcardheaderstyle = {
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: "28",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 0,
    paddingBottom: 0,
  };

  static normalcardstyle = {
    borderRadius: "0",
    borderTop: "8px solid #135DA6",
    height: 250,
    width: 295,
    borderLeft: "1px solid #D7D7D7",
    borderBottom: "6px solid #D7D7D7",
    borderRight: "1px solid #D7D7D7",
    "&:hover": {
      backgroundColor: "#DAF4F7",
      cursor: "pointer",
      "& div > span": { textDecoration: "underline" },
    },
    "&:focus": {
      outline: "2px solid #9263DE",
      backgroundColor: "#DAF4F7",
      "& div > span": { textDecoration: "underline" },
    },
    "&:focus-visible": {
      outline: "2px solid #9263DE",
      backgroundColor: "#DAF4F7",
      "& div > span": { textDecoration: "underline" },
    },
  };

  static normalcardgridstyle = {
    height: 250,
    minHeight: 250,
    maxHeight: 250,
    marginTop: 1,
    marginLeft: 1,
    marginBottom: 3,
    marginRight: 2,
    paddingTop: 0,
    paddingBottom: 0,
    poaddingLeft: 0,
    poaddingRight: 0,
  };

  //#endregion

  //#region Table

  /**
   * Table header
   * Email from Bree 4/April/2022
   */
  static tableheadercell = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "bold",
    textAlign: "left",
  };

  /**
   * Table cell
   * Email from Bree 4/April/2022
   */
  static tablecell = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "normal",
  };

  /**
   * Table
   * Email from Bree 4/April/2022
   */
  static tablebodystyle = {
    borderBottom: 1,
    borderTop: 1,
    // "&:last-child td" : {border: 0 },
    // "&:last-child th": { border: 0 },
    "& > tr:nth-of-type(even)": { backgroundColor: "#F5F5F5" },
  };

  /**
   * Table. Appears in the images but not specified
   */
  static tableheadstyle = {
    borderBottom: 2,
    borderColor: "#333333",
  };

  //#endregion

  //#region Charts

  /**
   * Chart colour array. Bree email 4/April/2022
   * Style Guide v0.7 Section 5.3
   */
  static chartcolours = [
    "#0095A8",
    "#112E51",
    "#78909C",
    "#5D2818",
    "#5792DB",
    "#2E3094",
    "#FF5724",
    "#691C66",
    "#949494",
    "#242424",
    "#108E8E",
    "#66003C",
    "#CE45C9",
    "#420042",
    "#FF38AC",
    "#063F47",
  ];

  /**
   * Chart colour array. Bree email 4/April/2022
   * Style Guide v0.7 Section 5.3
   */
  static linestrokedash = [
    "",
    "5 5",
    "3 1",
    "2 2",
    "6 1",
    "4 1 1 1 1",
    "5 5 1 5",
    "1 2 4",
    "4 2 4",
    "4 2 4",
    "4 2 4 1 1",
    "3 2 3",
    "7 1",
    "2 1",
    "3 1 3",
    "2 2 1 2 1",
  ];

  static radiochartstyle = {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Public Sans, Arial, Segoe",
    alignItems: "center",
    verticalAlign: "middle",
  };

  static chartmargin = {
    top: 10, right: 10, left: 10, bottom: 10
  }
  //#endregion


  /**
 * Styling for button used for selection of dimensions.
 * See Guide v0.7 Section 6.7 point 3
 */
  static buttongraphtoggleselectedstyle = {
    alignitems: "center",
    backgroundColor: "#F2F2F2",
    borderColor: "#135DA6",
    border: 2,
    borderRadius: 0,
    color: "#333333",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Public Sans, Arial, Segoe",
    height: 40,
    width: "110px",
    padding: 2.8,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#F2F2F2",
      textDecoration: "underline",
    },
    "&:focus": {
      borderColor: "#135DA6",
      textDecoration: "underline",
      outline: "2px solid #9263DE",
    },
  };

  static buttongraphtogglestyle = {
    alignitems: "center",
    backgroundColor: "#135DA6",
    border: 2,
    borderColor: "#135DA6",
    borderRadius: 0,
    color: "#FFFFFF",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Public Sans, Arial, Segoe",
    height: 40,
    padding: 2.8,
    width: "105px",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      borderColor: "#135DA6",
      textDecoration: "underline",
      outline: "2px solid #9263DE",
    },
  };

  static buttonhidden = {
    display: "none",
  };

  //#region Dimension Selection

  /**
   * Styling for button used for selection of dimensions.
   * See Guide v0.7 Section 6.7 point 1
   */
  static buttondimstyle = {
    alignitems: "center",
    backgroundColor: "#005677",
    borderRadius: 1,
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Public Sans, Arial, Segoe",
    height: 40,
    padding: 2.8,
    textTransform: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      border: "2px solid #FFFFFF",
      outline: "2px solid #9263DE",
      textDecoration: "underline",
    },
  };

  static hidestyle = {
    display: "none",
  };

  static dimensionboxstyle = {
    border: 2,
    borderColor: "#D7D7D7",
    pt: 2,
    pb: 2,
    pl: 2,
    pr: 2,
    mb: 2,
  };

  static dimensionboxmessagestyle = {
    backgroundColor: "#EAEDF4",
    borderLeft: "5px solid #2E5299",
    color: "#333333",
    mb: "10px",
    mr: "10px"
  };

  static dimensionboxwarningmessagestyle = {
    backgroundColor: "#FBEEE5",
    borderLeft: "5px solid #DC5800",
    color: "#333333",
    mb: "10px",
    mr: "10px"
  };


  //#endregion

  //#region Accordion
  static accordion = {
    borderTop: "1px solid #000000",
    borderBottom: "1px solid #000000",
    borderLeft: 0,
    borderRight: 0,
    padding: "1px",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
  };

  static accordionsummary = {
    "&:hover": {
      backgroundColor: "#DAF4F7",
    },
    "&:active": {
      backgroundColor: "#DAF4F7",
    },
    "&:focus": {
      border: "2px solid #9263DE",
    },
    "&:focus-visible": {
      border: "2px solid #9263DE",
    },
  };

  //#endregion

  //#region Progress Box. Need to check with Bree
  static progressboxstyle = {
    border: 2,
    borderColor: "#D7D7D7",
    pt: 5,
    pb: 5,
    pl: 5,
    pr: 5,
    justifyContent: "center",
  };
  //#endregion

  //#region Glossary No specified styling guide as yet.
  static glossary = {
    display: {
      xl: "flex",
      lg: "flex",
      md: "flex",
      sm: "flex",
      xs: "flex-wrap",
    },
    height: 50,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff",
    pt: 0,
    pb: 0,
    mb: 0,
  };

  static glossarylist = {
    fontFamily: "Public Sans, Arial, Segoe",
    // fontWeight: "bold",
    fontSize: 16,
    // color: "#333333",
  };

  static glossarylinks = {
    fontFamily: "Public Sans, Arial, Segoe",
    fontWeight: "bold",
    fontSize: 16,
    // color: "#333333",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
    "&:active": {
      border: 2,
      borderColor: "#9263DE",
    },
    "&:focus": {
      border: 2,
      borderColor: "#9263DE",
    },
  };

  static glossarynolinks = {
    fontFamily: "Public Sans, Arial, Segoe",
    // fontWeight: "bold",
    fontSize: 16,
    color: "#333333",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      cursor: "default",
    },
  };
  //#endregion

  //#region Dialog
  /**
   * Style guide v0.9 Section 7.15
   */
  static dialogboxstyle = {
    backgroundColor: "#00000099",
    height: { xs: "100%", sm: "500" },
    width: { xs: "100%", sm: "500" },
    maxHeight: { xs: "100%", sm: "500" },
    maxWidth: { xs: "100%", sm: "500" },
  };
  /**
   * Style guide v1.0 Section 6.8 (B)
   */
  static dialogwarning = {
    backgroundColor: "#FBEEE5",
    color: "#333333",
    display: "flex",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "bold",
  };

  static dialogwarninghide = {
    display: "none",
  };

  // Section 6.48
  static linkbutton = {
    alignitems: "center",
    backgroundColor: "#FFFFFF",
    border: 0,
    color: "#005677",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Public Sans, Arial, Segoe",
    height: 40,
    padding: 2.8,
    textDecoration: "underline",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
    },
    "&:focus": {
      backgroundColor: "#FFFFFF",
      border: 2,
      borderColor: "#9263DE",
      textDecoration: "none",
    },
  };

  static clearselectionbutton = {
    alignitems: "right",
    backgroundColor: "#FFFFFF",
    border: 0,
    color: "#005677",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Public Sans, Arial, Segoe",
    height: 40,
    padding: 2.8,
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
    },
    "&:focus-visible": {
      backgroundColor: "#FFFFFF",
      border: 2,
      borderColor: "#9263DE",
      textDecoration: "none",
    },
  };

  static treelabelstyle = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
  };

  static treelabeldisabledstyle = {
    color: "#999999",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
  };

  static searchbutton = {
    alignitems: "center",
    border: 0,
    background: "transparent",
    color: "#005677",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Public Sans, Arial, Segoe",
    textDecoration: "underline",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
    },
    "&:focus": {
      backgroundColor: "#FFFFFF",
      border: 2,
      borderColor: "#9263DE",
      textDecoration: "none",
    },
  };
  //#endregion

  /**
   * Tab Style
   */

  static tabstyle = {
    color: "#005677",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontWeight: "normal",
    textTransform: "capitalize",
    mr: 2,
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus-visible": {
      border: "2px solid #9263DE",
      textDecoration: "none",
    },
  };

  /**
   * Download Data Button Style
   */
  static downloaddatabutton = {
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    color: "#333333",
    textTransform: "none",
    backgroundColor: "#F2F2F2",
    marginTop: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 },
    width: "215px",
    height: "50px",
    borderRadius: 0,
    "&:active": {
      background: "#F2F2F2",
    },
    "&:focus": {
      outline: "2px solid #9263DE",
      textDecoration: "underline",
    },
  };

  /**
   * Search Form Style
   */
  static buttonprops = {
    backgroundColor: "#005677",
    borderRadius: 0,
    alignitems: "center",
    textTransform: "capitalize",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "bold",
    height: 40,
    paddingLeft: 2.8,
    paddingRight: 2.8,
    "&:hover": {
      backgroundColor: "#005677",
      textDecoration: "underline",
    },
    "&:focus": {
      border: "2px solid #FFFFFF",
      outline: "2px solid #9263DE",
      textDecoration: "underline",
    },
    "&:focus-visible": {
      border: "2px solid #FFFFFF",
      outline: "2px solid #9263DE",
      textDecoration: "underline",
    },
  };

  /**
   * Search Form Style
   */

  static inputbaseprops = {
    border: 1,
    paddingtop: 1,
    borderRadius: 0,
    ml: 1,
    pl: 1,
    width: "100%",
    height: "40px",
    "&:active": {
      border: "2px solid #FFFFFF",  
      outline: "2px solid #9263DE",
    },
    "&:focus": {
      border: "2px solid #FFFFFF",
      outline: "2px solid #9263DE",
    },
  };

  //#region List formats
  static unorderedlist = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
  };

  static unorderedlistelement = {
    color: "#333333",
    fontFamily: "Public Sans, Arial, Segoe",
    fontSize: 16,
    paddingBottom: "15px",
  };
  //#endregion

  //#region Data Footnote

  static datafootnotebox = {
    backgroundColor: "#EFF5FB",
    mt: "15px",
    pl: 1,
    pr: 1,
    pt: 1,
    pb: 1
  }
  //#endregion
}
