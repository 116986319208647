// React
import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Components
import NormalCard from "./NormalCard";

// Classes
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Display data sets and API navigation page
 */
export default class DataSetAPI extends React.Component {
  page = Page.DataSetAPI;
  cards = [
    {
      page: Page.Compiled.page,
      header: "API (bulk data download)",
      text: "Access a feed to query data using PowerPivot for Excel, PowerQuery for Excel, Excel Add-In, Drupal, JBoss, Joomla, Power BI, Tableau or Spotfire.",
    },
    {
      page: Page.Activity.page,
      header: "Activity tables",
      text: "Tables which present activity data used to derive emission estimates.",
    },
  ];

  handleNavigation = (page) => {
    this.props.onHandleNavigation(page);
  };

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-center"
          sx={{marginTop: 0, marginBottom:1, marginRight:1, marginLeft:1, paddingTop:1, paddingBottom:1, paddingLeft:1, paddingRight:1 }}
        >
          {this.cards.map((row) => (
            <NormalCard
              key={row.page}
              page={row.page}
              header={row.header}
              text={row.text}
              onHandleNavigation={() => {
                this.handleNavigation(row.page);
              }}
            />
          ))}
        </Grid>
      </div>
    );
  }
}
