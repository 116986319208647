// React
import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";

/**
 * No data message
 */
export default function ConfidentialDataSome(props) {
  const [nd, setND] = React.useState(props.confidential);

  React.useEffect(() => {
    setND(props.confidential);
  }, [props.confidential]);

  if(!nd) return null;
  return (
    <Alert
      severity="info"
      icon={<ErrorIcon fontSize="large" sx={{ color: "#DC5800" }} />}
      sx={MStyles.dimensionboxwarningmessagestyle}
      display={"flex"}
    >
      <AlertTitle>
        <Typography component="h4" sx={MStyles.warning}>
          Confidential data - can not be displayed
        </Typography>
      </AlertTitle>
      <Typography paragraph sx={MStyles.body}>
        The data for some sectors is confidential.  A chart for the non-confidential sectors is displayed.
      </Typography>
    </Alert>
  );
}
