// React
import * as React from "react";

// MUI
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputBase from "@mui/material/InputBase";

// Classes
import MStyles from "../classes/MStyles";

/**
 * Search component in the upper right corner of header
 * @param {*} props 
 * @returns 
 */
export default function Search(props) {
  const [searchterm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    setSearchTerm(props.searchterm);
  }, [props.searchterm]);

  const searchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchClicked = () => {
    props.onHandleSearch(searchterm);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      role="search"
    >
      <InputBase
        id="search"
        type="search"
        placeholder="Search term"
        size="medium"
        onChange={searchChange}
        sx={MStyles.inputbaseprops}
        tabIndex={0}
      />
      <Button
        variant="contained"
        disableElevation
        disableRipple
        disableTouchRipple
        sx={MStyles.buttonprops}
        onClick={searchClicked}
      >
        Search
      </Button>
    </Stack>
  );
}
