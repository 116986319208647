// React
import * as React from "react";

// MUI
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Display contact page
 */
export default class Contact extends React.Component {
  page = Page.Contact;
  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
        <div>
          <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
          <Typography paragraph sx={MStyles.body}>
            If you are unable to find answers to your questions in our glossary or frequently asked questions, the following email addresses can be used to send us an email with your inquiry. 
          </Typography>
          <Typography paragraph sx={MStyles.body}>
            For questions about the emissions inventory, contact {" "}
          <Link 
            href={ExternalURL.MAILTO.url} 
            sx={MStyles.linkstyle} 
            rel="noreferrer"
            title="Contact email link"
            alt="Contact email link"
            >{ExternalURL.MAILTO.text} </Link>
          </Typography>
          <Typography paragraph sx={MStyles.body}>
            For questions about emissions projections, contact {" "}  
          <Link 
            href={ExternalURL.MAILTOPROJECTIONS.url} 
            sx={MStyles.linkstyle} 
            rel="noreferrer"
            title="Contact email link"
            alt="Contact email link"
            >{ExternalURL.MAILTOPROJECTIONS.text} </Link>
          </Typography>
        </div>
    );
  }
}
