// React
import * as React from "react";

// Typography
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Classes
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

/**
 * Home page
 */
export default class Home extends React.Component {
  page = Page.Home;

  state = {
    selectionPage: "",
  };

  handleNavigation = (page) => {
    this.setState({ selectionPage: page });
    this.props.onHandleNavigation(page);
  };

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div className="Home">
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>

        <Typography paragraph sx={MStyles.body}>
          Welcome to Australia&apos;s National Greenhouse Accounts. This website
          provides detailed greenhouse gas emissions data since 1990 and
          projections of Australia&apos;s future emissions.
        </Typography>

        <Box
          sx={{
            mb: 3,
            pl: 1.5,
            pt: 0.5,
            pb: 1.5,
            backgroundColor: "#005677",
          }}
        >
          <Typography variant="h2" sx={MStyles.banner}>
            Data on Australia&apos;s greenhouse gas emissions.
          </Typography>
        </Box>

        <Grid
          container
          spacing={10}
          direction="row"
          justify="flex-start"
          alignItems="flex-center"
        >
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              tabIndex="0"
              sx={MStyles.hpc2}
              id="card2"
              onClick={() => {
                this.handleNavigation("EmissionsInventories");
              }}
            >
              <CardContent sx={MStyles.cardcontent}>
                <Typography variant="h3" sx={MStyles.hpcheader}>
                  Emissions inventories
                </Typography>
                <Typography sx={MStyles.body}>
                  Australia&apos;s historical greenhouse gas emissions data. Australia uses this
                  data to track progress towards national emissions
                  reduction targets. View emissions by economic sector and by state and territory.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              sx={MStyles.hpc3}
              tabIndex="0"
              onClick={() => {
                this.handleNavigation("EmissionsProjections");
              }}
            >
              <CardContent sx={MStyles.cardcontent}>
                <Typography variant="h3" sx={MStyles.hpcheader}>
                  Emissions projections
                </Typography>
                <Typography sx={MStyles.body}>
                  Projections of Australia&apos;s future greenhouse gas
                  emissions. Australia uses this data to track progress towards national emissions reduction targets.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              sx={MStyles.hpc1}
              tabIndex="0"
              onClick={() => {
                this.handleNavigation("About");
              }}
            >
              <CardContent sx={MStyles.cardcontent}>
                <Typography variant="h3" sx={MStyles.hpcheader}>
                  About this website
                </Typography>
                <Typography sx={MStyles.body}>
                  Find out more about the National Greenhouse Accounts.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              sx={MStyles.hpc4}
              tabIndex="0"
              onClick={() => {
                this.handleNavigation("HelpSupport");
              }}
            >
              <CardContent sx={MStyles.cardcontent}>
                <Typography variant="h3" sx={MStyles.hpcheader}>
                  Help and support
                </Typography>
                <Typography sx={MStyles.body}>
                  Find answers to FAQs and a glossary of terms.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}
