// React
import * as React from "react";

//MUI
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Classes
import MStyles from "../classes/MStyles";
import { Typography } from "@mui/material";

/**
 * Custom table
 * @param {*} props     
 *  props.ariatitle - ARIA title
 *  props.header - Array of headers  {key: "date", label: "Year", align: "left"}, key corresponds to attribute in the row
 *  props.isvisible - if false return null
 *  props.tabledata - Table of data. Each row should contain all key attributes from headers.
 * @returns Generates table display
 */
export default function CustomTable(props) {    
  if (!props.isvisible) return null;

  return (
    <Grid item xs={12} >
      <TableContainer sx={{ maxWidth : "fit-content"}}>
        <Table aria-label={props.ariatitle} stickyHeader>
          <caption style={{"captionSide": "top"}}><Typography variant="h4" sx={MStyles.h4}>{props.title}</Typography></caption>
          <TableHead sx={MStyles.tableheadstyle}>
            <TableRow>
              {props.headers.map((header) => (
                <TableCell
                  align={header.align}
                  component="th"
                  sx={MStyles.tableheadercell}
                  key={header.key}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={MStyles.tablebodystyle}>
            {props.tabledata.map((row) => (
              <TableRow key={row[props.headers[0].key]}>
                {props.headers.map((header) => (
                  <TableCell
                    align={header.align}
                    component="td"
                    sx={MStyles.tablecell}
                    key={row[props.headers[0].key] + ":" + header.key}
                  >
                    {row[header.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
