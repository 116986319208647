export default class Messages {
    static CUSTOMSELECTION = "Emissions for values you selected";
    static DEFAULTSELECTION = "Emissions for default values";

    static LOCATIONCOUNTERROR = "No location was selected. At least one location must be selected.";
    static LOCATIONINFO = ["Select one or more locations."];

    static SECTORCOUNT = "You can select upto 16 sectors at a time.";
    static SECTORCOUNTERROR = "The maximum number of sectors has been reached.";

    static SECTOR_BASELINE_INFO = ["Some subsectors are not available due to data constraints."];
    static SECTOR_ECONOMIC_INFO = [
        "Maximum 15 sectors can be selected at a time. Some subsectors are not available for certain locations due to confidentiality constraints.",
        "The selection will return to the default value Total of all Economic (ANZSIC) sectors if the location is changed."
    ];

    static SECTOR_KP_INFO = [
        "Maximum 15 sectors can be selected at a time. Some subsectors are not available for certain locations due to confidentiality constraints."
    ];

    static SECTOR_PARIS_INFO = [
        "Maximum 15 sectors can be selected at a time. Some subsectors are not available for certain locations due to confidentiality constraints.",
        "The selection will return to the default value Total UNFCCC if the location is changed."
    ];

    static SECTOR_MEMO_INFO = [
        "Maximum 15 sectors can be selected at a time. Some subsectors are not available for certain locations due to confidentiality constraints.",
        "The selection will return to the default value Memo Items if the location is changed."
    ];

    static SECTOR_SCOPE2_INFO = [
        "Some subsectors are not available for certain locations due to confidentiality constraints.",
        "The selection will return to the default value Total of all Economic (ANZSIC) sectors if the location is changed."
    ];

    static SECTOR_UNFCCC_INFO = [
        "Select up to 15 sectors at a time. Some subsectors are not available for certain locations due to confidentiality constraints.",
        "The selection will return to the default value Total UNFCCC if the location is changed."
      ];

    static SECTORCOUNTERROR1 = "No sectors were selected. At least one sector must be selected.";
    static SECTORINFO = "Use Ctrl+Click for multiple selection and Ctrl+Shift+Click for selection range.";

    static SEARCH_TERM_NOT_VALID = "The search term entered is not valid. A valid search term may contain from 4 to 32 alphanumeric characters and white space.";
    static SEARCH_TERM_EMPTY = "No search term has been entered.";
    static SEARCH_TERM_NO_RESULTS = "No search results were found.";
    static SEARCH_TERM_NO_RESULTS_NIR = "No search results were found for emissions.";
    static SEARCH_TERM_NO_RESULTS_PROJECTIONS = "No search results were found for projections.";
}
