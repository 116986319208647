// React
import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

// Classes
import Messages from "../classes/Messages";
import MStyles from "../classes/MStyles";
import TreeUtility from "../classes/TreeUtility";

/**
 * Multiple select dialog for tree data. Used for displaying hierarchies.
 * Properties
 * open : true or false to show this dialog
 * tree : data structure to be supplied to the TreeView component
 * selected : key of the selected node
 * title : Title to display
 * onCancel : Handle to cancel selection
 * onSave : Handle to pass new selection
 */
export default function MultipleSelectLocationTree(props) {
  const [expanded, setExpanded] = React.useState(
    Array.isArray(props.expanded) ? props.expanded : [props.expanded]
  );
  const [selected, setSelected] = React.useState(
    Array.isArray(props.selected) ? props.selected : [props.selected]
  );

  const [error, setError] = React.useState("");
  const [info, setInfo] = React.useState(Messages.LOCATIONINFO);
  const [notvalid, setNotValid] = React.useState(false);
  const timer = React.useRef();

  /**
 * Aria requirement to close dialog on escape
 * @param {*} e 
 */
  function ariaescape(e) {
    if (27 === e.keyCode) {
      cancelselect();
    }
  }

  function cancelselect() {
    setError("");
    setInfo(Messages.LOCATIONINFO);
    setNotValid(false);
    props.onCancel();
  }

  /**
   * Clear selection see Style Guid section 6.48
   */
  function clear() {
    setSelected([]);
    setExpanded([]);
    setError(Messages.LOCATIONCOUNTERROR);
    setInfo("");
    setNotValid(true);
  }

  function confirm() {
    props.onSave(selected);
  }

  /**
   * We are manipulating expansions on icons.
   * Se make sure that this function does nothing
   */
  function handleToggle(event, nodeIds) {
    return;
  }

  /**
   * Single click for a label
   * @param {*} id
   */
  function singleClick(id) {
    const i = selected.indexOf(id);
    const t = [];
    // It is currently selected item
    if (-1 < i) {
      for (const s of selected) {
        if (s !== id) {
          t.push(s);
        }
      }
    }
    // It is not currently selected
    else {
      for (const s of selected) {
        t.push(s);
      }
      t.push(id);
    }
    // Pass to validation
    validateSelection(t);
  }

  /**
   * Double click on a label
   * @param {*} id
   */
  function doubleClick(id) {
    const parentandchildren = [];
    const n = TreeUtility.findinTree(props.tree, null, id);
    if (n) {
      for (const c of n.children) {
        parentandchildren.push(c.id);
      }
      parentandchildren.push(id);
    }

    const i = selected.indexOf(id);
    // It is currently selected item
    if (-1 < i) {
      const a = [];
      for (const s of selected) {
        if (-1 === parentandchildren.indexOf(s)) a.push(s);
      }
      // Pass to validation
      validateSelection(a);
    }
    // It is not currently selected
    else {
      for (const s of selected) {
        parentandchildren.push(s);
      }
      validateSelection(parentandchildren);
    }
  }

  /**
   * Manual handler to differentiate a single click from double click
   * @param {*} event
   * @param {*} id
   * @param {*} count
   */
  function labelClick(event, id, count) {
    clearTimeout(timer.current);
    event.bubbles = false;
    if (event.detail === 1) {
      timer.current = setTimeout(singleClick(id), 500);
    } else if (event.detail === 2) {
      doubleClick(id);
    }
  }

  /**
   * Verification of selection
   * @param {*} tobeselected
   * @returns
   */
  function validateSelection(tobeselected) {
    const nodes = [];

    for (const id of tobeselected) {
      const n = TreeUtility.findinTree(props.tree, null, id);
      if (n) {
        nodes.push(n.id);
      } else {
        console.error("ERROR: Not found", id);
      }
    }

    // Nothing left
    if (nodes.length === 0) {
      setSelected([]);
      setError(Messages.LOCATIONCOUNTERROR);
      setInfo("");
      setNotValid(true);
      return;
    }

    // Success
    setError("");
    setInfo(Messages.LOCATIONINFO);
    setNotValid(false);
    setSelected(nodes);
  }

  /**
   * We are manipulating selection and expansions on icons and labels.
   * Se make sure that this function does nothing
   */
  function handleSelect(event, nodeIds) {
    return;
  }

  /**
   * Recursively render tree nodes
   * @param {*} nodes
   * @returns
   */
  function renderTree(nodes) {
    if (null == nodes) return null;
    if (Array.isArray(nodes)) {
      return nodes.map((node) => renderTree(node));
    } else {
      return (
        <TreeItem
          key={nodes.id}
          nodeId={nodes.id}
          label={
            <Stack
              direction="row"
              onClick={(event) => {
                labelClick(event, nodes.id);
              }}
            >
              {-1 < selected.indexOf(nodes.id) ? (
                <CheckBox />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              <Typography sx={MStyles.treelabelstyle}>{nodes.name}</Typography>
            </Stack>
          }
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      );
    }
  }

  // To detect if this is a a mobile screen we need to check for sm
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Dialog open={props.open} fullScreen={!small} aria-labelledby="title" aria-describedby="description" aria-modal="true" onKeyDown={ariaescape} >
      <Box
        display="flex"
        sx={{ justifyContent: "flex-end", marginBottom: 0, paddingBottom: 0 }}
      >
        <IconButton
          onClick={() => {
            cancelselect();
          }}
        >
          <CloseIcon titleAccess="Close dialog" />
        </IconButton>
      </Box>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1" sx={MStyles.h1} id="title">
              {props.title}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={MStyles.body}>{info}</DialogContentText>
        <Alert
          severity="error"
          sx={error ? MStyles.dialogwarning : MStyles.dialogwarninghide}
        >
          {error}
        </Alert>
        <Stack justifyContent="flex-end" direction="row" sx={{ width: "100%" }}>
          <Button
            variant="contained"
            disableElevation
            sx={MStyles.clearselectionbutton}
            onClick={() => {
              clear();
            }}
            disabled={0 === selected.length}
          >
            Clear selection
          </Button>
        </Stack>
        <TreeView
          aria-label="Select location"
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ExpandLessIcon />}
          defaultExpanded={props.expanded}
          defaultSelected={props.selected}
          disabledItemsFocusable={true}
          expanded={expanded}
          selected={selected}
          multiSelect={true}
          sx={{ flexGrow: 1 }}
          onNodeSelect={(event, nodeIds) => {
            handleSelect(event, nodeIds);
          }}
          onNodeToggle={(event, nodeIds) => {
            handleToggle(event, nodeIds);
          }}
        >
          {renderTree(props.tree)}
        </TreeView>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          disableElevation
          sx={MStyles.linkbutton}
          onClick={() => {
            cancelselect();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          disabled={notvalid}
          sx={MStyles.buttondimstyle}
          onClick={() => {
            confirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
