// Ract
import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Classes
import Page from "../classes/Page";
import MStyles from "../classes/MStyles";

// Components
import NormalCard from "./NormalCard";

/**
 * Display Help and Support navigation page
 */
export default class HelpSupport extends React.Component {
  page = Page.HelpSupport;
  state = {
    selectionPage: "",
  };
  cards = [
    {
      page: Page.About.page,
      header: "About this website",
      text: "Information about this website.",
    },
    {
      page: Page.FAQ.page,
      header: "Frequently asked questions",
      text: "Answers to frequently asked questions.",
    },
    {
      page: Page.Glossary.page,
      header: "Glossary",
      text: "Definitions for terms used on this website.",
    },
    {
      page: Page.Contact.page,
      header: "Contact us",
      text: "Contact details for enquiring about this website.",
    }
  ];

  handleNavigation = (page) => {
    this.setState({ selectionPage: page });
    this.props.onHandleNavigation(page);
  };

  render() {
    // Do not render if the page name is different
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          Use the self-help options for explanations on how to use this website and obtain data.  
        </Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-center"
          sx={{marginTop: 0, marginBottom:1, marginRight:1, marginLeft:1, paddingTop:1, paddingBottom:1, paddingLeft:1, paddingRight:1 }}
        >
          {this.cards.map((row) => (
            <NormalCard
              key={row.page}
              page={row.page}
              header={row.header}
              text={row.text}
              onHandleNavigation={() => {
                this.handleNavigation(row.page);
              }}
            />
          ))}
        </Grid>
      </div>
    );
  }
}
