/**
 * Utilty function to write a headers to CSV string
 * @param {*} headers 
 * @returns string in CSV format
 */
 function processHeader (headers) {
    let finalVal = "";
    for (let j = 0; j < headers.length; j++) {
        let innerValue = headers[j] === null ? '' : headers[j].label.toString();
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
            result = '"' + result + '"';
        if (j > 0)
            finalVal += ",";
        finalVal += result;
    }
    return finalVal + '\n';
};
/**
 * Utilty function to write a row to CSV string
 * @param headers
 * @param {*} row 
 * @returns string in CSV format
 */
function processRow (headers, row) {
    let finalVal = "";
    for (var j = 0; j < headers.length; j++) {
        const v = row[headers[j].key];
        let innerValue = v === null ? '' : v.toString();
        if (v instanceof Date) {
            innerValue = v.toLocaleString();
        };
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
            result = '"' + result + '"';
        if (j > 0)
            finalVal += ",";
        finalVal += result;
    }
    return finalVal + '\n';
};

/**
 * Convert table and data into csv string
 * @param {*} headers 
 * @param {*} tabledata 
 * @returns Blob.
 * @see https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export default function TableToCSV(displaylocation, displaysector, location, sector, gas, fuel, emissiontype, year, headers, tabledata)
{
    let t = processHeader(headers);
    for (var i = 0; i < tabledata.length; i++) {
        t += processRow(headers, tabledata[i]);
    }
    if(location && displaylocation) t += "Location: "+location +'\n';
    if(sector && displaysector) t += "Sector: "+sector +'\n';
    if(gas) t += "Gas: "+gas +'\n';
    if(fuel) t += "Fuel: "+fuel +'\n';
    if(emissiontype) t += "Emission: "+emissiontype +'\n';
    if(year) t += "Year: "+year +'\n';
    t += "Source : https://greenhouseaccounts.climatechange.gov.au";
    return new Blob([t], {type: "text/csv"});
}