// React
import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// rechart
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Classes
import ChartUtility from "../classes/ChartUtility";
import MStyles from "../classes/MStyles";

// Components
import BestExperience from "./BestExperience";

/**
 * Display stacked bar chart in data visualisation
 * @param {*} props
 * @param {*} refs - Reference required to export chart to PNG
 * @returns
 */
export default function CustomStackedBarChart(props) {
  const myref = React.useRef();
  const [digits, setDigits] = React.useState(props.digits);
  const [key, setKey] = React.useState(0);
  const [chartData, setChartData] = React.useState(props.data);
  const [isdate, setIsDate] = React.useState(props.isdate);
  const [lines, setLines] = React.useState(props.lines);
  const [xAxisTitle, setXAxisTitle] = React.useState(props.XAxisTitle);
  const [units, setUnits] = React.useState(props.units);

  /**
   * Update value after changing props
   */
  React.useEffect(() => {
    setDigits(props.digits);
    setIsDate(props.isdate);
    setChartData(props.data);
    setLines(props.lines);
    setXAxisTitle(props.XAxisTitle);
    setUnits(props.units);
    setKey(props.data.length);
    
  }, [
    props.digits,
    props.isdate,
    props.data,
    props.line,
    props.lines,
    props.units,
    props.XAxisTitle,
    props.YAxisTitle,
  ]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const xtitle = (!sm) ? "" : xAxisTitle;
  const ytitle = (!sm) ? "" : "Emissions";
  const s = { 
    "marginInlineStart" : (sm ? "40px" : "0px"),
    "marginInlineEnd" : (sm ? "40px" : "0px"),
    }

  if (!props.isvisible) return null;


  return (
    <Grid
      item
      xs={12}
      sx={{ mt: 0, mb: 0, ml: 0, mr: 0, pt: 0, pb: 0, pl: 0, pr: 0 }}
    >
      <BestExperience />
      <figure style={s}>
        <figcaption style={{ captionSide: "top", marginBottom:10  }}>
          <Typography variant="h4" sx={MStyles.h4}>
            {props.title}
          </Typography>
        </figcaption>
        <ResponsiveContainer
          width="100%"
          aspect="1.6"
          key={key}
          ref={myref}
        >
          <BarChart
            id="barchart"
            data={chartData}
            margin={MStyles.chartmargin}
            ref={props.forwardRef}
            stackOffset="sign"
          >
            <CartesianGrid
              stroke="#E6E6E6"
              strokeDasharray="5 5"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey={isdate ? "date" : "label"}
              axisLine={{ stroke: "#333333" }}
              label={{
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
                position: "bottom",
                value: xtitle,
              }}
              minTickGap="7"
              tick={{
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
              }}
              tickFormatter={
                isdate
                  ? ChartUtility.tickdateFormater
                  : ChartUtility.ticklocationFormatter
              }
            />
            <YAxis
              label={{
                angle: -90,
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "nomal",
                position: "left",
                value: ytitle,
              }}
              scale="auto"
              domain={["auto", "auto"]}
              tick={{
                fontFamily: "Public Sans, Arial, Segoe",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
              }}
              tickFormatter={ChartUtility.formatYAxisNumbers}
              unit={units}
            />
            <Tooltip
              itemStyle={{ color: "#333333" }} // Remove colour requested by Bree email 11/04/2022
              formatter={(x) => {return new 
                Intl.NumberFormat("en-AU",{
                  minimumFractionDigits: digits,
                  maximumFractionDigits: digits,
                }).format(x) + " "+ props.units;
              }}
            />
            {sm && (
              <Legend
                id="items"
                wrapperStyle={{
                  fontFamily: "Public Sans, Arial, Segoe",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: "normal",
                  color: "#333333",
                  postion: "bottom",
                  paddingTop: 30, // Needs to be big enough to accommodate xAxis title
                }}
                formatter={(value, entry, index) => (
                  <span style={{ color: "#333333" }}>{value}</span>
                )} // Remove colour requested by Bree email 11/04/2022
              />
            )}

            <ReferenceLine y={0} stroke="#333333" />
            {lines.map((line) => (
              <Bar
                key={line.key}
                dataKey={line.key}
                fill={MStyles.chartcolours[lines.indexOf(line) % 16]}
                yAxisId={0}
                stackId="a"
                label={line.label}
                name={line.label}
              />
            ))}
            {/* {sm && (
              <text
                x={ChartUtility.titlex(windowDimensions.width)}
                y={ChartUtility.titley(windowDimensions.width)}
                style={{
                  fontSize: "14",
                  fontFamily: "Public Sans",
                  fontWeight: "normal",
                  color: "#333333",
                }}
              >
                {title}
              </text>
            )}
            {sm & ChartUtility.sourcey(windowDimensions.width)  && (
              <text
                x={0}
                y={ChartUtility.sourcey(windowDimensions.width) }
                style={{
                  fontSize: "14",
                  fontFamily: "Public Sans",
                  fontWeight: "normal",
                  color: "#333333",
                }}
              >
                Source: https://ageis.climagechange.gov.au
              </text>
            )} */}
          </BarChart>
        </ResponsiveContainer>
      </figure>
    </Grid>
  );
}
