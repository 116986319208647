export default class ArrayUtility {
  /**
   * Convert array of selection to a comma separated ids
   * @param selected - Array of string ids or a single id
   * @returns string
   */
  static convertArrayToString(selected) {
    if (Array.isArray(selected)) {
      let s = selected[0];
      for (let i = 1; i < selected.length; i++) {
        s += ", " + selected[i];
      }
      return s;
    } else {
      return selected;
    }
  }

  /**
   * Convert array of selection to a comma separated ids
   * @param selected - Array of string ids or a single id
   * @returns string
   */
  static convertArrayToStringIDs(selected) {
    if (Array.isArray(selected)) {
      let s = selected[0];
      for (let i = 1; i < selected.length; i++) {
        s += "," + selected[i].toString();
      }
      return s;
    } else {
      return selected.toString();
    }
  }

  /**
   * Convert array of ids to an array of strings
   * @param {*} selected
   * @returns
   */
  static convertArrayIDsToArrayString(selected) {
    const a = [];
    if (Array.isArray(selected)) {
      for (const s of selected) {
        a.push(s.toString());
      }
    } else {
      a.push(selected.toString());
    }
    return a;
  }

  /**
   * Check if two arguments are equal on elements ignoring their type
   * @param {*} first
   * @param {*} second
   */
  static elementEqual(first, second) {
    if (Array.isArray(first) && Array.isArray(second)) {
      if (first.length !== second.length) return false;
      for (const f of first) {
        if (-1 === second.indexOf(f)) return false;
      }
      return true;
    } else if (!Array.isArray(first) && Array.isArray(second)) {
        if(1 !==  second.length) return false;
        if(-1 === second.indexOf(first) && -1 === second.indexOf(first.toString())) return false;
        return true;
    } else if (Array.isArray(first) && !Array.isArray(second)) {
      if(1 !==  first.length) return false;
      if(-1 === first.indexOf(second) && -1 === first.indexOf(second.toString())) return false;
      return true;
    }
    else {
      if (!first && !second) return true;
      if (!first && second) return false;
      if (first && !second) return false;
      return first === second || first.toString() === second.toString();
    }
  }
}
