import ArrayUtility from "./ArrayUtility";
import TreeUtility from "./TreeUtility";

export default class TransformationUtility {

  /**
   * Calculate gas display name
   * @param {*} gastreeraw
   * @param {*} gastreear
   * @param {*} gasselected
   * @returns String with name of gas for display
   */
  static calculateGasDisplayName(gastreeraw, gastreear, gasselected) {
    let t = "";

    if (gastreeraw) {
      const selectedgasraw = TreeUtility.findinTree(
        gastreeraw,
        null,
        gasselected
      );
      if (selectedgasraw) t = selectedgasraw.name;
    }

    if (gastreear) {
      const selectedgasar = TreeUtility.findinTree(
        gastreear,
        null,
        gasselected
      );
      if (selectedgasar) t = selectedgasar.name;
    }

    return t;
  }

  /**
   * Calculate if we have elements from different generation
   * @param {*} tree
   * @param {*} selected
   * @returns True if there are more than one levels selected
   */
  static calculateGenerationSpread(tree, selected) {
    let _generation = -1;
    if (Array.isArray(selected)) {
      for (const s of selected) {
        // Check business rules, generation spread and confidential
        const node = TreeUtility.findinTree(tree, null, s);
        if (node) {
          if (_generation === -1) {
            _generation = node.level;
          } else {
            if (_generation !== node.level) {
              return true;
            }
          }
        }
      }
      return false;
    }
    return false;
  }

  static calculateSelectionList(tree, selected) {
    let s = "";
    if (Array.isArray(selected)) {
      const arr = [];
      for (const a of selected) {
        const tn = TreeUtility.findinTree(tree, null, a);
        if (tn) arr.push(tn.name);
      }
      s = ArrayUtility.convertArrayToString(arr);
    } else {
      const t = TreeUtility.findinTree(tree, null, selected);
      s = t.name;
    }
    return s;
  }

  static calculateTitleAccountedUnder(tree, selected) {
    let s = "";
    if (!Array.isArray(selected)) {
      const t = TreeUtility.findinTree(tree, null, selected);
      s = " accounted under " + t.name;
    }
    return s;
  }

  static calculateTitle(isdisabled, tree, selected) {
    if (isdisabled) return "";
    if (!Array.isArray(selected)) {
      const t = TreeUtility.findinTree(tree, null, selected);
      if (t) return t.name;
    }
    return "";
  }
  
  /**
   * Populate time series using tree as a variable
   * @param {*} apid
   * @param {*} selected
   * @param {*} tabledata
   * @returns maximum absolute value or -1 if no data
   */
   static getMaxValue(apid, selected, tabledata) {
    let maxvalue = 0;

    if (Array.isArray(selected)) {
      for (const s of selected) {
        for (const row of tabledata) {
          for (let id of apid) {
            if (id.date.toString() === row.date && id.s.toString() === s) {
              const t = Math.abs(id.v);
              if (maxvalue < t) maxvalue = t;
            }
          }
        }
      }
    } else {
      for (const row of tabledata) {
        for (let id of apid) {
          if (id.date.toString() === row.date && id.s.toString() === selected) {
            const t = Math.abs(id.v);
            if (maxvalue < t) maxvalue = t;
          }
        }
      }
    }
    return maxvalue;
  }

  /**
   * Calculate scaling of values. All emissions stored internally are in kt
   * @param {*} maxvalue 
   * @returns Object {factor, units}
   */
  static getScale(maxvalue){
    if (1000 < maxvalue) return { factor: 0.001, units: "Mt"};
    else if (1 < maxvalue) return { factor: 1, units: "kt"} 
    else return { factor: 1000, units: "t"};
  }

  /**
   * Check if data table has any not available entries
   */
  static hasNotAvailable(tabledata)
  {
    for (const row of tabledata) {
      for (const c in row) {
        if (row[c] === "Not available") {
          return true;
        }
      }
    }
    return false;
  }
  /**
   * Prepare for time series
   * @param {*} firstyear - First year to populate
   * @param {*} lastyear - Last year to populate
   * @param {*} headers - Headers fits column will be "date"
   * @param {*} chartdata - Add the first column
   * @param {*} tabledata -- Add the first column
   */
  static prepareTimeSeries(firstyear, lastyear, headers, chartdata, tabledata) {
    headers.push({ key: "date", label: "Year", align: "left" });
    for (let y = firstyear; y <= lastyear; y++) {
      chartdata.push({ date: y.toString() });
      tabledata.push({ date: y.toString() });
    }
  }

  /**
   * Populate time series using tree as a variable
   * @param {*} apid
   * @param {*} selected
   * @param {*} tree
   * @param {*} chartdata
   * @param {*} lines
   * @param {*} tabledata
   * @param {*} headers
   * @param {*} digits 2 or 4 number of digits to display
   * @param {*} factor
   * @returns true if it has data
   */
  static populateTimeSeries(
    apid,
    selected,
    tree,
    chartdata,
    lines,
    tabledata,
    headers,
    digits,
    factor
  ) {
    let hasdata = false;
    let partialdata = false;
    if (Array.isArray(selected)) {
      for (const s of selected) {
        let _t = TreeUtility.findinTree(tree, null, s);
        const _label = _t ? _t.name : "Unable to find " + s;

        lines.push({ key: s, label: _label });
        headers.push({ key: s, label: _label, align: "right" });

        // Populate chart data
        for (const row of chartdata) {
          let found = false;
          for (const id of apid) {
            if (id.date.toString() === row.date && id.s.toString() === s) {
              row[s] = Number((id.v * factor).toFixed(digits));
              found = true;
              break;
            }
          }
          if (!found) {
            row[s] = null;            
          }
        }
        // Populate table data
        for (const row of tabledata) {
          let found = false;
          for (let id of apid) {
            if (id.date.toString() === row.date && id.s.toString() === s) {
              row[s] = Intl.NumberFormat("en-AU", {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              }).format(id.v * factor);
              hasdata = true;
              found = true;
              break;
            }
          }
          if (!found) {
            row[s] = "Not available";
            partialdata = true;
          }
        }
      }
    } else {
      // Needs to add ordering
      let _t = TreeUtility.findinTree(tree, null, selected);
      const _label = _t ? _t.name : "Unable to find " + selected;

      lines.push({ key: selected, label: _label });
      headers.push({ key: selected, label: _label, align: "right" });

      // Populate chart data
      for (const row of chartdata) {
        let found = false;
        for (const id of apid) {
          if (id.date.toString() === row.date && id.s.toString() === selected) {
            row[selected] = Number((id.v * factor).toFixed(digits));
            found = true;
            break;
          }
        }
        if (!found) {
          row[selected] = null;
        }
      }
      // Populate table data
      for (const row of tabledata) {
        let found = false;
        for (let id of apid) {
          if (id.date.toString() === row.date && id.s.toString() === selected) {
            row[selected] = Intl.NumberFormat("en-AU", {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            }).format((id.v * factor));
            hasdata = true;
            found = true;
            break;
          }
        }
        if (!found) {
          row[selected] = "Not available";
          partialdata = true;
        }
      }
    }
    return {hasdata: hasdata, partialdata: partialdata};
  }

  /**
   * Get lines with data only
   * @param {*} lines 
   * @param {*} chartdata 
   * @returns Array of lines with data
   */
  static getLinesWithData(lines, chartdata)
  {
    const newlines = [];
    for (const l of lines)
    {
      for (const r of chartdata)
      {
        if (null != r[l.key])
        {
          newlines.push(l);
          break;
        }
      }
    }
    return newlines;
  }
}
